import Fe from "@/lib/ui/FormElement";

function parseDate(d) {
    d = new Date(d.replace(' ', 'T'));
    let date = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear(),
        time = d.getHours()+':'+(d.getMinutes().toString().length < 2 ? '0'+d.getMinutes():d.getMinutes())
    return date + ' '+time
}
export default {

    allowDelete: true,
    name: "Brand",
    path: "brands",
    plural: "Brands",
    menu: "Brands",
    meta : {
        discountTypes : [
            {id:'fixed',name:'Fixed Discount'}, {id:'percentage',name:'Percentage Discount'}, {id:'custom',name:'Custom Price'},
        ],
        features: [
            {id:'recurring-billing', label: "Recurring Billing"},
            {id:'plans', label: "Plans"},
            {id:'videos', label: "Videos"},
            {id:'media lists', label: "Media Lists"},
            {id:'landing pages', label: "Landing Pages"},
            {id:'mailers', label: "Mailers"},
            {id:'exclusives', label: "Exclusives"},
            {id:'coupons', label: "Coupons"},
            {id:'courses', label: "Courses"},
            {id:'notifications', label: "Subscriber Notifications"},
            {id:'templates', label: "E-mail Templates"},
            {id:'reports', label: "Reports"},
        ]
    },

    actions: [
        { action: "switch", icon: "play", mood: "primary", tooltip: "Switch to Brand" }
    ],

    permissions: ['ADMIN'],

    endpoints : {
        list : { path : '/admin/brand/list', method: "get", token: true },
        get : { path : '/admin/brand/get/:id', method: "get", token: true },
        add : { path : '/admin/brand/add', method: "post", body: true, token: true },
        update : { path : '/admin/brand/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/brand/delete/:id', method: "get", params: true, token: true },
        switch : { path : '/admin/brand/switch/:id', method: "get", params: true, body: true, token: true },
    },

    fields: [
        {label: "ID", prop: "id", above: 1, sortable: true},
        {label: "Name", prop: "name", sortable: true},
        {label: "App URL", prop: "app_url",  sortable: true},
        {label: "Created", prop: "created", format: parseDate, sortable: true, above: 1},
    ],

    form : [
        Fe.Text("name", "Name", "primary"),
        Fe.Text("contact_email", "Contact E-mail", "primary"),
        Fe.Text ("outgoing_email", "Outgoing E-Mail Address", "primary"),
        Fe.Date("created", "Created", "primary"),
        Fe.Number("max_users", "Max Users", "primary 2",'', 'App Limits'),
        Fe.Number("max_api_keys", "Max API Keys", "primary 2"),
        Fe.Checkbox("custom_settings", "Allow Custom Settings", "primary 2"),
        Fe.Text("app_url", "App URL", "primary 3",'', "App Settings"),
        Fe.Text("app_title", "App Title", "primary 3"),
        Fe.Image("app_logo", "App Logo", "primary 3"),
        Fe.Image("app_icon", "App Icon", "primary 3"),
        Fe.Text ("production_api_key", "production api key", "primary 4"),
        Fe.Text ("development_api_key", "development api key", "primary 4"),
        Fe.Text("minimum_client_version", "Minimum Client Version", "primary 5"),
        Fe.Text ("latest_client_version", "Latest Client Version", "primary 5"),
    ],

    additionalControls: () => [
        {type: "edit-list", title: "App Features", position: "main", items: "app_features", titleProp: "feature", reduceProp: "feature", fields: [
                { prop: "feature", },
            ],
            formFields: () => [
                Fe.Select("feature", "Feature",null, "Select feature",'label','features','id'),
            ]
        }
    ],

}
