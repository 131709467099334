<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields" :on-validate="validateService"
                :on-update="onUpdate" :on-submit="onSubmit">
    <template slot="main">
      <ServiceValidation :service="instance" :on-validate="onValidateService" />
      <ServiceEndpointsForm v-if="instance && instance.endpoints" :endpoints="instance.endpoints" :service-id="instance.id" />
      <ServiceModulesForm v-if="instance && instance.modules" :modules="instance.modules" :service-id="instance.id" />
    </template>
  </InstanceDash>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import Fe from "@/lib/ui/FormElement";
import ServiceValidation from "@/components/forms/ServiceValidation";
import ServiceEndpointsForm from "@/components/forms/ServiceEndpointsForm";
import ServiceModulesForm from "@/components/forms/ServiceModulesForm";

export default {

  name: "Service",
  mixins: [InstanceView],
  components: {ServiceModulesForm, ServiceEndpointsForm, ServiceValidation, InstanceDash,  },
  data() {
    return {

      type: "Service",
      path: "services",
      fields : [
          Fe.Text("name", "Name", "primary"),
          Fe.Text("url", "URL", "primary"),
          Fe.Text("username", "Username", "primary 3"),
          Fe.Password("password", "Password", "primary 3"),
          Fe.Text("token", "Token", "primary 3"),
      ],
      Create : this.createService,
      Update : this.updateService,
      defaultData: {
        name: "",
      },
    }
  },

  async mounted() {
    await this.initInstance('allServices',[!this.allServices.length, this.getServices])
  },

  computed: {
    ...mapGetters(['allServices']),
  },

  methods: {
    ...mapActions(['getServices', 'updateService', 'createService']),
    onValidateService(token, endpoints, modules) {
      this.$set(this.instance, 'token', token)
      this.$set(this.instance, 'endpoints', endpoints)
      this.$set(this.instance, 'modules', modules)
      this.$set(this.instance, 'is_active', true)
    },
    validateService() {
      if (this.instance.name && this.instance.url && this.instance.token) return true
    }
  }
}
</script>
