<template>
  <Container v-if="instance" :full="full">
    <Panel>
      <Header>{{t(type)}}: <b>{{instanceTitle}}</b></Header>
      <Content :colNeg="{2:2}">
        <Content :flex="{3:2, 4:2, 5:3}" :col="true" :full="2">
          <Scroller :full="true">
            <AppForm :fields="fields" :data="instance" @update="onUpdate" :meta="meta" :features="features" />
            <slot name="main"></slot>
          </Scroller>
        </Content>
        <Content :flex="{3:1,4:1,5:1}" class="instance-sidebar">
          <Scroller :full="true">
            <slot name="sidebar"></slot>
            <BoxGroup :col="true">
              <Box>
                <Button @click="doSubmit" :disabled="!isValid" mood="saving">{{ t(instance.id?'Update {0}':'Create {0}',t(type)) }}</Button>
              </Box>
            </BoxGroup>
            <slot name="sidebar-bottom"></slot>
          </Scroller>
        </Content>
      </Content>
    </Panel>
  </Container>
</template>


<script>
import Container from "@/components/ui/layout/Container";
import Panel from "@/components/ui/layout/Panel";
import Content from "@/components/ui/layout/Content";
import Scroller from "@/components/ui/layout/Scroller";
import AppForm from "@/components/ui/form/AppForm";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Box from "@/components/ui/layout/Box";
import Button from "@/components/ui/form/Button";
import Header from "@/components/ui/utils/Header";
import t from '@/lib/Locale';

export default {
  name: "InstanceDash",
  props: {
    onUpdate: Function,
    onSubmit : Function,
    validate : Function,
    instance: Object,
    titleProp: {type:[String, Function], default: "name"},
    meta: {type: Object, default: () => {}},
    type: String,
    full: {type: Boolean, default: false},
    fields: Array,
    features: {type: Array, default: () => []},
  },
  components: {Header, Button, Box, BoxGroup, AppForm, Scroller, Content, Panel, Container},

  computed : {
    instanceTitle() {
      if (this.instance) {
        if (typeof this.titleProp === "function") return this.titleProp(this.instance)
        else return this.instance[this.titleProp]
      }
      return ''
    },
    isValid() {
      if (typeof this.validate !== 'function') return true;
      else return this.validate()
    },

  },

  methods: {
    t,
    doSubmit() {
      if (this.isValid) this.onSubmit()
    }
  }

}
</script>
