<template>
  <Panel :title="listTitle">
    <Draggable handle=".handle .icon" @end="onDragEnd" :class="clsName">
      <Content class="item" v-for="(item,index) in items" :key="item.id+'_'+index">
        <div class="handle"><Icon :icon="Icons.drag" cursor="move" /></div>
        <Part v-for="(field, fi) in fields" :key="fi" :flex="field.flex || field.primary?1:'auto'" :class="visibilityClass(field, item)" :width="field.width">
          <component :is="itemTag(field, item)" :class="itemClass(field, item)" :title="itemTitle(field, item)" :style="itemStyle(field, item)" :icon="itemIcon(field, item)" :src="itemSrc(field, item)">
            <Flexy v-if="field.type !== 'icon' && field.icon && itemContent(field, item)" :centered="true">
              <Icon v-if="field.icon" :icon="field.icon" :mood="field.mood" :tiny="true" />
              <Txt>{{itemContent(field, item)}}</Txt>
            </Flexy>
            {{!field.icon?itemContent(field, item):''}}
            <RowActions :actions="field.actions" :item="item" v-on="actionBinding(item, index)" />
          </component>
        </Part>
      </Content>
    </Draggable>
  </Panel>
</template>

<script>
import Icons from '@/lib/Icons';
import Badge from "@/components/ui/utils/Badge";
import Icon from "@/components/ui/utils/Icon";
import Content from "@/components/ui/layout/Content";
import Panel from "@/components/ui/layout/Panel";
import Draggable from "vuedraggable";
import Txt from "@/components/ui/utils/Txt";
import FormattedList from "@/mixins/FormattedList";
import Part from "@/components/ui/layout/Part";
import RowActions from "@/components/ui/lists/RowActions";
import t from '@/lib/Locale';
import Flexy from "@/components/ui/layout/Flexy";

export default {
  name: "DraggableList",
  mixins: [FormattedList],
  components: {Flexy, RowActions, Part, Txt, Panel, Content, Icon, Badge, Draggable},
  props : {
    title : String,
    compact: {type: Boolean, default: false},
  },
  data() {
    return {
      Icons,
    }
  },

  computed : {
    listTitle() { return this.title ? t(this.title) : '' },
    clsName() {
      let c = 'draggable-list';
      if (this.compact) c += ' compact'
      return c;
    }
  },

  methods : {
    t,
    onDragEnd(data) {
      this.$emit('sort', data.oldIndex, data.newIndex)
    }
  }

}
</script>
