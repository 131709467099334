<template>
  <div class="filtered-list">
    <div class="filters-bar">
      <Flexy class="list-title" :centered="true">
        <Txt :title="true" :bold="true" v-if="title">{{t(title)}}</Txt><Txt :mood="filteredItems.length?'primary':'warning'" :text="filteredItems.length" />
        <div class="actions" v-if="actions && Object.keys(actions).length">
          <Button v-for="(action,name) in actions" :key="name" @click="action" :small="true">{{t(name)}}</Button>
        </div>
      </Flexy>
      <div class="filters" v-if="ready">
        <vSelect v-for="(filter, prop) in filters" :key="prop" v-model="myFilters[prop]" :reduce="reduceFilter" :options="filterOptions(prop)" :placeholder="t('Filter By {0}',t(filter))" />
        <div class="search" v-if="search">
          <Input placeholder="Search..." :value="tempSearchQuery" @input="onSearch" />
        </div>
      </div>
    </div>
    <ItemList :fields="fields" :items="filteredItems" :on-click="onClick" v-on="customEvents" :preformatted="true" :default-sort-by="defaultSortBy"
              :max-items="maxItems" :page="page" :on-page="onPage" v-if="done" :features="features" />

    <ContentMockup v-if="!done" :is-list="true" :rows="maxItems" />
  </div>
</template>

<script>
import ItemList from "@/components/ui/lists/ItemList";
import Input from "@/components/ui/form/Input";
import {getListValues} from "@/lib/ui/UiUtils";
import vSelect from "vue-select";
import {TempGet, TempSet} from "@/lib/Storage";
import t from '@/lib/Locale'
import Flexy from "@/components/ui/layout/Flexy";
import Button from "@/components/ui/form/Button";
import ContentMockup from "@/components/ui/layout/ContentMockup";
import Txt from "@/components/ui/utils/Txt";

export default {
name: "FilteredItemList",
  components: {Txt, ContentMockup, Button, Flexy, Input, ItemList, vSelect},
  props : {
    defaultSortBy : {type: String, default: null},
    saveAs : {type: String, default: ''},
    search: { type: [Boolean, Array] },
    filters: { type: [Array, Object] },
    filterData: { type: Object, default: () => ({}) },
    filterFormat: { type: Object, default: () => ({}) },
    actions: { type: Object },
    done: {type: Boolean, default: true},
    items: Array,
    fields: Array,
    features: Array,
    title: String,
    noFormatting: Boolean,
    onClick: Function,
    onExtSearch: Function,
  },
  data() {
    return {
      listValues: [],
      filterTimeout: null,
      ready: false,
      myFilters : {},
      searchQuery : "",
      tempSearchQuery: "",
      page: 1,
      maxItems: 20,
    }
  },

  mounted() {
    this.initSettings()
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed : {

    customEvents() {
      const events = {};
      for (let f of this.fields) if (f.actions) {
        for (let a of f.actions) if (a.action) events[a.action] = (v) => this.$emit(a.action, v)
      }
      return events;
    },
    filteredItems() {
      if (!this.done) return []
      let items = getListValues(this.items, this.fields)
      if (!items.length) return []
      if (this.searchQuery && this.search) items = this.applySearch(items)
      let val;
      if (this.myFilters) {
        for (let d in this.myFilters) {
          val = this.myFilters[d];
          if (typeof val === 'string' && val.match(/^-?\d+\.?\d*$/)) val *= 1;
          else if (val === 'true') val = true;
          else if (val === 'false') val = false;
          if (!this.myFilters[d]) continue
          items = items.filter(it => it[d] === val)
        }
      }
      return items;
    },
  },
  methods : {
    t,

    onResize() {
      const size = window.innerHeight - 200
      this.maxItems = Math.max(3,Math.round(size/30)-2)

      while (this.page > 1 && Math.max(1,this.page-1) * this.maxItems > this.items.length) this.page -= 1
    },


    initSettings() {
      this.ready = false
      const savedSearch = this.load('search'),
          savedPage = this.load('page'),
          savedFilters = this.load('filters')

      this.listValues = getListValues(this.items, this.fields)

      if (savedSearch) this.onSearch(savedSearch)
      if (savedPage) this.onPage(savedPage)
      if (savedFilters) this.setFilters(savedFilters)
      setTimeout(() => this.ready = true, 1)
    },

    setFilters(filters) {
      let ff = {}
      for (let f in filters) {
        ff[f] = filters[f]
      }
      this.listValues = []
      this.myFilters = {...this.myFilters, ...ff}
    },

    onSearch(search) {
      clearTimeout(this.filterTimeout)
      this.tempSearchQuery = search
      this.filterTimeout = setTimeout(() => {
        if (this.searchQuery !== search) {
          this.page = 1
          this.save('page',this.page)
          if (search) this.onExtSearch(search)
        }

        this.searchQuery = search;
        this.save('search', this.searchQuery)
        this.listValues = []
      }, 300)
    },

    onPage(page) {
      this.page = page
      this.save('page', this.page)
    },

    reduceFilter(data) {
      return data.value
    },

    load(prop) {
      if (this.saveAs) {
        let s = TempGet('saved_'+this.saveAs)
        if (!s) s = {}

        return s[prop]
      }
    },

    save(prop, value) {
      if (this.saveAs) {
        let s = TempGet('saved_'+this.saveAs)
        if (!s) s = {}

        s[prop] = value;

        TempSet('saved_'+this.saveAs, s)
      }
    },

    filterOptions(prop) {
      const items = this.listValues ? this.listValues : this.filteredItems
      let opts = {}
      for (let item of items) if (typeof item[prop] !== "undefined") {
        if (typeof this.filterFormat[prop] === 'function') opts[item[prop]] = this.filterFormat[prop](item[prop]);
        else opts[item[prop]] = item[prop];
      }
      return Object.keys(opts).map((d) => ({label: opts[d]?t(opts[d]):null, value: d})).filter(d => d.label)
    },

    applySearch(items) {
      if (this.searchQuery === "*") return items;
      if (this.search) {
        items = items.filter(item => {
          if (this.search instanceof Array) {
            for (let prop of this.search) {
              if (item[prop] && item[prop].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase())>-1) return true;
            }
          }
          else {
            for (let prop in item) {
              if (item[prop] && item[prop].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase())>-1) return true;
            }
          }
        })
      }
      return items;
    }
  },
  watch : {
    myFilters : {
      handler() {
        if (this.ready) this.page = 1
        this.save("filters", this.myFilters)
      },
      deep: true
    },
    items() {
      this.initSettings()
      this.onResize()
    }
  }
}
</script>
