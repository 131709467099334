<template>
  <textarea :rows="rows" :class="clsName" :placeholder="t(inputPlaceholder)" :disabled="disabled" :value="value" @keyup="e => onChange(e)" @input="e => onChange(e)" autocomplete="none"></textarea>
</template>

<script>
import t from '@/lib/Locale';

export default {
  name: "Textarea",
  props : {
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' },
    rows: { type: Number, default: 4 },
    disabled: { type: Boolean, default: false },
    mood: {type: String, default: ''},
  },
  computed : {
    inputPlaceholder() {
      return this.placeholder;
    },
    clsName() {
      let s = '';
      if (this.mood) { if (s) s+=' '; s += this.mood }
      return s
    },
  },
  methods : {
    t,
    onChange(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>
