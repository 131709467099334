import Fe from "@/lib/ui/FormElement";

export default {

    allowDelete: true,
    name: "Notification",
    path: "notifications",
    plural: "Notifications",
    menu: "Notification",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],
    titleProp: "title",

    defaultSortBy: "min_plan_days",

    meta : {
        mediaTypes : [
            {id:'video',name:'Video file'}, {id:'audio',name:'Audio file'}, {id:'youtube',name:'YouTube'}, {id:'vimeo',name:'Vimeo'}
        ],
    },

    endpoints : {
        list : { path : '/admin/notification/list', method: "get", token: true },
        get : { path : '/admin/notification/get/:id', method: "get", token: true },
        add : { path : '/admin/notification/add', method: "post", body: true, token: true },
        update : { path : '/admin/notification/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/notification/delete/:id', method: "get", params: true, token: true },
    },

    fields: [
        {label: "ID", prop: "id", above: 2, sortable: true },
        {label: "Title", prop: "title", shorten: 32, sortable: true },
        {label: "Media Type", prop: "media_type", above: 1,},
        {label: "Days in plan", prop: "min_plan_days", target: "plan_days", format:(min, o) => o['max_plan_days'] ? ((min||'0')+' - '+o['max_plan_days']):(min?min+'+':'All'), above: 1, sortable: true},
        {label: "Progress", prop: "min_updates", format:(min, o) => o['max_updates'] ? ((min||'0')+' - '+o['max_updates']+' updates'):(min?min+'+ updates':'All'), above: 1 },
        {label: "Expired Only", prop: "expired", type: "boolean", above: 1 },
        {label: "Length (words)", prop: "content", format:(d) =>{d = d && d.trim()?d.replace(/[^ ]/g,'').length+1:0; return d?d:'(empty)'}, above: 2 },
    ],

    form : [
        Fe.Text("title", "Title", "main"),
        Fe.Textarea("content", "Content", "main"),
        Fe.Image("image", "Image", "primary 2"),
        Fe.Select("media_type", "Media Type", "primary 2", "Select Media Type", 'name', 'mediaTypes'),
        Fe.Text("media_path", "Media Path", "primary 2","ID for YouTube/vimeo, full URL otherwise"),
        Fe.Text("alt_media_path", "Alternative Media Path", "primary 2","Path for other media type"),
        Fe.Number("min_plan_days", "Min. days in plan", "primary"),
        Fe.Number("max_plan_days", "Max. days in plan", "primary"),
        Fe.Number("min_updates", "Min. updates", "primary 3"),
        Fe.Number("max_updates", "Max. updates", "primary 3"),
        Fe.Date("fixed_date", "Fixed Date", "primary 3"),
        Fe.Checkbox("expired_only", "Only for expired", "small"),
    ]

}
