<template>
  <Panel :title="t('Service Modules')">
    <ItemList :fields="fields" :items="modules" @edit="onEdit" @test="onTest" :full="false" />
  </Panel>
</template>

<script>
import t from "@/lib/Locale";
import Panel from "@/components/ui/layout/Panel";
import ItemList from "@/components/ui/lists/ItemList";
export default {
  name: "ServiceModulesForm",
  props: {
    modules: Array,
    serviceId: Number,
  },
  components: {ItemList, Panel},
  data() {
    return {
      editing: null,
      editingIndex: null,
      testing: null,
      fields: [
        {label:"Name", prop: 'name', primary: true},
        {label:"Path", prop: 'path', above: 2},
      ]
    }
  },
  computed : {
  },
  methods: {
    t,
    onEdit(endpoint,index) {
      if (this.testing && !this.editing) {
        this.testing = this.editingIndex = null;
      }
      if (this.editingIndex === index) {
        this.editing = this.editingIndex = null;
      }
      else {
        this.editing = endpoint;
        this.editingIndex = index;
      }
    },

    onTest(endpoint,index) {
      if (this.editing && !this.testing) {
        this.editing = this.editingIndex = null;
      }
      if (this.editingIndex === index) {
        this.testing = this.editingIndex = null;
      }
      else {
        this.testing = endpoint;
        this.editingIndex = index;
      }
    },

    onSave(newData) {
      this.$set(this.endpoints, this.editingIndex, {...this.editing, ...newData, is_active: true})
      this.editing = this.editingIndex = null;
    }
  }
}
</script>
