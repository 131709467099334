<template>
  <input :type="inputType" :class="clsName" :placeholder="inputPlaceholder" :disabled="disabled" :value="val" @keyup="e => onChange(e)" @change="e => onChange(e)" autocomplete="none" />
</template>
<script>

import t from '@/lib/Locale';

export default {
  name: "Input",
  props : {
    placeholder: { type: String, default: '' },
    value: { type: [String, Number, Boolean], default: '' },
    password: { type: Boolean },
    number: { type: Boolean },
    date: { type: Boolean },
    datetime: { type: Boolean },
    disabled: { type: Boolean },
    checkbox: { type: Boolean },
    size: { type: String, default: '' },
    mood: {type: String, default: ''},
  },
  data() {
    return {
      val: ''
    }
  },
  mounted() {
    this.val = this.value;
  },
  computed : {
    clsName() {
      let s = '';
      if (this.mood && ['primary','danger','warning','success','saving'].indexOf(this.mood)>-1) { if (s) s+=' '; s += this.mood }
      if (this.size && ['min','med','max'].indexOf(this.size)>-1) { if (s) s+=' '; s += this.size }
      return s
    },
    inputType() {
      if (this.password) return 'password'
      else if (this.number) return 'number'
      else if (this.date) return 'date'
      else if (this.datetime) return 'datetime-local'
      else if (this.checkbox) return 'checkbox'
      return 'text'
    },
    inputPlaceholder() {
      return t(this.placeholder);
    }
  },
  methods : {
    onChange(e) {
      this.$emit('input', e.target.value, e.target)
    }
  },
  watch : {
    value() {
      this.val = this.value
    }
  }
}
</script>
