import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        subscribers : [],
        currentSubscriber : null,
        pending : [],
    },
    getters : {
        currentSubscriber: (state) => state.currentSubscriber,
    },
    actions : {
        async resetPassword({dispatch}, [password, id]) {
            const res = await ApiRequest(ApiCall(Api.SubscriberResetPassword, {password}, id));
            if (res.error) return dispatch('setError', res.error)
            dispatch('setSuccess', 'Successfully Reset Password')
        },

        async getSubscriber({commit, dispatch}, id) {
            commit('setCurrentSubscriber', null)
            const res = await ApiRequest(ApiCall(Api.SubscriberGet, id));
            if (res.error) return dispatch('setError', res.error)
            commit('setCurrentSubscriber', res)
            return res
        },

        async getSubscribers({commit, dispatch}) {
            const res = await ApiRequest(ApiCall(Api.SubscriberList));
            if (res.error) return dispatch('setError', res.error)

            return await commit('setSubscribers', res);
        },
        async deleteSubscriber({commit, dispatch}, id) {
            const res = await ApiRequest(ApiCall(Api.SubscriberDelete, id))
            if (res.error) return dispatch('setError', res.error)
            return await commit('deleteSubscriber', id)
        },
        async updateSubscriber({commit, dispatch}, [subscriber, id]) {
            const res = await ApiRequest(ApiCall(Api.SubscriberUpdate, subscriber, id))
            if (res.error) return dispatch('setError', res.error)

            return await commit('saveSubscriber', [subscriber, id])

        },
        async createSubscriber({commit, dispatch}, subscriber) {
            if (!subscriber.password) subscriber.password = ''
            const res = await ApiRequest(ApiCall(Api.SubscriberAdd, subscriber))
            if (res.error) return dispatch('setError', res.error)
            subscriber.id = res.insertId
            return await commit('saveSubscriber', [subscriber, null])
        },
    },
    mutations : {
        setSubscribers: (state, subscribers) => {
            state.subscribers = subscribers.filter(it => it.subscription_active)
            state.pending = subscribers.filter(it => !it.subscription_active)
        },
        saveSubscriber: (state, [subscriber, id]) => {
            if (id) { state.subscribers = state.subscribers.map(it => it.id === subscriber.id ? subscriber : it) }
            else { state.subscribers = [...state.subscribers, subscriber]; }
            return state
        },
        setCurrentSubscriber: (state, subscriber) => {
            state.currentSubscriber = subscriber;
        },
        deleteSubscriber(state, id) { state.subscribers = state.subscribers.filter(it => it.id !== id) },
    }
}
