export default [
    {to: '/subscribers', label: 'Subscribers'},
    {to: '/transactions', label: 'Transactions'},
    {to: '/pending', label: 'Pending'},
    {to: '/plans', label: 'Plans', feature: 'plans'},
    {to: '/videos', label: 'Videos', feature: 'videos'},
    {to: '/landing-pages', label: 'Landing Pages', feature: 'landing pages'},
    {to: '/media-lists', label: 'Media Lists', feature: 'media lists'},
    {to: '/exclusives', label: 'Exclusives', feature: 'exclusives'},
    {to: '/coupons', label: 'Coupons', feature: 'coupons'},
    {to: '/courses', label: 'Courses', feature: 'courses'},
    {to: '/mailers', label: 'Mailers', feature: 'mailers'},
    {to: '/mailer-templates', label: 'Mailer Templates', feature: 'mailers'},
    {to: '/notifications', label: 'Subscriber Notifications', feature: 'notifications'},
    {to: '/email-templates', label: 'E-mail Notifications', feature: 'templates'},
    {to: '/whatsapp', label: 'Whatsapp Messages', ability: 'whatsapp'},
    {to: '/reports', label: 'Reports', feature: 'reports'},
    {to: '/settings', label: 'Settings', permission: 'BRAND_MANAGER'},
    {to: '/brands', label: 'Brands', type: 'admin', permission: 'ADMIN'},
    {to: '/users', label: 'Users', type: 'admin', permission: 'ADMIN'},
    {to: '/user-roles', label: 'Roles', type: 'admin', permission: 'ADMIN'},
    {to: '/integrations', label: 'Integrations', type: 'admin', permission: 'ADMIN'},
    {to: '/services', label: 'Services', type: 'admin', permission: 'ADMIN'},
    {to: '/actions', label: 'Actions', type: 'admin', permission: 'ADMIN'},
]
