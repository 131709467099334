import Vue from 'vue'
import App from './App.vue'
import store from './store';
import modules from "./modules";
import Modules from "./lib/Modules";
import VueRouter from 'vue-router';
import routes from "@/config/Routes";

for (let module in modules) {
  Modules.createStoreModule(modules[module], store)
  Modules.createModuleRoutes(modules[module], routes)
}


const router = new VueRouter({
  mode: 'hash',
  routes,
});

Vue.use(VueRouter);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
