import {mapActions, mapGetters} from "vuex";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {

    data() {
        return {
            // override these:
            type: '(Instance)',
            path: 'unknown-path',
            fields : [],
            async Create() {},
            async Update() {},
            defaultData : {},
            initLoad : [],
            collection : [],
            locale : { get: '', create : '', update : '', }, // Api.endpoints
            localeFields : [],

            // these should be managed here:
            instanceId : null,
            instance : null,
            newImages: null,
            locales: null,
            collectionProp : null,
            processData : null,
        }
    },

    computed : {
        ...mapGetters(['isLoggedIn','error']),
    },

    methods : {
        ...mapActions(['setLoading','setSuccess']),

        async onSubmit() {
            await this.setLoading(true);

            // upload new images!
            let res;
            for (let prop in this.newImages) {
                res = await ApiRequest(ApiCall(Api.ImageUpload, {file: this.newImages[prop]}))
                if (res.location) this.$set(this.instance, prop, res.location)
            }

            if (this.instance.id) { //update
                await this.Update([this.instance, this.instance.id])
                if (this.processData) this.instance = this.processData(this.instance)
            }
            else { // create new
                await this.Create(this.instance);
                if (this.instance.id) {
                    await this.$router.push('/'+this.path+'/'+this.instance.id)
                    this.instance = {...this.instance }
                    this.collection = this[this.collectionProp]
                    if (this.processData) this.instance = this.processData(this.instance)
                    await this.initData()
                }
            }
            await this.setLoading(false);
            if (!this.error) await this.setSuccess(this.type+" saved");
        },

        onUpdate(values, newImages = null) {
            this.newImages = newImages && Object.keys(newImages).length > 0 ? newImages : null;
            this.instance = {...this.instance, ...values};
            if (typeof this.processData === 'function') this.instance = this.processData(this.instance)
        },

        async initInstance(collectionProp, loadIf, processData = null) {
            await delayedIfLoading(!this.isLoggedIn, actionIf(loadIf), this.setLoading, () => this.isLoggedIn, () => this.$router.push('/login'))
            this.collection = typeof this.allItems === "function" ? this.allItems() : this[collectionProp]
            this.collectionProp = collectionProp;
            this.processData = processData;
            await this.initData()
        },

        async initData() {
            this.instance = !isNaN(this.$route.params.id*1) ? this.collection.find(it => it.id === this.$route.params.id*1) :
                { ...this.defaultData }
            if (typeof this.processData === 'function') this.instance = this.processData(this.instance)
        },

    }
}
