import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        permissions : [],
    },
    getters : {
        allPermissions : (state) => state.permissions,
    },
    actions : {
        async getPermissions({commit}) {
            const res = await ApiRequest(ApiCall(Api.GetAllPermissions));
            console.log(res)
            return await commit('setPermissions', res.permissions.map(it => ({permission: it})));
        },
    },
    mutations : {
        setPermissions: (state, permissions) => { state.permissions = permissions},
    }
}
