<template>
  <Panel :title="t('Reset Password')" :small="true">
    <form @submit="onSubmit">
      <Flexy :even="true">
        <Input placeholder="New Password" v-model="newPassword" :password="true" tabindex="1" />
        <Button @click="onClick" tabindex="2" :disabled="!isValid">{{t('Reset Password')}}</Button>
      </Flexy>
      <Txt mood="danger" v-if="error">{{t('Error: {0}', t(error))}}</Txt>
    </form>
  </Panel>
</template>

<script>
import Flexy from "@/components/ui/layout/Flexy";
import Input from "@/components/ui/form/Input";
import Button from "@/components/ui/form/Button";
import Txt from "@/components/ui/utils/Txt";
import Panel from "@/components/ui/layout/Panel";
import t from '@/lib/Locale';

export default {
  name: "ResetPasswordForm",
  components: {Panel, Txt, Button, Input, Flexy},
  props: {
    onSubmit : { type: Function, }
  },
  data() {
    return {
      newPassword: '',
      error: ''
    }
  },
  methods : {
    t,
    onClick() {
      if (this.isValid) {
        this.onSubmit(this.newPassword)
        this.newPassword = '';
      }
    }
  },
  computed : {
    isValid() {
      return this.newPassword.length >= 8
    }
  }
}
</script>
