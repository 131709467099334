<template>
  <ul :class="className" ref="list">
    <li v-for="(item ,i) in shownItems" :class="itemClass(i)" :key="i">
      <router-link :to="item.to">{{t(item.label)}}</router-link>
    </li>
    <li v-if="more > 0 && shownItems.length > 0" :class="testing?'test':''" @click="toggleShowMore" ref="more">
      <a>
        <Txt>{{t(moreLabel)}}</Txt>
        <Icon :icon="moreIcon" height="15" width="9" rotate="90" :link="true" />
      </a>
      <NavFloatingMenu v-if="showMore && shownItems.length > 0" :items="hiddenItems" :container="$refs.more" constrain-x="right" :dismiss="toggleShowMore" />
    </li>
    <li v-show="shownItems.length === 0" @click="toggleShowMore" ref="mini_menu" class="menu-toggle">
      <a>
        <Icon :icon="menuIcon" height="18" width="18" :link="true" />
        <Txt>{{t(menuLabel)}}</Txt>
      </a>
      <NavFloatingMenu v-if="showMore && shownItems.length === 0" :items="hiddenItems" :container="$refs.mini_menu" constrain-x="right" :dismiss="toggleShowMore" />
    </li>
  </ul>
</template>

<script>

import Icons from "@/lib/Icons";
import Icon from "@/components/ui/utils/Icon";
import NavFloatingMenu from "@/components/ui/navigation/NavFloatingMenu";
import Txt from "@/components/ui/utils/Txt";
import t from '@/lib/Locale';
import {mapGetters} from "vuex";

const sleep = (ms) => new Promise(res => setTimeout(() => res(), ms))
let resizeTimer;

export default {
  name: "NavMenu",
  components: {Txt, NavFloatingMenu, Icon},
  props : {
    items : Array,
    safetyMargin: {type: Number, default: 80},
    testSlack: {type: Number, default: 40},
    menuLabel: {type: String, default: "Menu"},
    moreLabel: {type: String, default: "More..."},
    icon : {type: Object, default: null},
    onlyToggle: {type: Boolean, default: false},
    hide : {type: Array},
    type : {type: String, default: null}
  },
  async mounted() {
    setTimeout(() => this.onResize(),100)
    if (!this.onlyToggle) window.addEventListener('resize', this.resize)
    if (this.icon) this.menuIcon = this.icon
  },
  beforeDestroy() {
    if (!this.onlyToggle) window.removeEventListener('resize', this.resize)
  },
  data() {
    return {
      moreIcon: Icons.caret,
      menuIcon: Icons.burger,
      more : 0,
      testing : false,

      showMore: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser','appAbilities']),

    className() {
      let cls = 'nav-menu';
      return cls
    },

    allowedItems() {
      let items = this.items;
      items = items.filter(it => !this.hide || this.hide.indexOf(it.to) < 0)
      if (this.type !== null) items = items.filter(it => (this.type && it.type === this.type) || (!this.type && !it.type))
      return items.filter(it =>
            (!it.feature || this.hasFeature(it.feature)) &&
            (!it.ability || this.hasAbility(it.ability)) &&
          (!it.permission || this.hasPermission(it.permission))
      )
    },

    shownItems() {
      if (this.onlyToggle) return []
      return this.allowedItems.filter((it,i) => this.allowedItems.length-i > this.more)
    },
    hiddenItems() {
      if (this.onlyToggle) return this.allowedItems
      return this.allowedItems.filter((it,i) => this.allowedItems.length-i <= this.more)
    }
  },
  methods: {
    t,
    toggleShowMore() {
      this.showMore = !this.showMore
    },

    hasPermission(permission = null) {
      if (!this.currentUser) return false
      if (this.currentUser.admin) return true
      for (let perm of this.currentUser.permissions) if (perm === permission) return true
    },

    hasAbility(ability = null) {
      for (let a of this.appAbilities) {
        if (a === ability) return true
      }
    },

    hasFeature(feature) {
      if (!this.currentUser || !this.currentUser.app || !this.currentUser.app.features) return false
      for (let feat of this.currentUser.app.features) if (feat === feature) return true
    },

    itemClass(i) {
      return this.testing && this.items.length-i <= this.more+1 ? 'test':'';
    },

    resize() {
      if (resizeTimer) clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => this.onResize(), 1);
    },

    async onResize() {
      if (this.onlyToggle) return;

      let widths = this.getWidths()
      if (!widths) return;
      let breakDistance = widths.total - this.safetyMargin - widths.items
      this.testing = true
      while (breakDistance > this.safetyMargin+this.testSlack && this.more > 0) {
        this.more--
        await sleep(1)
        widths = this.getWidths()
        if (!widths) break;
        breakDistance = widths.total - widths.items
      }

      breakDistance = widths.total - this.safetyMargin - widths.items
      this.testing = false
      while (breakDistance < 0 && this.more < this.allowedItems.length) {
        this.more++
        await sleep(1)
        widths = this.getWidths()
        if (!widths) break;
        else breakDistance = widths.total - this.safetyMargin - widths.items
      }
    },

    getWidths() {
      if (!this.$refs.list) return
      const li = this.$refs.list.querySelectorAll('li')
      let items = 0, s;
      for (let l of li) {
        s = getComputedStyle(l)
        if (l.classList.contains('menu-toggle')) continue;
        items += l.clientWidth + (parseInt(s.marginLeft)) + (parseInt(s.marginRight));
      }

      const total = this.$refs.list.getBoundingClientRect().width

      return {items, total }
    },

  },
  watch : {
    type() {
      this.resize()
    },
    items() {
      this.resize()
    }
  }
}
</script>
