<template>
  <div :class="clsName">
    <Flexy v-if="!recurse">
      <Txt :bold="mini" class="node-label">{{propText}}</Txt>
      <Txt :bold="true" class="node-value" v-if="!mini">{{value}}</Txt>
    </Flexy>
    <Flexy v-if="recurse">
      <Flexy @click.native="$emit('collapse')" class="node-label collapsible" v-if="!base">
        <Icon mood="primary" :icon="collapsed?Icons.plus:Icons.minus" :tiny="true" />
        <Txt :mood="nodeMood" :bold="!collapsed">{{propText}}</Txt>
      </Flexy>
      <Flexy v-if="base" @click.native="$emit('collapse')" class="node-value">
        <Icon mood="primary" :icon="collapsed?Icons.plus:Icons.minus" :tiny="true" />
        <Txt :interactive="true" :bold="!collapsed" :text="nodeType" :mood="nodeMood" />
      </Flexy>
      <Flexy @click.native="$emit('collapse')" v-if="collapsed" class="node-value">
        <Txt v-if="collapsed" :small="true" mood="primary" :interactive="true">{{collapsedPreview}}</Txt>
      </Flexy>
      <Flexy v-if="!collapsed">
        <Flexy v-if="recurse" :col="!isMini(value)">
        <JsonBrowserNode v-for="(a ,ii) in minifyValues" :key="ii" :value="a" :prop="ii" :collapsed="isCollapsed(ii)" @collapse="() => toggleCollapse(ii)" :mini="isMini(value)" />
        </Flexy>
      </Flexy>
    </Flexy>
  </div>
</template>
<script>

import Txt from "@/components/ui/utils/Txt";
import Flexy from "@/components/ui/layout/Flexy";
import t from '@/lib/Locale';
import Icon from "@/components/ui/utils/Icon";
import Icons from "@/lib/Icons";

export default {
  name: "JsonBrowserNode",
  components: {Icon, Flexy, Txt},
  props: {
    mini : { type: Boolean, default: false},
    collapsed: {type: Boolean, default: false},
    base: {type: Boolean, default: false},
    prop: {type: [Object, Array, Number, String, Boolean]},
    value: {type: [Object, Array, Number, String, Boolean, null]},
  },
  data() {
    return {
      Icons,
      collapseChild: true,
      expanded:[],
    }
  },
  computed : {
    clsName() {
      let n = 'browser-node';
      if (this.mini) n += ' mini'
      return n
    },
    nodeMood() {
      return this.nodeType==='Object'?'warning':'success'
    },
    propText() {
      if (this.mini) {
        if (typeof this.prop === 'number') return this.value
        return this.prop+' : '+this.value
      }
      if (typeof this.prop === 'number') {
        if (this.value && this.value.name) return this.value.name.substr(0, 20)+(this.value.name.length>20?'...':'')
        else if (this.value && this.value.title) return this.value.title.substr(0, 20)+(this.value.title.length>20?'...':'')
        return t('Item {0}',(this.prop+1))
      }
      return this.prop;
    },
    recurse() {
      return this.value !== null && typeof this.value === 'object'
    },
    collapsedPreview() {
      if (this.value === null) return t('null')
      if (this.value instanceof Array) return t("{0} items", this.value.length)
      else if (typeof this.value === 'object') {
        if (this.isMini(this.value)) return t("{0} items", Object.keys(this.value).length)
        return t("{0} props", Object.keys(this.value).length)
      }
      return '-'
    },
    nodeType() {
      if (this.value instanceof Array) return 'Array'
      return 'Object'
    },
    minifyValues() {
      if (this.isMini(this.value) && this.value instanceof Array) {
        const res = [...this.value]
        return res.sort((a,b) => a<b?-1:1)
      }
      return this.value
    },
  },
  methods: {

    isCollapsed(index) {
      return !(this.expanded[index]);
    },
    toggleCollapse(index) {
      this.$set(this.expanded, index, !this.expanded[index])
    },
    isMini(values) {
      if (values instanceof Array && values.filter(it => typeof it === 'object').length === 0) return true
      for (let v in values) if (values[v].toString().length > 1 || values[v] > 1) return false
      return true
    }
  }


}
</script>
