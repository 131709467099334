<template>
  <div class="content-mockup">
    <Flexy :even="true" v-for="r in rowList" :key="r">
      <div :class="isList?'mock-row':'mock-box'"></div>
      <div class="mock-box" v-if="!isList"></div>
    </Flexy>
  </div>
</template>

<script>
import Flexy from "@/components/ui/layout/Flexy";
export default {
  name: "ContentMockup",
  components: {Flexy},
  props: {
    isList : {type: Boolean, default: false },
    rows : {type: Number, default: null },
  },
  computed : {
    rowList() {
      const rows = this.rows ? this.rows : (this.isList ? 20 : 4)
      const r = [];
      for (let i = 0; i < rows; i++) r.push(i)
      return r;
    }
  }
}
</script>
