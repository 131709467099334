import Vuex from 'vuex';
import Vue from 'vue';
import app from './modules/app';
import actions from './modules/actions';
import admin from './modules/admin';
import brands from './modules/brands';
import integrations from './modules/integrations';
import login from './modules/login';
import services from './modules/services';
import subscribers from './modules/subscribers';

Vue.use(Vuex);

export default new Vuex.Store({
    modules : {
        actions,
        admin,
        brands,
        app,
        integrations,
        login,
        services,
        subscribers,
    }
})
