export default {
    "Subscribers":"מנויים",
    "Transactions":"עסקאות",
    "Pending":"בהמתנה",
    "Plans":"תוכניות",
    "Videos":"סרטונים",
    "Media Lists":"רשימות מדיה",
    "Exclusives":"הטבות",
    "Coupons":"קופונים",
    "Subscriber Notifications":"הודעות למנויים",
    "E-mail Templates":"תבניות למיילים",
    "Reports":"דוחות",
    "Menu":"תפריט",
    "ID":"ID",
    "Subscriber Name":"שם מנוי",
    "Subscriber Email":"אימייל מנוי",
    "Sum":"סכום",
    "Date":"תאריך",
    "Status":"סטטוס",
    "Transaction #":"מספר עסקה",
    "Code":"קוד",
    "Invoice #":"חשבונית",
    "Actions":"פעולות",
    "Add Transaction":"הוסף עסקה",
    "Search...":"חיפוש..",
    "Subscriber ID":"מזהה מנוי",
    "Amount":"סכום",
    "Transaction Key":"מפתח עסקה",
    "Transaction Code":"קוד עסקה",
    "Coupon Code":"קוד קופון",
    "Coupon Multi-Code":"מולטי קופון",
    "Description":"תיאור",
    "Transaction":"עסקה",
    "Create {0}":"יצירת {0}",
    "Update {0}":"עדכון {0}",
    "Name":"שם",
    "First Name":"שם פרטי",
    "Last Name":"שם משפחה",
    "E-mail":"אימייל",
    "Gender":"מגדר",
    "Reg Date":"ת. הרשמה",
    "Pending Subscribers":"מנויים בהמתנה",
    "Subscriber":"מנוי",
    "E-Mail":"אימייל",
    "Phone":"טלפון",
    "Recovery Code":"קוד שחזור",
    "Subscription Active":"מנוי פעיל",
    "Subscription Renewal":"מנוי מתחדש",
    "VIP Subscriber":"מנוי VIP",
    "Charge Interval (months)":"חיוב כל (חודשים)",
    "First Registration Date":"תאריך הרשמה ראשוני",
    "Subscription Date":"תאריך רכישת מנוי",
    "No items were found..":"לא נמצאו תוצאות..",
    "Sub Date":"ת. רכישה",
    "Plan":"תוכנית",
    "Confirm":"אישור",
    "Cancel":"ביטול",
    "Expired":"פג תוקף",
    "Add Subscriber":"הוספת מנוי",
    "Filter By {0}":"סינון לפי {0}",
    "Subject":"נושא",
    "Add E-mail Template":"הוספת תבנית מייל",
    "Notifications":"הודעות",
    "Add Notification":"הוסף הודעה",
    "Title":"כותרת",
    "Days in plan":"ימים בתוכנית",
    "Progress":"עדכונים",
    "Expired Only":"למנוי פג-תוקף בלבד",
    "Length (words)":"אורך (מילים)",
    "#":"#",
    " ":" ",
    "Headline":"כותרת",
    "Add Exclusive":"הוספת הטבה",
    "Type":"סוג",
    "Start Date":"ת. התחלה",
    "Expiry Date":"ת. תפוגה",
    "Add Coupon":"הוספת קופון",
    "Active":"פעיל",
    "Canceled":"מבוטל",
    "Valid":"תקף",
    "{0} days":"{0} יום",
    "Days Left":"ימים נותרו",
    "More...":"עוד...",
    "Display Name":"שם תצוגה",
    "Media Type":"סוג מדיה",
    "Folder":"תיקיה",
    "Items":"פריטים",
    "Add Item":"הוספת פריט",
    "Sorting":"מיון",
    "Add Media List":"הוספת רשימת מדיה",
    "Duration (minutes)":"משך (דקות)",
    "Add Video":"הוספת סרטון",
    "Price":"מחיר",
    "Duration":"משך",
    "Add Plan":"הוספת תוכנית",
    "Duration (days)":"משך (ימים)",
    "Price (real)":"מחיר (סופי)",
    "Full Price (not real)":"מחיר מלא (לא סופי)",
    "Target URL":"כתובת URL",
    "Active?":"פעיל?",
    "Archived?":"ארכיון?",
    "Video":"סרטון",
    "Path":"נתיב",
    "Thumbnail":"תמונה",
    "Media List":"רשימת מדיה",
    "Display Content":"להציג את התוכן",
    "Exclusive":"הטבה",
    "Conditions":"תנאים",
    "External URL":"קישור חיצוני",
    "Coupon":"קופון",
    "Discount Type":"סוג הנחה",
    "Starts On":"מתחיל ב",
    "Expires On":"בתוקף עד",
    "Notification":"הודעה",
    "Content":"תוכן",
    "Min. days in plan":"מינ. ימים בתוכנית",
    "Max. days in plan":"מקס. ימים בתוכנית",
    "Min. updates":"מינ. עדכונים",
    "Max. updates":"מקס. עדכונים",
    "Only for expired":"רק לפגי תוקף",
    "E-mail Template":"תבנית מייל",
    "Cancellation Date":"תאריך ביטול",
    "Key":"תכונה",
    "Value":"ערך",
    "Valid Until:":"בתוקף עד",
    "Meta Data":"תכונות",
    "{0} more days":"בעוד {0} ימים",
    "Subscription active":"מנוי פעיל",
    "Renews on:":"מתחדש בתאריך:",
    "Subscription Details":"פרטי מנוי",
    "Subscription cancelled":"מנוי בוטל:",
    "Expired:":"פג תוקף:",
    "{0} days ago":"לפני {0} ימים",
    "Reset Password":"איפוס סיסמה",
    "Activate Account":"הפעלת חשבון",
    "Signup Welcome":"ברוכים הבאים",
    "Progress Reminder":"תזכורת לעדכן",
    "New Password":"סיסמה חדשה",
    "Are you sure?":"אתם בטוחים?",
    "Do you really want to delete the {0}":"האם אתם באמת רוצים למחוק את ה{0}",
    "Today":"היום",
    "This Week":"השבוע",
    "Month":"חודש",
    "Year":"שנה",
    "Select Status":"בחירת סטטוס",
    "Success":"הצלחה",
    "Error":"שגיאה",
    "Select Gender":"בחירת מגדר",
    "Male":"זכר",
    "Female":"נקבה",
    "Other":"אחר",
    "Active Subscribers":"מנויים פעילים",
    "Pending Leads":"לידים בהמתנה",
    "Subscription Plans":"תוכניות מנויים",
    "Income":"הכנסות",
    "Sales":"מכירות",
    "Summary":"סיכום",
    "New Signups":"הרשמות חדשות",
    "Plan Breakdown":"התפלגות תוכניות",
    "Plan Overview":"נתונים כלליים",
    "General Statistics":"סטטיסטיקה ראשית",
    "My Settings":"ההגדרות שלי",
    "Night Mode":"מצב לילה",
    "Log Out":"יציאה מהמערכת",
    "Log In":"התחברות למערכת",
    "Username":"שם משתמש",
    "Password":"סיסמה",
    "Login":"התחברות",
    "Login failed":"התחברות נכשלה",
    "Error: {0}":"שגיאה: {0}",
    "Please note: these settings only apply locally. Using a different device will reset them.":"שימו לב: ההגדרות האלו תקפות רק מקומית. הן לא ישמרו על מכשיר אחר.",
    "Use a dark theme when your eyes hurt":"השתמשו בתבנית כהה כשכואבות לכם העיניים",
    "Locale":"שפה/מיקום",
    "Services":"שירותים",
    "Add Service":"הוספת שירות",
    "URL":"כתובת URL",
    "Service":"שירות",
    "Token":"טוקן",
    "Service Validation":"אימות השירות",
    "Service Validated":"השירות אומת",
    "The service was successfully validated.":"השירות הזה אומת בהצלחה",
    "Service Endpoints":"נקודות קצה של השירות",
    "Method":"מתודה",
    "Edit Service Endpoint":"עריכת נקודת קצה",
    "Endpoint Name:":"שם נ. קצה",
    "Endpoint Method:":"מתודת נ. קצה",
    "Endpoint Path:":"כתובת נ.קצה",
    "Cache result?":"לשמור עותק מקומי של התוצאה?",
    "Permission":"הרשאת גישה",
    "Description (optional)":"תיאור (לא חובה)",
    "Set Endpoint Configuration":"שמור הגדרת נ. קצה",
    "Cache Key":"שם עותק מקומי",
    "Reload after seconds (optional)":"טעינה מחדש לאחר שניות (לא חובה)",
    "Who can reload cache?":"מי יכול לטעון מחדש עותק שמור?",
    "Test Service Endpoint":"בדיקת נ. קצה",
    "Test Endpoint":"בדיקת הנקודה",
    "No cache":"לא לשמור עותק",
    "Settings":"הגדרות",
    "Custom Settings":"הגדרות מותאמות",
    "Note:":"שימו לב:",
    "Brand Settings":"הגדרות המותג",
    "App Limits":"מגבלות שימוש",
    "Action path. Use something short and simple":"נתיב עבור הפעולה. השתמשו במשהו פשוט וקצר",
    "Name for this action":"שם עבור פעולה זו. לתצוגה בלבד.",
    "Optional":"לא חובה",
    "Image":"תמונה",
    "video":"וידאו",
    "image":"תמונה",
    "Thumbnail image":"תמונת נושא",
    "App Settings":"הגדרות האפליקציה",
    "Preview:":"תצוגה מקדימה:",
    "Action":"פעולה",
    "Add Action":"הוספת פעולה",
    "Commands":"פקודות",
    "App Title":"כותרת אפליקציה",
    "App Logo":"לוגו אפליקציה",
    "App Icon":"אייקון אפליקציה",
    "Brand application URL":"כתובת האינטרנט של האפליקציה של המותג",
    "Management app custom header title":"כותרת מותאמת לאפליקציית הניהול",
    "Management app header logo":"תמונת לוגו לאפליקציית הניהול",
    "Management app browser icon":"אייקון דפדפן לאפליקציית הניהול",
    "Save Settings":"שמירת הגדרות",
    "General Settings": "הגדרות כלליות",
    "Please note: these settings will apply to all the users of the app.":"שימו לב: הגדרות אלו יחולו על כל משתמשי האפליקציה.",
    "API Settings":"הגדרות API",
    "Successfully saved settings":"ההגדרות נשמרו בהצלחה",
    "Site" :"לאתר",
    "Here are your API Keys":"הנה מתפחות ה-API שלכם",
    "Use them with Fito toolkit or your own implementation to create your user experience.":"השתמשו בהם עם Fito Toolkit או באמצעות מימוש משלכם כדי ליצור את חויית המשתמש שלכם.",
    "Subscriber Meta":"תכונות המנוי",
    "Admin":"אדמין",
    "Brand Manager":"מנהל מותג",
    "Debit":"הוראת קבע",
    "Credit":"תשלום באשראי",
    "Refund" : "זיכוי",
    "Debit Canceled":"הוראה בוטלה",
    "debit":"הוראת קבע",
    "credit":"תשלום באשראי",
    "refund":"זיכוי",
    "Debit Cancellation Date":"ת. ביטול הוראת קבע",
    "Debit Amount":"סכום הוראת קבע",
    "Debit ID":"מזהה הוראת קבע",
    "Open":"פתוח",
    "Logged in Subscriber":"מנוי מחובר",
    "Active Subscriber":"מנוי פעיל",
    "Select language and locale settings.":"בחרו הגדרת שפה ומיקום",
    "Hide menu items":"הסתרת פריטים בתפריט",
    "Result":"תוצאה",
    "Users":"משתמשים",
    "Add User":"הוספת משתמש",
    "Brands":"מותגים",
    "Roles":"תפקידים",
    "Role":"תפקיד",
    "Admin?":"אדמין?",
    "Last Login":"התחברות אחרונה",
    "App Type":"סוג אפליקציה",
    "App URL":"כתובת אפליקציה",
    "Created":"נוצר בתאריך",
    "Add Brand":"הוספת מותג",
    "Admin Menu":"תפריט אדמין",
    "User Roles":"תפקידים",
    "Add User Role":"הוספת תפקיד",
    "Brand":"מותג",
    "Contact E-mail":"אימייל ליצירת קשר",
    "Max Users":"מקס. משתמשים",
    "Max API Keys":"מקס. מפתחות API",
    "Allow Custom Settings":"הגדרות מותג חופשיות",
    "User":"משתמש",
    "admin":"אדמין",
    "Production API Key":"מפתח API סביבה חיה",
    "Development API Key":"מפתח API סביבת בדיקות",
    "Display Settings" : "הגדרות תצוגה",
    "Toggle between normal and admin menu" : "החלפה בין תפריט הניווט הרגיל ותפריט לאדמינים",
    "User Role":"תפקיד",
    "Select Brand":"בחרו מותג",
    "Select Role":"בחרו תפקיד",
    "{0} props":"{0} מאפיינים",
    "Object":"אובייקט",
    "{0} items":"{0} פריטים",
    "Item {0}":"פריט {0}",
    "Array":"מערך",

    "Integrations":"אינטגרציות",
    "Add Integration":"הוספת אינטגרציה",
    "Integration":"אינטגרציה",
    "Select Integration Type":"בחירת סוג אינטגרציה",
    "Integration Type":"סוג אינטגרציה",
    "Comments":"הערות",
    "Credentials":"פרטי התחברות",
    "Hooks":"הוקים",
    "Enter the required integration credentials below.":"הזינו למטה את פרטי הגישה של האינטגרציה",

    "Params":"פרמטרים",
    "Cmds":"פקודות",
    "Scope":"תחום",
    "Call Permissions":"הרשאת קריאה",

    "Call Permission(s)":"הרשאות קריאה",
    "Open Action (can be run by subscribers)":"פעולה פתוחה (מנויים יכולים להריץ אותה)",
    "Use a group name to group actions together":"הששמו בשם הקבוצה כדי לקבץ פעולות ביחד",
    "Group Name":"שם הקבוצה",
    "Group":"קבוצה",
    "Mandatory":"חובה",
    "Add Param":"הוספת פרמטר",
    "Add Command":"הוספת פקודה",
    "Test Action":"בדיקת הפעולה",
    "Allow Saving Meta":"שמירת תכונות בפועל",
    "Allow Saving Settings":"שמירת הגדרות מותג בפועל",
    "Edit Param":"עריכת פרמטר",
    "Edit Command":"עריכת פקודה",
    "Target":"מטרה",
    "Store":"שמירה",
    "Condition":"תנאי",
    "Condition Input {0}":"קלט תנאי {0}",
    "Either":"תנאי אחד מספיק",
    "Input":"קלט",
    "Input?":"קלט?",
    "Input to use as Target":"קלט שישמש בתור מטרה",

    "smoove":"סמוב",
    "Smoove":"סמוב",
    "Cardcom":"קארדקום",
    "S3 Upload":"העלאות S3",
    "cardcom":"קארדקום",
    "s3":"s3",
    "Integrate Smoove API actions for subscribers.":"אינטגרציה של פעולות סמוב בAPI למנויים",
    "Cardcom payment gateway integration":"אינטגרציה של תשלומים דרך קארדקום",
    "Add command: Perform refund":"הוספת פקודה: בצע זיכוי",
    "Add command: Deactivate Debit":"הוספת פקודה: עצור הוראה",
    "Add command: Reactive Debit":"הוספת פקודה: הפעל מחדש הוראה",
    "Add command: Update Debit Sum":"הוספת פקודה: עדכון סכום הוראה",
    "Add command: Update Debit Next Charge Date":"הוספת פקודה: עדכון תאריך חיוב הבא בהוראה",
    "Amazon S3 media upload integration":"אינטגרציה להעלאת מדיה לשירות S-3 של אמזון",
    "Add command: Import Subscribers to lists":"הוספת פקודה: יבוא מנויים לרשימות בסמוב",
}
