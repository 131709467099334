<template>
  <Modal :title="t('Are you sure?')" :cancel="cancel" :actions="{'Confirm': confirm}" :action-moods="{'Confirm':'danger'}">
    {{t('Do you really want to '+actionLabel+' {0}',t(itemType))}} <Txt :bold="true">{{itemName}}</Txt>
  </Modal>
</template>

<script>
import Modal from "@/components/ui/layout/Modal";
import t from '@/lib/Locale'
import Txt from "@/components/ui/utils/Txt";

export default {
  name: "ConfirmModal",
  components: {Txt, Modal},
  props: {
    itemType: {type: String},
    itemName : {type: String},
    confirm: {type: Function},
    cancel: {type: Function},
    actionLabel: {type: String, default: "delete the"},
  },
  methods : {
    t
  }
}
</script>
