<template>
  <Panel title="Test Service Endpoint">
      <Flexy :between="true">
        <Txt>{{t("Endpoint Name:")}} <Txt :bold="true">{{endpoint.name}}</Txt></Txt>
        <Txt>{{t("Endpoint Method:")}} <Txt :bold="true">{{endpoint.method}}</Txt></Txt>
        <Txt>{{t("Endpoint Path:")}} <Txt :bold="true">{{endpoint.path}}</Txt></Txt>
      </Flexy>
      <Button @click="testEndpoint" text="Test Endpoint" />
      <Textarea :rows="8" :disabled="true" placeholder="Result" v-model="result" class="mono" />
  </Panel>
</template>

<script>
import t from "@/lib/Locale";
import Panel from "@/components/ui/layout/Panel";
import Flexy from "@/components/ui/layout/Flexy";
import Txt from "@/components/ui/utils/Txt";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import Button from "@/components/ui/form/Button";
import Textarea from "@/components/ui/form/Textarea";

export default {
  name: "EndpointTester",
  props: {
    endpoint: Object,
    serviceId: Number
  },
  components: {Textarea, Button, Txt, Flexy, Panel},
  data() {
    return {
      result : "",
      params : {}
    }
  },
  mounted() {
  },
  computed : {

  },
  methods: {
    t,
    async testEndpoint() {
      this.result = t("Loading result...")
      console.log(this.endpoint)
      const res = await ApiRequest(ApiCall(Api.ServiceTest, this.params, {id: this.serviceId, action: this.endpoint.path}));
      this.result = JSON.stringify(res);
    }
  },
  watch : {
  }
}
</script>
