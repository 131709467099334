<template>
  <div class="navbar">
    <div class="success-bar" v-if="success">{{success}}</div>
    <div class="error-bar" v-if="error">{{error}}</div>
    <Flexy>

    <Flexy v-if="moduleBar" class="module-bar" :col="true">
      <Txt v-for="(service, ii) in availableServices" :key="ii" :bold="activeService===service.path?'active':''" @click.native="() => setActiveService(service.path)">{{service.label}}</Txt>
    </Flexy>

    <Button :small="true" @click="toggleModuleBar" v-if="appModules.length"><Icon :icon="Icons.caret" :rotate="90" :mood="activeService?'success':'primary'" /></Button>

    <router-link to="/">
      <Flexy :centered="true">
        <Icon :icon="Icons.crown" v-if="!appLogo" height="22" width="33" :loading="isLoading" />
        <img :class="'app-logo' + (isLoading?' loading':'')" v-if="appLogo" :src="appLogo" />
        <Flexy>
          <h1>
          <span>{{activeService? activeService : title}}</span>
          <Button mood="primary" v-if="appUrl && !activeService" :small="true" @click="gotoWebsite">{{t('Site')}}</Button>
          </h1>
        </Flexy>
      </Flexy>
    </router-link>
    </Flexy>

    <NavMenu :items="menuLinks" v-if="isLoggedIn" class="main-nav" :hide="appHiddenMenuItems" :type="menuType" />
    <NavMenu menu-label="" v-if="isLoggedIn" :only-toggle="true" :items="settingsLinks" class="user-nav" :icon="Icons.settings" />
  </div>
</template>

<script>
import NavMenu from "@/components/ui/navigation/NavMenu";
import Icons from '@/lib/Icons';
import t from '@/lib/Locale';

import {mapGetters} from "vuex";
import Icon from "@/components/ui/utils/Icon";
import Flexy from "@/components/ui/layout/Flexy";
import Button from "@/components/ui/form/Button";
import Txt from "@/components/ui/utils/Txt";
export default {
  name: "Navbar",
  components : {Txt, Button, Flexy, Icon, NavMenu},
  props : {
    'links' : Array,
    'settingsLinks': Array
  },
  data() {
    return {
      Icons,
      moduleBar: false,
      activeService: ""
    }
  },
  computed : {
    ...mapGetters(['isLoggedIn','isLoading','success','error','appHiddenMenuItems','appMenuType','appSettings','appModules']),

    menuLinks() {
      if (this.activeService) return this.serviceLinks(this.activeService)
      return this.links
    },

    menuType() {
      if (this.activeService) return null;
      return this.appMenuType
    },

    title() {
      if (this.appSettings.title) return this.appSettings.title
      return 'Fito Brands Admin'
    },

    appUrl() {
      if (this.appSettings.url) return this.appSettings.url
      return ''
    },

    appLogo() {
      if (this.appSettings.logo) return this.appSettings.logo
      return ''
    },

    availableServices() {
      const services = [{path: '', label: 'Brands (main)'}];
      for (let module of this.appModules) if (!services.find(it => it.path === module.service)) services.push({path: module.service, label: module.service})
      return services
    },


  },
  methods : {
    t,
    gotoWebsite(e) {
      window.open(this.appSettings.url)
      e.preventDefault()
      return false
    },
    toggleModuleBar() {
      this.moduleBar = !this.moduleBar
    },
    serviceLinks(service) {
      const links = []
      for (let module of this.appModules) if (module.service === service) links.push({to: '/module/'+module.path, label: module.name})
      return links;
    },
    setActiveService(service) {
      this.activeService = service;

      this.moduleBar = false;
    }
  }

}
</script>
