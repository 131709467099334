<template>
  <h1 :class="className">
    <a v-if="link" :href="link" :class="mood"><Txt v-if="text">{{t(text)}}</Txt><slot></slot></a>
    <router-link v-if="routerLink" :to="routerLink"><Txt v-if="text">{{t(text)}}</Txt><slot></slot></router-link>
    <Txt v-if="!link && !routerLink && text">{{t(text)}}</Txt><slot v-if="!link && !routerLink"></slot>
  </h1>
</template>

<script>
import Txt from "@/components/ui/utils/Txt";
import t from '@/lib/Locale';

export default {
  name: "Header",
  components: {Txt},
  props: ['mood', 'bold', 'link', 'small', 'routerLink','text'],
  computed: {
    className() {
      let cls = 'header';
      if (this.bold) cls += ' bold'
      if (this.small) cls += ' small'
      switch (this.mood) {
        case 'primary': cls += ' primary'; break;
        case 'success': cls += ' success'; break;
        case 'warning': cls += ' warning'; break;
        case 'danger': cls += ' danger'; break;
        case 'saving': cls += ' saving'; break;
      }
      return cls
    }
  },
  methods : {
    t,
  }
}
</script>
