import Brand from "./Brand";
import Coupon from "./Coupon";
import Course from "./Course";
import DebitCharge from "./DebitCharge";
import EmailTemplate from "./EmailTemplate";
import Exclusive from "./Exclusive";
import LandingPage from "./LandingPage";
import Mailer from "./Mailer";
import MailerTemplate from "./MailerTemplate";
import MediaList from "./MediaList";
import Notification from "./Notification";
import Pending from "./Pending";
import Plan from "./Plan";
import Subscriber from "./Subscriber";
import Transaction from "./Transaction";
import Video from "./Video";
import User from "./User";
import UserRole from "./UserRole";
import WhatsappMessage from "./WhatsappMessage";

export default {
    Brand,
    Coupon,
    Course,
    DebitCharge,
    EmailTemplate,
    Exclusive,
    LandingPage,
    Mailer,
    MailerTemplate,
    MediaList,
    Notification,
    Plan,
    Pending,
    Subscriber,
    Transaction,
    User,
    UserRole,
    Video,
    WhatsappMessage,
}
