<template>
  <Panel :title="t('Subscription Details')" :small="true">

    <Box v-if="chargeInterval" :col="true">
      <Flexy class="box-padding" :centered="true" >
        <Badge :mood="mood" />
        <Txt :title="true" :mood="mood" :text="title" />
      </Flexy>
      <Txt v-if="suspended">{{suspended}}</Txt>
      <Flexy :col="true">
        <Flexy :between="true" :base-padding="true">
          <Txt :text="timeText" />
          <Txt>{{timeDate}}</Txt>
        </Flexy>
        <Flexy :between="true" v-if="frozen">
          <Txt text="Releases on:" mood="primary" />
          <Txt mood="primary">{{releaseDate}} {{t('{0} more days', releaseDays)}}</Txt>
        </Flexy>
      </Flexy>
    </Box>

    <Box v-if="!chargeInterval" :col="true">
      <Flexy class="box-padding" :centered="true">
        <Txt :title="true" mood="danger" text="Charge Interval Required" />
      </Flexy>
    </Box>
  </Panel>
</template>

<script>
import Flexy from "@/components/ui/layout/Flexy";
import Txt from "@/components/ui/utils/Txt";
import Panel from "@/components/ui/layout/Panel";
import Badge from "@/components/ui/utils/Badge";
import {addMonths} from "@/lib/TimeUtils";
import t from '@/lib/Locale';
import Box from "@/components/ui/layout/Box";

export default {
  name: "SubscriptionCalculator",
  components: {Box, Badge, Panel, Txt, Flexy},
  props: {
    renew: { type: Boolean },
    expirationDate : { type: String, },
    suspended : { type: String, },
    subscriptionDate : { type: String, },
    cancellationDate : { type: String, },
    chargeInterval : { type: [String, Number], },
    inactiveUntil : { type: String, },
  },
  data() {
    return {
      newPassword: '',
      error: ''
    }
  },
  methods : {
    t,
    onClick() {
      if (this.isValid) {
        this.onSubmit(this.newPassword)
        this.newPassword = '';
      }
    },
  },
  computed : {

    mood() {
      if (this.renew) {
        if (this.suspended) return 'saving'
        if (this.frozen) return 'primary'
        return 'success'
      }
      return this.expired ? 'danger':'warning'
    },

    title() {
      if (this.renew) {
        if (this.frozen) return 'Subscription Frozen'
        if (this.suspended) return 'Subscription Suspended'
        return 'Subscription Active'
      }
      else return this.expired ? 'Subscription expired' : 'Subscription cancelled'
    },

    timeText() {
      if (this.renew) return 'Subscription renews on:'
      return this.expired ? 'Expired:' : 'Still valid Until:'
    },

    timeDate() {
      return this.renew ? this.renewDate : this.expiryDate
    },

    cancelledTimeText() {
      return this.expired ?
          this.expiryDate+', '+t('{0} days ago', -this.expiryDays) :
          this.expiryDate+', '+t('{0} more days', -this.expiryDays)
    },

    isValid() {
      return this.newPassword.length >= 8
    },

    releaseDate() {
      const d = new Date(this.inactiveUntil)
      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    },

    renewDate() {
      const now = new Date()
      let d = new Date(this.subscriptionDate);
      while (d < now) {
        d = addMonths(d, this.chargeInterval)
      }

      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    },

    expiryDate() {
      let d;
      if (this.expirationDate) {
        d = new Date(this.expirationDate)
      }
      else {
        const cd = new Date(this.cancellationDate)
        d = new Date(this.subscriptionDate)
        while (d < cd) d = addMonths(d, this.chargeInterval)
      }

      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    },

    frozen() {
      return this.inactiveUntil && new Date(this.inactiveUntil) > new Date()
    },

    expired() {
      if (this.renew) return false
      const now = new Date()
      if (this.expirationDate) return !this.renew && now > new Date(this.expirationDate)

      let d = new Date(this.subscriptionDate), cd = new Date(this.cancellationDate)
      while (d < cd) d = addMonths(d, this.chargeInterval)
      return d < now
    },

    releaseDays() {
      return Math.round((new Date(this.inactiveUntil).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24)
    },

    expiryDays() {
      const now = new Date()
      if (this.expirationDate) return Math.round((new Date(this.expirationDate).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24)

      let d = new Date(this.subscriptionDate), cd = new Date(this.cancellationDate)
      while (d < cd) d = addMonths(d, this.chargeInterval)

      return Math.round((d.getTime() - now.getTime()) / 1000 / 60 / 60 / 24)
    }
  },
}
</script>
