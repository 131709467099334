<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields" :meta="{permissions: allPermissions}" :title-prop="title" :full="true"
                :on-update="onUpdate" :on-submit="onSubmit">
    <template slot="main">
      <Flexy v-if="instance && instance.id" :col="true">
        <Header :small="true" text="Params" />
        <ItemList :full="false" :items="sortedParams" :fields="paramFields" @delete="onDeleteParam" @sort="onSortParam" @edit="onEditParam" />
        <Flexy :col="true" class="box-padding">
          <Txt :bold="true" :text="(editedCommand?'Edit':'Add')+' Param'" />
          <ParamForm :param="editedParam" :on-add="addParam" :on-edit="editParam" :on-cancel-edit="cancelEditParam" />
        </Flexy>
      </Flexy>

      <Flexy v-if="instance && instance.id" :col="true">
        <Header :small="true" text="Commands" />
        <DraggableList :items="sortedCommands" :fields="commandFields" @delete="onDeleteCommand" @sort="onSortCommand" @edit="onEditCommand" :compact="true" />
        <Flexy :col="true" class="box-padding">
          <Txt :bold="true" :text="(editedCommand?'Edit':'Add')+' Command'" />
          <CommandForm :command="editedCommand" :commands="sortedCommands" :params="instance.params" :on-add="addCommand" :on-edit="editCommand" :on-cancel-edit="cancelEditCommand" />
        </Flexy>
      </Flexy>
    </template>
    <template slot="sidebar-bottom">
      <TestActionForm v-if="instance && instance.id" :action="instance" :on-test="onTestAction" :results="testResults" :log="testLog" />
    </template>
  </InstanceDash>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import Fe from "@/lib/ui/FormElement";
import t from '@/lib/Locale';
import DraggableList from "@/components/ui/lists/DraggableList";
import Txt from "@/components/ui/utils/Txt";
import CommandForm from "@/components/forms/CommandForm";
import Icons from "@/lib/Icons";
import Flexy from "@/components/ui/layout/Flexy";
import TestActionForm from "@/components/forms/TestActionForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import ParamForm from "@/components/forms/ParamForm";
import ItemList from "@/components/ui/lists/ItemList";
import Header from "@/components/ui/utils/Header";
export default {

  name: "Action",
  mixins: [InstanceView],
  components: {Header, ItemList, ParamForm, TestActionForm, Flexy, CommandForm, Txt, DraggableList, InstanceDash,  },
  data() {
    return {
      editedCommand : null,
      editedParam : null,
      testResults: null,
      testLog: null,

      type: "Action",
      path: "actions",
      fields : [
        Fe.Text("path", "Path", "primary","Action path. Use something short and simple"),
        Fe.Text("name", "Name", "primary","Name for this action"),
        {...Fe.Date("created", "Created", "primary"), show : () => this.instance.id },
        Fe.Select("call_permission", "Call Permission(s)", "primary 2","","permission","permissions","permission",true,true),
        Fe.Checkbox("is_open", "Open Action (can be run by subscribers)", "primary 2"),
        Fe.Text("group_name", "Group Name", "primary 3","Use a group name to group actions together"),
        Fe.Textarea("description", "Description", "primary 4", t("Optional")),
      ],
      paramFields : [
        {prop: "name", label: "Name", width: '50%'},
        {prop: "is_mandatory", label: "Mandatory", type: "badge", mood:"danger", format: (r) => r?'Mandatory':''},
        { actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete", confirmation: (ing) => "Are you sre you want to delete '"+ing.name+"'?" },
            { mood: "primary", icon: Icons.pencil, action: "edit", tooltip: "Edit" }
          ]}
      ],
      commandFields : [
        {prop: "name", label: "Name", primary: true},
        {prop: "input", label: "Input", mood: "success", icon: Icons.play },
        {prop: "type", target: "type_label", label: "Type", format: this.commandType },
        {prop: "target", label: "Target", format: this.commandTarget, above: 2, mood:'primary' },
        {prop: "store", label: "Store", mood:"warning"},
        {prop: "condition_type", label: "Condition", type:'badge', mood:this.conditionMood, format: this.commandCondition},
        { actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete", confirmation: (ing) => "Are you sre you want to delete '"+ing.name+"'?" },
            { mood: "primary", icon: Icons.pencil, action: "edit", tooltip: "Edit" }
          ]}
      ],
      async Create(a) {
        if (a.call_permission && typeof a.call_permission === 'object') a.call_permission = a.call_permission.join(",")
        await this.createAction(a)
      },
      async Update(a) {
        if (a[0].call_permission && typeof a[0].call_permission === 'object') a[0].call_permission = a[0].call_permission.join(",")
        await this.updateAction(a)
      },
      defaultData: {
        commands: [],
        params: []
      },
    }
  },

  async mounted() {
    await this.initInstance('allActions',[
        [!this.allPermissions.length, this.getPermissions],
        [!this.actionCommandTypes.length, this.getCommandTypes],
        [!this.actionConditionTypes.length, this.getConditionTypes],
        [!this.allActions.length, this.getActions],
        [!this.allServices.length, this.getServices],
      ], this.processAction)
  },

  computed: {
    ...mapGetters(['allActions','allServices','actionConditionTypes','actionCommandTypes','allPermissions']),
    sortedCommands() {
      const commands = this.instance && this.instance.commands ? [...this.instance.commands] : []
      return commands
          .map(cmd => (
              {...cmd, target: (typeof target === 'string' && cmd.target.length && !isNaN(cmd.target*1)) ? cmd.target*1 : cmd.target }
          ))
          .sort((a, b) => a.sort < b.sort?-1:1)
    },
    sortedParams() {
      return this.instance && this.instance.params ? this.instance.params : []
    },
    allEndpoints() {
      let a = [];
      for (let service of this.allServices) {
        a = [...a, ...service.endpoints.map(it => ({path: it.path, label: service.name +': '+it.name}))]
      }
      return a
    }
  },

  methods: {
    ...mapActions(['getActions', 'updateAction', 'createAction','getServices','getCommandTypes','getConditionTypes','setLoading','getPermissions']),
    title(action) {
      return action.name
    },

    processAction(action) {
      if (typeof action.call_permission === 'string') action.call_permission = action.call_permission.split(/,/g)
      return action
    },

    async onTestAction(settings = {}, params = {}) {
      this.testResults = this.testLog = null;
      await this.setLoading(true)
      const body = {...settings, ...params}

      const res = await ApiRequest(ApiCall(Api.ActionTest, body, this.instance.id))
      if (res.state) this.testResults = res.state;
      if (res.log) this.testLog = res.log;
      await this.setLoading(false)
    },

    commandTarget(r, command) {
      if (command.type.indexOf('endpoint') >= 0) {
        const endpoint = this.allEndpoints.find(e => e.path === r)
        if (endpoint) return endpoint.label
        return '-'
      }
      if (command.target_input) return command.target +' (input)'
      return command.target
    },

    conditionMood(r, command) {
      return command.condition_or ? 'success': (command.condition_type && command.condition_type.length > 1? 'warning':'success')
    },

    commandCondition(r, command) {
      if (!command.condition_type) return 0
      const c = command.condition_type.split(/,/g)
      return c.length
    },

    commandType(r, command) {
      const cond = this.actionCommandTypes.find(c => c.id === command.type)
      if (cond) return cond.label
      return command.type
    },

    onDeleteCommand(command) {
      this.instance.commands = this.instance.commands.filter(it => (it.id && it.id !== command.id))
    },

    addCommand(name, type, target,target_input, input, store, value, search, condition_type, condition_input, condition_or) {
      if (typeof target === 'string' && target.length && !isNaN(target*1)) target = target*1

      let commands = this.instance.commands || [];
      const _key = commands.length+1
      commands.push({_key, sort: _key, name, type, target,target_input, input, store, value, search, condition_type, condition_input, condition_or})
      this.instance.commands = commands
    },

    editCommand(id, name, type, target, target_input, input, store, value, search, condition_type, condition_input, condition_or) {
      if (typeof target === 'string' && target.length && !isNaN(target*1)) target = target*1

      this.instance.commands = this.instance.commands.map(it => it.id === id || (!it.id && it._key === id) ? {...it, name, type, target,target_input, input, store, value, search, condition_type, condition_input, condition_or } : it)
      this.editedCommand = null
    },

    onSortCommand(oldIndex, newIndex) {
      const commands = [...this.sortedCommands], command = commands.splice(oldIndex, 1);
      commands.splice(newIndex, 0, command[0]);

      for (let i = 0; i < commands.length; i++) {
        commands[i].sort = i+1;
      }
      this.instance.commands = commands;
    },
    onEditCommand(command) {
      this.editedCommand = command;
    },
    cancelEditCommand() {
      this.editedCommand = null
    },

    addParam(name, is_mandatory) {
      let params = this.instance.params || [];
      const _key = params.length+1
      params.push({_key, name, is_mandatory})
      this.instance.params = params
    },

    editParam(id, name, is_mandatory) {
      this.instance.params = this.instance.params.map(it => it.id === id || (!it.id && it._key === id) ? {...it, name, is_mandatory } : it)
      this.editedParam = null
    },

    onDeleteParam(param) {
      this.instance.params = this.instance.params.filter(it => (it.id && it.id !== param.id))
    },
    onSortParam(oldIndex, newIndex) {
      const params = [...this.sortedParams], param = params.splice(oldIndex, 1);
      params.splice(newIndex, 0, param[0]);

      for (let i = 0; i < params.length; i++) {
        params[i].sort = i+1;
      }
      this.instance.params = params;
    },
    onEditParam(param) {
      this.editedParam = param;
    },
    cancelEditParam() {
      this.editedParam = null
    },
  }
}
</script>
