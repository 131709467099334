import {breakpointClassNames, objNotEmpty} from "@/lib/ui/UiUtils";

export default {
    data() {
        return {
            clsName: 'flexy',
            inheritsFlexyViaSass: false,
            classIfs: []
        }
    },
    props: {
        even: {type: Boolean, default: false},
        between: {type: Boolean, default: false},
        centered: {type: Boolean, default: false},
        wraps: {type: Boolean, default: false},
        col: {type: [Boolean, Object], default: false},
        colNeg: {type: [Boolean, Object], default: false},
        flex: {type: [Number, Object, String], default: null},
        full: {type: Number, default: null},
        half: {type: Number, default: null},
        third: {type: Number, default: null},
        quarter: {type: Number, default: null},
        autoFlex: {type: Boolean, default: false},
        align: {type: String, default: ''},
        justify: {type: String, default: ''},
        autoWidth: {type: Boolean, default: false},
        basePadding: {type: Boolean, default: false},
        linePadding: {type: Boolean, default: false},
    },
    computed: {
        className() {
            let cls = this.clsName === 'flexy' ? 'flexy' : this.clsName + (this.inheritsFlexyViaSass?'':' flexy')
            if (this.even) cls += ' even'
            if (this.between) cls += ' between'
            if (this.centered) cls += ' centered'
            if (this.wraps) cls += ' wraps'

            if (this.justify === 'start') cls += ' justify_start'
            else if (this.justify === 'end') cls += ' justify_end'
            if (this.align === 'start') cls += ' align_start'
            else if (this.align === 'end') cls += ' align_end'

            if (this.flex !== null && !isNaN(this.flex*1)) cls += ' flex_'+this.flex
            else if (objNotEmpty(this.flex)) cls += ' '+breakpointClassNames(this.flex,'flex_c_')
            else if (this.flex === 'auto') cls += ' auto_flex'

            if (typeof this.quarter !== "undefined" && this.quarter !== null) cls += ' quarter_'+this.quarter
            if (typeof this.third !== "undefined" && this.third !== null) cls += ' third_'+this.third
            if (typeof this.half !== "undefined" && this.half !== null) cls += ' half_'+this.half
            if (typeof this.full !== "undefined" && this.full !== null) cls += ' full_'+this.full
            if (this.autoFlex) cls += ' auto_flex'
            if (this.linePadding) cls += ' line_padding'
            if (this.basePadding) cls += ' base_padding'

            if (this.classIfs) {
                for (let pair of this.classIfs) {
                    if (pair[0]) cls += ' '+pair[1]
                }
            }
            if (objNotEmpty(this.col)) {
                cls += ' '+breakpointClassNames(this.col, 'col_c_', true)
            }
            else if (objNotEmpty(this.colNeg)) {
                cls += ' '+breakpointClassNames(this.colNeg, 'col_cn_', true)
            }

            if (objNotEmpty(this.autoWidth)) {
                cls += ' '+breakpointClassNames(this.autoWidth, 'aw_', true)
            }

            else if (this.col) {
                cls += ' col';
            }

            return cls.trim()
        },
    }
}
