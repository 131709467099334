const tempStorage = {}

export const TOKEN = 'AdminApiToken';

export function getToken() {
    return localStorage.getItem(TOKEN)
}
export function setToken(tokenValue) {
    localStorage.setItem(TOKEN, tokenValue);
}

export function TempGet(prop) {
    return tempStorage[prop]
}

export function TempSet(prop, value) {
    tempStorage[prop] = value
}
