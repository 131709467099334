import burger from '@/assets/icons/burger.svg'
import caret from '@/assets/icons/caret.svg'
import check from '@/assets/icons/check.svg'
import drag from '@/assets/icons/drag.svg'
import crown from '@/assets/icons/crown.svg'
import groceries from '@/assets/icons/groceries.svg'
import lock from '@/assets/icons/lock.svg'
import minus from '@/assets/icons/minus.svg'
import pencil from '@/assets/icons/pencil.svg'
import play from '@/assets/icons/play.svg'
import plus from '@/assets/icons/plus.svg'
import pushPin from '@/assets/icons/push-pin.svg'
import settings from '@/assets/icons/settings.svg'
import x from '@/assets/icons/x.svg'

export default {
    burger : {icon: burger, colorProp: 'stroke'},
    caret : {icon: caret, colorProp: 'stroke'},
    check : {icon: check, colorProp: 'fill'},
    crown : {icon: crown, colorProp: 'fill'},
    drag : {icon: drag, colorProp: 'fill'},
    groceries : {icon: groceries, colorProp: 'fill'},
    lock : {icon: lock, colorProp: 'fill'},
    minus : {icon: minus, colorProp: 'fill'},
    pencil : {icon: pencil, colorProp: 'fill'},
    play : {icon: play, colorProp: 'fill'},
    plus : {icon: plus, colorProp: 'fill'},
    pushPin : {icon: pushPin, colorProp: 'fill'},
    settings : {icon: settings, colorProp: 'fill'},
    x : {icon: x, colorProp: 'fill'},
}
