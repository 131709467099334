<template>
  <div :class="className" :style="style" ref="scroller">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Scroller",
  props: {
    full: Boolean,
    height: Number,

    listener: null
  },
  mounted() {
    setTimeout(() => this.checkFull(), 1);
    window.addEventListener('resize', this.checkFull);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkFull);
  },
  computed : {
    className() {
      let clsName = 'scroller';
      if (this.full) clsName += ' full'
      return clsName;
    },
    style() {
      let style = {};
      if (this.height) { style.maxHeight = this.height+'px' }
      return style;
    }
  },
  methods : {
    checkFull() {
      if (!this.full) return;
      const scroller = this.$refs.scroller;
      if (!scroller) return;
      let h = (window.innerHeight - scroller.getBoundingClientRect().top)
      scroller.style.height = h+'px'
    }
  }
}
</script>
