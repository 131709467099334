import Fe from "@/lib/ui/FormElement";

function parseDate(d) {
    d = new Date(d.replace(' ', 'T'));
    let date = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear(),
        time = d.getHours()+':'+(d.getMinutes().toString().length < 2 ? '0'+d.getMinutes():d.getMinutes())
    return date + ' '+time
}

function parseRole(d,tr, meta) {
    console.log({d,tr, meta})
    return tr.role_id
}

function parseIsAdmin(d, tr) {
    console.log({d,tr})
    return false
}

export default {

    allowDelete: true,
    name: "User",
    path: "users",
    plural: "Users",
    menu: "User",
    permissions: ['ADMIN'],
    menuType: "admin",
    titleProp: "title",

    metaModules : ['brands','user-roles'],

    endpoints : {
        list : { path : '/admin/user/list', method: "get", token: true },
        get : { path : '/admin/user/get/:id', method: "get", token: true },
        add : { path : '/admin/user/add', method: "post", body: true, token: true },
        update : { path : '/admin/user/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/user/delete/:id', method: "get", params: true, token: true },
    },

    fields: (instance, meta) => [
        {label: "ID", prop: "id", above: 1, sortable: true},
        {label: "Username", prop: "username", sortable: true},
        {label: "Role", prop: "role", format: (d, tr) => parseRole(d,tr,meta), above: 1, sortable: true},
        {label: "Admin?", prop: "role", target:"admin", type: "boolean", format: parseIsAdmin, above: 1, sortable: true},
        {label: "Last Login", prop: "last_login", format: parseDate, sortable: true},
    ],

    form : (instance) => [
        Fe.Text("username", "Username", "primary"),
        Fe.Select("brand_id", "Brand", "primary", "Select Brand",'name','brands','id', false),
        {...Fe.Select("role_id", "Role", "primary", "Select Role", 'name', 'user-roles', 'id', false), show: () => !instance.role || !instance.role.is_super_admin },
        {...Fe.Label("role", "Role", "primary"), format: (r) => r && r.name ? r.name : 'N/A', show: () => instance.role && instance.role.is_super_admin },
        Fe.Date("last_login", "Last Login", "primary 2"),
        Fe.Password("password", "Reset Password", "primary 2"),
        Fe.Text("token", "Token", "primary 2"),
        Fe.Text("production_api_key", "Production API Key", "primary 3"),
        Fe.Text("development_api_key", "Development API Key", "primary 3"),
    ]

}
