import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        Brands : [],
    },
    actions : {
        async switch_brands({dispatch}, id) {
            const res = await ApiRequest(ApiCall(Api.SwitchBrands, id));
            if (res.error) return dispatch('setError', res.error)
            dispatch('setSuccess', 'Successfully Switched Brands')
        },
    },
    mutations : {

    }
}
