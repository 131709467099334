<template>
  <div class="modal" @click="() => cancel && cancel()">
    <div class="modal-inner" @click="(e) => e.stopPropagation()">
      <h3 v-if="title">{{title}}</h3>
      <div class="modal-content">
        <slot></slot>
      </div>
      <ButtonGroup>
        <Button v-for="(action, name) in actions" :key="name" @click="action" :mood="actionMood(name)">{{t(name)}}</Button>
        <Button v-if="cancel" @click="cancel">{{t('Cancel')}}</Button>
      </ButtonGroup>
    </div>
  </div>
</template>

<script>
import ButtonGroup from "@/components/ui/layout/ButtonGroup";
import Button from "@/components/ui/form/Button";
import t from '@/lib/Locale'

export default {
  name: "Modal",
  components: {Button, ButtonGroup},
  props: {
    title: String,
    cancel: Function,
    actions: Object,
    actionMoods : Object
  },
  methods : {
    t,
    actionMood(name) {
      if (this.actionMoods && this.actionMoods[name]) return this.actionMoods[name]
    }
  }
}
</script>
