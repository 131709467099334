<template>
  <Container :full="true">
    <ConfirmModal v-if="integrationForDeletion" item-type="Integration" :item-name="integrationForDeletion.name" :confirm="confirmDeleteIntegration" :cancel="cancelDeleteIntegration" />
    <FilteredItemList title="Integrations" :fields="fields" :items="allIntegrations" :on-click="goto" :search="true" @delete="askToDeleteIntegration" :actions="{'Add Integration': addNewIntegration }" />
  </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Container from "@/components/ui/layout/Container";
import Icons from "@/lib/Icons";

export default {
  name: "Integrations",
  components: {Container, FilteredItemList, ConfirmModal },
  data() {
    return {
      integrationForDeletion: null,
      search : "",
      fields : [
        {label: "Name", prop: "name", above: 2},
        {label: "Type", prop: "integration_type"},
        {label: "Comments", prop: "comments", above: 4},
        {label: "Active", prop: "is_active", always: true, type: "boolean"},

        {label: "", prop: "", width: "10%", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allIntegrations.length, this.getIntegrations]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/login'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allIntegrations']),
  },

  methods: {


    ...mapActions(['getIntegrations','setLoading','deleteIntegration']),
    goto(integration) {
      this.$router.push('/integrations/'+integration.id)
    },
    addNewIntegration() {
      this.$router.push('/integrations/new')
    },
    askToDeleteIntegration(integration) {
      this.integrationForDeletion = integration;
    },
    cancelDeleteIntegration() { this.integrationForDeletion = null },
    confirmDeleteIntegration() {
      this.deleteIntegration(this.integrationForDeletion.id)
      this.integrationForDeletion = null
    }
  },


}
</script>
