import Fe from "@/lib/ui/FormElement";
import t from "@/lib/Locale";

export default {

    allowDelete: true,
    name: "Email Template",
    path: "email-templates",
    plural: "E-mail Templates",
    menu: "E-mail Templates",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],

    meta : {
        templateNames : [
            {id:'reset-password',name:t('Reset Password')},
            {id:'activate-account',name:t('Activate Account')},
            {id:'signup-welcome',name:t('Signup Welcome')},
            {id:'progress-reminder',name:t('Progress Reminder')},
        ],
    },

    endpoints : {
        list : { path : '/admin/email-template/list', method: "get", token: true },
        get : { path : '/admin/email-template/get/:id', method: "get", token: true },
        add : { path : '/admin/email-template/add', method: "post", body: true, token: true },
        update : { path : '/admin/email-template/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/email-template/delete/:id', method: "get", params: true, token: true },
    },

    fields: [
        {label: "ID", prop: "id", above: 1},
        {label: "Name", prop: "name"},
        {label: "Subject", prop: "subject", above: 2},
    ],

    form : [
        Fe.Select("name", "Name", "main","Select Template Name",'name', 'templateNames'),
        Fe.Text("subject", "Subject", "main"),
        Fe.Textarea("content", "Content", "main"),
    ]

}
