import Fe from "@/lib/ui/FormElement";

export default {

    allowDelete: true,
    name: "Plan",
    path: "plans",
    plural: "Plans",
    menu: "Plans",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],

    endpoints : {
        list : { path : '/admin/plan/list', method: "get", token: true },
        get : { path : '/admin/plan/get/:id', method: "get", token: true },
        add : { path : '/admin/plan/add', method: "post", body: true, token: true },
        update : { path : '/admin/plan/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/plan/delete/:id', method: "get", params: true, token: true },
    },

    fields: [
        {label: "Name", prop: "name"},
        {label: "Active", prop: "is_active", type: "boolean" },
        {label: "Price", prop: "price", format: (d) => isNaN(d*1) ? '-' : ''+parseFloat(d).toFixed(2)},
        {label: "Duration", prop: "duration_days", format: (d) => d? d+ ' days':''},
        {label: "Description", prop: "description", above: 3},
    ],

    form : [
        Fe.Text("name", "Name", "main"),
        Fe.Number("duration_days", "Duration (days)", "primary"),
        Fe.Number("price", "Price (real)", "primary"),
        Fe.Number("full_price", "Full Price (not real)", "primary"),
        Fe.Text("target_url", "Target URL", "primary"),
        Fe.Checkbox("is_active", "Active?", "primary"),
        Fe.Checkbox("is_archived", "Archived?", "primary"),
        Fe.Textarea("description", "Description", "primary 2"),
        Fe.Number("free_trial_days", "Free Trial Days", "primary 3"),
        Fe.Checkbox('email_charge_receipt', 'Send Charge Receipt Email?', 'primary 3'),
    ],

}
