import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        actions : [],
        commandTypes : [],
        conditionTypes : [],
        permittedActions : [],
        brandSettings : {},
    },
    getters : {
        allActions : (state) => state.actions,
        actionCommandTypes : (state) => state.commandTypes,
        actionConditionTypes : (state) => state.conditionTypes,
        allPermittedActions : (state) => state.permittedActions,
        allBrandSettings : (state) => state.brandSettings,
    },
    actions : {
        async getActions({commit}) {
            const res = await ApiRequest(ApiCall(Api.ActionList));
            return await commit('setActions', res);
        },
        async deleteAction({commit}, id) {
            await ApiRequest(ApiCall(Api.ActionDelete, id))
            return await commit('deleteAction', id)
        },
        async updateAction({commit}, [action, id]) {
            await ApiRequest(ApiCall(Api.ActionUpdate, action, id))
            return await commit('saveAction', [action, id])
        },
        async createAction({commit}, action) {
            const res = await ApiRequest(ApiCall(Api.ActionAdd, action))
            action.id = res.insertId
            action.created = res.created
            return await commit('saveAction', [action, null])
        },

        async getCommandTypes({commit}) {
            const res = await ApiRequest(ApiCall(Api.ActionCommandTypes));
            const k = Object.keys(res.types).map(it => ({id: it, label : res.types[it].label, hide: res.types[it].hide, targetInput: res.types[it].targetInput, scope: res.types[it].scope || null}))
            return await commit('setCommandTypes', k);
        },

        async getConditionTypes({commit}) {
            const res = await ApiRequest(ApiCall(Api.ActionConditionTypes));
            const k = Object.keys(res.types).map(it => ({id: it, label : res.types[it].label}))
            return await commit('setConditionTypes', k);
        },

        async getPermittedActions({commit}) {
            const res = await ApiRequest(ApiCall(Api.PermittedActions));
            commit('setPermittedActions',res.actions)
        },

        async getBrandSettings({commit}) {
            const res = await ApiRequest(ApiCall(Api.CustomSettings));
            commit('setBrandSettings',res.settings)
        }
    },
    mutations : {
        setActions: (state, actions) => { state.actions = actions},
        setCommandTypes: (state, commandTypes) => { state.commandTypes = commandTypes},
        setPermittedActions: (state, permittedActions) => {state.permittedActions = permittedActions},
        setBrandSettings: (state, brandSettings) => {state.brandSettings = brandSettings},
        setConditionTypes: (state, conditionTypes) => { state.conditionTypes = conditionTypes},
        saveAction: (state, [action, id]) => {
            if (id) { state.actions = state.actions.map(it => it.id === action.id ? action : it) }
            else { state.actions = [...state.actions, action]; }
            return state
        },
        deleteAction(state, id) { state.actions = state.actions.filter(it => it.id !== id) },

    }
}
