import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        services : [],
    },
    getters : {
        allServices : (state) => state.services,
    },
    actions : {
        async getServices({commit}) {
            const res = await ApiRequest(ApiCall(Api.ServiceList));
            return await commit('setServices', res);
        },
        async deleteService({commit}, id) {
            await ApiRequest(ApiCall(Api.ServiceDelete, id))
            return await commit('deleteService', id)
        },
        async updateService({commit}, [service, id]) {
            await ApiRequest(ApiCall(Api.ServiceUpdate, service, id))
            return await commit('saveService', [service, id])
        },
        async createService({commit}, service) {
            const res = await ApiRequest(ApiCall(Api.ServiceAdd, service))
            service.id = res.insertId
            return await commit('saveService', [service, null])
        },

    },
    mutations : {
        setServices: (state, services) => { state.services = services},
        saveService: (state, [service, id]) => {
            if (id) { state.services = state.services.map(it => it.id === service.id ? service : it) }
            else { state.services = [...state.services, service]; }
            return state
        },
        deleteService(state, id) { state.services = state.services.filter(it => it.id !== id) },

    }
}
