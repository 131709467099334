<template>
  <Content :class="clsName" v-if="field && isShown">
    <Txt class="label" v-if="labelBefore" :text="field.label" />
    <Flexy>
      <img v-if="previewImg" :src="previewImg" class="preview-image" />
      <component :is="tagName" v-bind="tagProps" v-on="tagEvents">{{tagContent}}</component>
      <component v-if="hasSecondary" :is="secondaryTagName" v-bind="secondaryTagProps" v-on="secondaryTagEvents" />
    </Flexy>
    <Txt class="label" v-if="labelAfter" :text="field.label" />
  </Content>
</template>

<script>
import Content from "@/components/ui/layout/Content";
import Input from "@/components/ui/form/Input";
import Textarea from "@/components/ui/form/Textarea";
import vSelect from "vue-select";
import Txt from "@/components/ui/utils/Txt";
import Flexy from "@/components/ui/layout/Flexy";
import t from '@/lib/Locale';
import {mapGetters} from "vuex";

export default {
  name: "FormField",
  components: {Flexy, Txt, Content},
  props: {
    noLabels: Boolean,
    field : {type: Object},
    value : {type: [String, Number, Object, Array, Boolean] },
    meta : {type: Object },
    show : {type: [Boolean, Function], default: true},
    clearable : {type: Boolean, default: true},
    filterOptions: { type: Function, default: null},
    setImage : {type: Function, default: null},
    previewImg : {type: String, default: ''},
  },

  computed : {
    ...mapGetters(['appAbilities']),
    hasSecondary() {
      switch(this.field.type) {
        case 'image':
          if (this.appAbilities.indexOf('upload') > -1) return true;
      }
      return false;
    },

    isShown() {
      return typeof this.show === 'function' ? this.show() : this.show
    },

    clsName() {
      let cls = 'form-field'
      switch (this.field.type) {
        case 'checkbox': cls += ' horizontal centered'
      }

      return cls;
    },

    tagName() {
      switch (this.field.type) {
        case 'label': return Txt;
        case 'select': return vSelect;
        case 'textarea': return Textarea;
        default: return Input
      }
    },
    tagContent() {
      const value = this.field.format ? this.formatTag(this.value, this.field.format) : this.value
      switch (this.field.type) {
        case 'label': return value;
      }
      return null
    },
    tagEvents() {
      const events = {}
      switch (this.field.type) {
        case 'checkbox':

          events.input = (value,el) => {
            this.doChange(!!el.checked)
          }
          break;
        default:
          events.input = (value) => this.doChange(value);
          break;
      }
      return events;
    },

    tagProps() {
      const props = {}

      if (this.field.disabled) props.disabled = true

      switch (this.field.type) {
        case 'label':
          props.bold = true
          break
        case 'number':
          props.type = 'number'
          props.placeholder = t(this.field.placeholder)
          props.value = this.value
          break
        case 'image':
          props.type = 'text';
          props.placeholder = t(this.field.placeholder)
          props.value = this.value
          break;
        case 'date':
          props.type = 'datetime-local';
          props.value = this.value && this.value.length > 8 ? this.value.substr(0,19) : '';
          break;
          case 'date-only':
          props.type = 'date';
          props.value = this.value && this.value.length > 8 ? this.value.substr(0,10) : '';
          break;
        case 'password':
          props.type = 'password'
          props.placeholder = t(this.field.placeholder)
          props.value = this.value
          break
        case 'text':
          props.type = 'text'
          props.placeholder = t(this.field.placeholder)
          props.value = this.value
          break
        case 'textarea':
          props.placeholder = t(this.field.placeholder)
          props.value = this.value
          break
        case 'checkbox':
          props.type = 'checkbox'
          props.checked = !!this.value
          break
        case 'select':
          props.placeholder = t(this.field.placeholder)
          props.label = this.field.labelKey
          props.reduce = (it) => this.field.propKey ? it[this.field.propKey] : it.id
          props.value = this.field.propKey === 'id' && !isNaN(this.value*1) ?this.value*1 : this.value
          props.clearable = this.clearable
          props.multiple = this.field.multiple
          props.options = this.meta ? this.meta[this.field.metaGroup] : []
          if (typeof this.filterOptions === "function") {
            props.options = this.filterOptions(props.options)
            if (typeof props.options === "function") props.options = props.options()
          }
          if (props.options)
            props.options = props.options.map(opt => {
            for (let k in opt) {
              if (['id','value'].indexOf(k) > -1) continue;
              opt[k] = t(opt[k])
            }
            return opt
          })

          break
      }

      return props;
    },

    secondaryTagName() {
      switch (this.field.type) {
        case 'image': return 'input'
      }
      return null
    },

    secondaryTagProps() {
      switch (this.field.type) {
        case 'image': return { type: "file" }
      }
      return null
    },

    secondaryTagEvents() {
      switch (this.field.type) {
        case 'image': return {
          change : (e) => {
            if (this.setImage) this.setImage(e.target.files[0])
            this.doChange(this.value)
          }
        }
      }
      return null
    },

    labelBefore() {
      switch (this.field.type) {
        case 'boolean':
        case 'checkbox':
          return false
        default: return !this.noLabels
      }
    },
    labelAfter() {
      switch (this.field.type) {
        case 'checkbox':
          return true
        default: return false
      }
    }
  },

  methods : {
    t,
    doChange(val) {
      this.val = val;
      this.$emit('change', val);
    },
    formatTag(val, format) {
      if (typeof format === 'function') return format(val)
      else {
        switch (format) {
          case 'datetime': return val? new Date(val).toDateString():'-'
        }
      }
      return val
    }
  },

}
</script>
