<template>
  <Container>
    <Panel>
      <Header>{{reportTitle}}</Header>
      <Content :full="2">
        <Content :col="true">
          <Flexy>
            <Txt text="Active Subscribers:"></Txt>
            <Txt :bold="true">{{ activeSubs.length }}</Txt>
          </Flexy>
          <BarChart :data="dailySubs" />
        </Content>
        <Content :col="true">
          <Flexy>
            <Txt text="Canceled Subscribers:"></Txt>
            <Txt :bold="true">{{ canceledSubs.length }}</Txt>
          </Flexy>
          <BarChart :data="dailyCancellations" color="#dd0000" />
        </Content>
      </Content>
    </Panel>
    <Panel>
      <Header text="Subscription Summary" />
      <Flexy>
        <Txt text="Active Subscriptions:" />
        <Txt :bold="true">{{verifiedSubscriptions.length}}</Txt>
      </Flexy>
      <Flexy>
        <Txt text="Estimated Subscriptions Summary:" />
        <Txt :bold="true">{{verifiedSum}}</Txt>
      </Flexy>
    </Panel>
    <Panel>
      <Header text="Forecast" />
      <Content :col="true">
        <Flexy>
          <Txt text="Next Month:" />
          <Txt :bold="true">{{predictionSum(1)}}</Txt>
        </Flexy>
        <Flexy v-for="index in [2,3,4,5,6,7,8,9,10,11,12]" :key="index">
          <Txt>{{t('In {0} Months:', index)}}</Txt>
          <Txt :bold="true">{{predictionSum(index)}}</Txt>
        </Flexy>
      </Content>
    </Panel>
  </Container>
</template>

<script>
import Container from "@/components/ui/layout/Container";
import Panel from "@/components/ui/layout/Panel";
import Header from "@/components/ui/utils/Header";
import Content from "@/components/ui/layout/Content";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import {mapActions, mapGetters} from "vuex";
import Txt from "@/components/ui/utils/Txt";
import BarChart from "@/components/ui/stats/BarChart";
import Flexy from "@/components/ui/layout/Flexy";
import {addMonths} from "@/lib/TimeUtils";
import t from "@/lib/Locale";

export default {
  name: "Report",
  components: { Flexy, BarChart, Txt, Content, Header, Panel, Container },
  data() {
    return {

    }
  },
  async mounted() {
    this.loaded = false
    await delayedIfLoading(!this.isLoggedIn, actionIf([
          [!this.externalItems('subscribers').length, this.externalGetItems('subscribers')],
          [!this.externalItems('transactions').length, this.externalGetItems('transactions')],
          [!this.externalItems('debit-charges').length, this.externalGetItems('debit-charges')],
          [!this.externalItems('plans').length, this.externalGetItems('plans')],
        ]), this.setLoading,
        () => { return this.isLoggedIn }, () => this.$router.push('/login'), 1000, 5)

    this.loaded = true
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    reportTitle() { return "Subscribers Report" },
    subs() { return this.externalItems('subscribers') },
    activeSubs() {return this.subs.filter(it => it.subscription_active && it.subscription_renew)},
    canceledSubs() { return this.subs.filter(it => it.subscription_active && !it.subscription_renew) },
    transactions() { return this.externalItems('transactions') },

    activeTransactions() {
      return this.transactions.filter(it => it.status === 'success' && !it.debit_cancellation_date && it.type === 'debit')
    },

    verifiedSubscriptions() {
      let sub
      return this.activeTransactions.filter( it => {
        sub = this.activeSubs.find(s => s.id === it.subscriber_id)
        if (!sub) return false
        it.sub = sub
        return true
      })
    },

    verifiedSum() {
      return parseFloat(this.verifiedSubscriptions.reduce((pv, it) => {return pv + it.debit_amount*1}, 0)).toFixed(2)
    },

    dailySubs() {
      let s = {}, d, start, res = {}
      for (let sub of this.activeSubs) {
        d = new Date(sub.subscription_date)
        if (d.toJSON()) {
          d = d.toJSON().substr(0,10)
          s[d] = !s[d] ? 1 : s[d]+1
        }
      }

      start = Object.keys(s).sort()
      start = start[0]

      d = new Date(start)
      while (d <= new Date()) {
        res[d.toJSON().substr(0,10)] = s[d.toJSON().substr(0,10)] ? s[d.toJSON().substr(0,10)] : 0
        d.setDate(d.getDate()+1)
      }

      return res
    },
    dailyCancellations() {
      let s = {}, d, start, res = {}
      for (let sub of this.subs) {
        if (!sub.cancellation_date) {
          if (sub.subscription_renew || !sub.subscription_date) continue
          d = new Date(sub.subscription_date)
          d = addMonths(d, sub.subscription_charge_interval*1)
        }
        else {
          d = new Date(sub.cancellation_date)
          if (!sub.subscription_active) continue;
        }
        d = d.toJSON().substr(0,10)
        s[d] = !s[d] ? 1 : s[d]+1
      }

      start = Object.keys(s).sort()
      start = start[0]
      d = new Date(start)

      while (d <= new Date()) {
        res[d.toJSON().substr(0,10)] = s[d.toJSON().substr(0,10)] ? s[d.toJSON().substr(0,10)] : 0
        d.setDate(d.getDate()+1)
      }

      return res
    },

  },
  methods: {
    t,
    ...mapActions(['setLoading']),
    externalItems(modulePath) {
      return this.$store.getters['all_'+modulePath] || []
    },
    externalGetItems(modulePath) {
      return async () => await this.$store.dispatch('get_'+modulePath+'_list')
    },

    predictionSum(month) {
      let sum = 0, sub, int, st, dt = addMonths(new Date(), month)
      for (let transaction of this.verifiedSubscriptions) {
        sub = transaction.sub;
        int = sub.subscription_charge_interval*1
        st = new Date(sub.subscription_date);
        while (st < dt && st.getMonth() !== dt.getMonth()) {
          st = addMonths(st, int)
        }
        if (st.getMonth() === dt.getMonth()) sum += transaction.debit_amount *1
      }
      return parseFloat(sum).toFixed(2)
    }
  }
}
</script>
