<template>
  <span :class="className">
    <a v-if="link" :href="link" :class="mood"><Txt v-if="text">{{t(text)}}</Txt><slot></slot></a>
    <router-link v-if="routerLink" :to="routerLink"><Txt v-if="text">{{t(text)}}</Txt><slot></slot></router-link>
    <Txt v-if="!link && !routerLink && text">{{t(text)}}</Txt><slot v-if="!link && !routerLink" @click="onClick"></slot>
  </span>
</template>
<script>

import t from '@/lib/Locale';

export default {
  name: "Txt",
  props: ['mood', 'bold','small', 'title', 'link', 'routerLink','action','text','interactive'],
  computed: {
    className() {
      let cls = 'txt';
      if (this.bold) cls += ' bold'
      if (this.small) cls += ' small'
      if (this.interactive) cls += ' interactive'
      if (this.title) cls += ' title'
      switch (this.mood) {
        case 'primary': cls += ' primary'; break;
        case 'success': cls += ' success'; break;
        case 'warning': cls += ' warning'; break;
        case 'danger': cls += ' danger'; break;
        case 'saving': cls += ' saving'; break;
      }
      return cls
    }
  },
  methods : {
    t,
    onClick() {
      if (this.action) this.action()
    }
  }
}
</script>
