<template>
    <form @submit="onSubmit">
      <Flexy :col="true">
        <Flexy :even="true" :wraps="true">
          <Input v-for="(param, p) in params" :date="param.name.indexOf('date')>-1" :key="p" size="med" :mood="param.is_mandatory?'warning':''" v-model="values[param.name]" :placeholder="param.placeholder" />
        </Flexy>
        <Flexy :even="true">
          <Button @click="doSubmit" tabindex="3" mood="primary" :disabled="!isValid">{{runLabel}}</Button>
        </Flexy>
      </Flexy>
    </form>
</template>

<script>
import Flexy from "@/components/ui/layout/Flexy";
import Input from "@/components/ui/form/Input";
import Button from "@/components/ui/form/Button";
import t from '@/lib/Locale';

export default {
  name: "ParamForm",
  components: { Button, Input, Flexy},
  props : {
    params : { type: Array, default: () => [] },
    runLabel : { type: String, default: 'Run Action' },
    onSubmit : { type: [Function] },
  },
  data() {
    return {
      values : {},
    }
  },

  computed : {
    isValid() {
      return this.params.filter(p => p.is_mandatory && !p.value).length === 0
    }
  },

  methods: {
    t,
    doSubmit() {
      this.onSubmit(this.values)
    }

  },
  watch : {

  }
}
</script>
