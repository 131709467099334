<template>
  <svg class="bar-chart" :height="height" width="100%" ref="el">
    <g v-for="(v, i) in columns" :key="i" :fill="color">
      <rect :x="colPos(i)" :height="colHeight(v[1])*0.75" :y="height-colHeight(v[1])*0.75 - 12" :width="colWidth" />
      <rect :x="colPos(i)" :height="1" width="100%" :y="height - 12" />
      <text :x="colPos(i) + (colWidth/2)-4" :y="height-colHeight(v[1])*0.75 - 18" :fill="color" font-size="9">{{v[1]}}</text>
      <text :x="colPos(i)" :y="(i%2)?height:height-4" :fill="color" font-size="8">{{colDate(v[0])}}</text>
    </g>
    <rect x="0" width="25%" height="100%" fill="#00000001" @click="addOffset(7)" />
    <rect x="75%" width="25%" height="100%" fill="#00000001" @click="addOffset(-7)" />
  </svg>
</template>

<script>
export default {
  name: "BarChart",
  props : {
    data : { type: [Object, Array]},
    color: {type: String, default: '#3366ff'},
    colWidth: {type:Number, default: 22},
    height: {type: Number, default: 160}
  },

  data() {
    return {
      elWidth : 100,
      offset : 0,
    }
  },

  mounted() {
    this.elWidth = this.$refs.el.getBoundingClientRect().width
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed : {
    columns() {
      let d = []
      for (let i in this.data)
        d.push([i, this.data[i]])
      return d
    },
    min() {
      let m = Infinity
      for (let i in this.data) if (this.data[i] < m) m = this.data[i]
      return m
    },
    max() {
      let m = -Infinity
      for (let i in this.data) if (this.data[i] > m) m = this.data[i]
      return m
    }
  },
  methods: {
    colHeight(val) {
      return val/this.max * this.height
    },
    colDate(v) {
      let d = new Date(v)
      return d.getDate()+'/'+(d.getMonth()+1)
    },
    colPos(i) {
      let r = -1 * Object.keys(this.data).length * this.colWidth
      r += i * this.colWidth
      r += this.elWidth

      r += this.offset * this.colWidth

      return r
    },
    onResize() {
      this.elWidth = this.$refs.el.getBoundingClientRect().width
    },
    addOffset(amount) {
      this.offset += amount
    }
  },

}
</script>
