<template>
  <Container v-if="m" :full="true" :index="renderId">
    <ConfirmModal v-if="itemForDeletion" :item-type="name" :item-name="itemForDeletion[module.titleProp?module.titleProp:'name']" :confirm="confirmDeleteItem" :cancel="cancelDeleteItem" />
    <ConfirmModal v-if="itemForSwitching" :item-type="name" action-label="switch to" :item-name="itemForSwitching[module.titleProp?module.titleProp:'name']" :confirm="confirmSwitchItem" :cancel="cancelSwitchItem" />
    <FilteredItemList :title="plural" :fields="moduleFields" :items="allItems" :on-click="goto" :search="true" :max-items="20" :save-as="path" :filters="filters" :filter-format="filterFormat" :filter-data="filterData" :features="features" :default-sort-by="defaultSortBy"
                      @delete="askToDeleteItem" @switch="askToSwitchTo" :actions="{['Add '+name]: addNewItem }" :external-search="isSearchOnly" :onExtSearch="doSearch" />
  </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Container from "@/components/ui/layout/Container";
import Icons from "@/lib/Icons";

export default {
  name: "List",
  components: {Container, FilteredItemList, ConfirmModal },
  props: {
    module : Object,
    view : {type: Object, default: () => {}},
  },
  data() {
    return {
      renderId: 0,
      itemForDeletion: null,
      itemForSwitching: null,
      search : "",
      externalModule: null
    }
  },

  async mounted() {
    await this.initItems()
  },

  computed: {
    ...mapGetters(['isLoggedIn','appModules', 'currentUser']),
    m() {
      if (this.externalModule) {
        for (let mod of this.appModules) {
          if (mod.path === this.externalModule) return mod
        }
      }
      return this.module
    },
    isSearchOnly() { return this.m && this.m.searchOnly },
    defaultSortBy() { return this.m.defaultSortBy },
    v() { return this.view },
    name() { return (this.v && this.v.name) ? this.v.name : this.m.name; },
    path() { return (this.v && this.v.path) ? this.v.path : this.m.path; },
    dataPath() { return (this.v && this.v.path) ? (this.v.dataPath ? this.v.dataPath : this.v.path) : (this.m.dataPath ? this.m.dataPath : this.m.path); },
    plural() { return (this.v && this.v.plural) ? this.v.plural : this.m.plural; },
    filters() { return (this.v && this.v.filters) ? this.v.filters : this.m.filters; },
    filterData() { return (this.v && this.v.filterData) ? this.v.filterData : this.m.filterData; },
    filterFormat() { return (this.v && this.v.filterFormat) ? this.v.filterFormat : this.m.filterFormat; },
    allItems() {
      if (!this.m) return []
      let data = this.$store.getters['all_'+this.dataPath] || []
      if (this.v && this.v.filter) data = data.filter(this.v.filter)
      else if (this.m.filter)  data = data.filter(this.m.filter)
      return data
    },
    moduleFields() {
      let fields = this.v && this.v.fields ? this.v.fields : (this.m ? this.m.fields : []);
      let actions = this.v && this.v.actions ? this.v.actions : (this.m && this.m.actions ? this.m.actions : []);
      if (typeof fields === "function") fields = fields(this.instance, this.moduleMeta)

      if (this.m.allowDelete && !actions.find(it => it.name === 'delete')) actions.push({ mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" })

      if (!fields.find(it => it.actions)) fields.push(
        {label: "", prop: "", width: "10%", actions: actions.map(action => ({...action, icon: typeof action.icon === 'string'?Icons[action.icon]:action.icon }))})

      return fields;
    },
    moduleMeta() {
      const meta = this.m.meta || {}
      if (this.m.metaModules) {
        for (let mm of this.m.metaModules) {
          meta[mm] = this.externalItems(mm)
        }
      }
      if (this.m.computedMeta) {
        for (let cm in this.m.computedMeta) {
          meta[cm] = this.m.computedMeta[cm](this.allItems, meta)
        }
      }

      return meta;
    },
    features() {
      if (!this.currentUser || !this.currentUser.app) return []
      return this.currentUser.app.features || []
    }
  },

  methods: {
    ...mapActions(['setLoading','get_brands_list']),


    async doSearch(search) {
      if (this.isSearchOnly) {
        await this.$store.dispatch('search_'+this.m.path, search)
      }
    },

    async initItems() {

      if (!this.module && this.$route.params && this.$route.params['external_id']) {
        this.externalModule = this.$route.params['external_id']
      }
      else this.externalModule = null

      const conditions = [
        [!this.allItems.length, this.getItems]
      ]
      if (this.m && this.m.metaModules) {
        for (let mm of this.m.metaModules) {
          conditions.push([!this.externalItems(mm).length, this.externalGetItems(mm)])
        }
      }

      await delayedIfLoading(!this.isLoggedIn, actionIf(conditions), this.setLoading,
          () => this.isLoggedIn, () => this.$router.push('/login'))
    },

    externalItems(modulePath) {
      return this.$store.getters['all_'+modulePath] || []
    },
    externalGetItems(modulePath) {
      return async () => {
        await this.$store.dispatch('get_'+modulePath+'_list')
      }
    },

    async getItems() {
      if (!this.m) return []

      if (!this.m.searchOnly) await this.$store.dispatch('get_'+this.m.path+'_list')
    },

    ...mapActions(['setLoading']),
    goto(item) {
      if (this.externalModule) this.$router.push('/module/'+this.path+'/'+item.id)
      else this.$router.push('/'+this.path+'/'+item.id)
    },
    addNewItem() {
      if (this.externalModule) this.$router.push('/module/'+this.path+'/new')
      else this.$router.push('/'+this.path+'/new')
    },
    askToDeleteItem(item) {
      this.itemForDeletion = item;
    },
    askToSwitchTo(item) {
      this.itemForSwitching = item;
    },
    cancelDeleteItem() { this.itemForDeletion = null },
    async confirmDeleteItem() {
      await this.$store.dispatch('del_'+this.m.path+'_list', this.itemForDeletion.id)
      this.itemForDeletion = null
    },
    async confirmSwitchItem() {
      await this.$store.dispatch('switch_'+this.m.path, this.itemForSwitching.id)
      this.itemForDeletion = null
      window.location.reload()
    },
    cancelSwitchItem() {
      this.itemForSwitching = null
    }
  },

  watch : {
    async module() {
      await this.initItems()
    },
    async '$route'() {
      this.renderId++
      await this.initItems()
    }
  }


}
</script>
