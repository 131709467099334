import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        integrations : [],
        availableIntegrations : []
    },
    getters : {
        allIntegrations : (state) => state.integrations,
        allAvailableIntegrations : state => state.availableIntegrations,
    },
    actions : {
        async getIntegrations({commit}) {
            const res = await ApiRequest(ApiCall(Api.IntegrationList));
            return await commit('setIntegrations', res);
        },
        async deleteIntegration({commit}, id) {
            await ApiRequest(ApiCall(Api.IntegrationDelete, id))
            return await commit('deleteIntegration', id)
        },
        async updateIntegration({commit}, [integration, id]) {
            await ApiRequest(ApiCall(Api.IntegrationUpdate, integration, id))
            return await commit('saveIntegration', [integration, id])
        },
        async createIntegration({commit}, integration) {
            const res = await ApiRequest(ApiCall(Api.IntegrationAdd, integration))
            integration.id = res.insertId
            return await commit('saveIntegration', [integration, null])
        },
        async getAvailableIntegrations({commit}) {
            const res = await ApiRequest(ApiCall(Api.AvailableIntegrations));
            return await commit('setAvailableIntegrations', res);
        },
    },
    mutations : {
        setIntegrations: (state, integrations) => { state.integrations = integrations},
        setAvailableIntegrations: (state, integrations) => { state.availableIntegrations = integrations},
        saveIntegration: (state, [integration, id]) => {
            if (id) { state.integrations = state.integrations.map(it => it.id === integration.id ? integration : it) }
            else { state.integrations = [...state.integrations, integration]; }
            return state
        },
        deleteIntegration(state, id) { state.integrations = state.integrations.filter(it => it.id !== id) },

    }
}
