<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields" :meta="{genderOptions, plans, courses, retentionStatus}" :title-prop="title" :full="true" :features="features"
                :on-update="onUpdate" :on-submit="onSubmit">
    <template slot="sidebar">
        <BoxGroup>
          <Box>
            <ResetPasswordForm :on-submit="onResetPassword" />
          </Box>
          <Box>
            <SendActivationLink :on-submit="onSendActivationLink" />
          </Box>
        </BoxGroup>
    </template>
    <template slot="sidebar-bottom">
      <BoxGroup>
        <Box v-if="instance">
          <SubscriptionCalculator :expiration-date="instance.expiration_date" :inactive-until="instance.inactive_until_date" :cancellation-date="instance.cancellation_date" :charge-interval="instance.subscription_charge_interval"
                                  :renew="instance.subscription_renew" :subscription-date="instance.subscription_date" :suspended="instance.suspended" />
        </Box>
        <Box v-if="subscriberLog && subscriberLog.length">
          <Header text="Activity Log" />
          <Scroller :height="150">
            <ItemList :full="false" :fields="logFields" :items="subscriberLog" />
          </Scroller>
        </Box>
        <Box>
          <Panel v-if="actions.length" title="Actions">
            <ActionsPanel :actions="actions" @run="onRunAction" :result="actionResult" />
          </Panel>
        </Box>
      </BoxGroup>
    </template>
    <template slot="main">
      <Panel v-if="transactions.length" title="Transactions">
        <ItemList :full="false" :fields="transactionFields" :items="transactions" :on-click="onTransactionClick" v-if="transactions.length" />
      </Panel>
      <Panel v-if="charges.length" title="Charges">
        <ItemList :full="false" :fields="chargesFields" :items="charges" :on-click="onChargeClick" v-if="transactions.length" />
      </Panel>
      <Header :title="true" text="Meta Data" />
      <ItemList :full="false" :fields="metaFields" :items="subscriberMeta" @edit="onEditMeta" @delete="onDeleteMeta" />
      <SubscriberMetaForm v-if="editMetaIndex !== null" :meta-value="editMeta.meta_value" @update="onSaveMeta" @cancel="onCancelEditMeta" />
    </template>
  </InstanceDash>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import Fe from "@/lib/ui/FormElement";
import ResetPasswordForm from "@/components/forms/ResetPasswordForm";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Box from "@/components/ui/layout/Box";
import ItemList from "@/components/ui/lists/ItemList";
import SubscriptionCalculator from "@/components/forms/SubscriptionCalculator";
import Header from "@/components/ui/utils/Header";
import Icons from "@/lib/Icons";
import SubscriberMetaForm from "@/components/forms/SubscriberMetaForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import ActionsPanel from "@/components/forms/ActionsPanel";
import Panel from "@/components/ui/layout/Panel";
import t from "@/lib/Locale";
import Scroller from "@/components/ui/layout/Scroller";
import SendActivationLink from "@/components/forms/SendActivationLink.vue";

export default {

  name: "Subscriber",
  mixins: [InstanceView],
  components: {
    SendActivationLink,
    Scroller,
    Panel, ActionsPanel, SubscriberMetaForm, Header, SubscriptionCalculator, ItemList, Box, BoxGroup, ResetPasswordForm, InstanceDash,  },
  data() {
    return {

      genderOptions : [
        {id:'m',name:'Male'}, {id:'f',name:'Female'}, {id:'o',name:'Other'},
      ],

      plans : [],
      courses : [],
      retentionStatus : [
        {id:'asked-to-cancel',name:'Asked to Cancel'},
        {id:'in-retention-process',name:'In Retention Process'},
        {id:'retained',name:'Retained'},
        {id:'cancelled',name:'Cancelled'},
      ],

      type: "Subscriber",
      path: "subscribers",
      fields : [
          Fe.Text("first_name", "First Name", "primary", "First Name","Basic Information"),
          Fe.Text("last_name", "First Name", "primary", "Last Name"),
          Fe.Text("email", "E-Mail", "primary", "E-Mail"),
          Fe.Text("phone", "Phone", "primary", "Phone"),
          Fe.Select("gender", "Gender", "small 2", "Select Gender",'name','genderOptions' ),
          Fe.Text("recovery_code", "Recovery Code", "small 2"),
          Fe.Text("transaction_key", "Transaction Key", "small 2"),
          Fe.Text("one_time_key", "One Time Key", "small 2"),
          Fe.Text("charge_token","Charge Token","small 2a",'','Charge Token'),
          Fe.Text("charge_validity","Charge Validity (mmyy)","small 2a",'mmyy'),
          Fe.DateOnly("next_charge_date","Next Charge Date","small 2a"),
          Fe.Select("subscription_plan_id", "Plan", "small 3", "Select Plan",'name','plans','id',true,false,"Plan Details" ),
          {...Fe.Select("course_id", "Course", "small 3", "Select Course",'name','courses'), feature: 'courses'},
          Fe.Checkbox("subscription_active", "Subscription Active", "small 3"),
          Fe.Checkbox("subscription_renew", "Subscription Renewal", "small 3"),
          Fe.Checkbox("vip", "VIP Subscriber", "small 3"),
          Fe.Date("registration_date", "First Registration Date", "small 4"),
          Fe.Date("subscription_date", "Subscription Date", "small 4"),
          Fe.Number("subscription_charge_interval", "Charge Interval (months)", "small 4"),
          Fe.Date("plan_start_date", "Plan Start Date", "small 4"),
          Fe.Text("suspended","Suspension (enter text to suspend subscriber)","small 5"),
          Fe.Select("retention_status", "Retention Status", "small 6", "Select Retention Status",'name','retentionStatus','id',true,false),
          Fe.Date("cancellation_date", "Cancellation Date", "small 6"),
          Fe.Text("cancellation_reason", "Cancellation Reason", "small 6"),
          Fe.Date("expiration_date", "Expiration Date", "small 7"),
          Fe.Date("inactive_until_date", "Inactive Until", "small 7"),
          Fe.Text("coupon_code", "Coupon Code", "small 8"),
          Fe.Text("notes", "Notes", "small 9"),
      ],
      Create : this.createSubscriber,
      Update : this.updateSubscriber,
      defaultData: {
        vip: true,
        first_name: "",
        last_name: "",
      },

      subscriberMeta : null,
      subscriberLog : null,

      transactionFields : [
        {label: "ID", prop: "id", above: 1, sortable: true, mood:(d, tr) => this.transactionMood(d, tr)},
        {label: "Amount", prop:"amount", format: this.getAmount, sortable: true, sum: true, sum_negative: (d, tr) => tr.type==='refund', mood:(d, tr) => this.transactionMood(d, tr)},
        {label: "Date", prop: "date", format: (d) => this.parseDate(d), sortable: true, mood:(d, tr) => this.transactionMood(d, tr)},
        {label: "Status", prop: "status", type: "boolean", always: true, format: (d) => d === 'success', sortable: true},
        {label: "Transaction #", prop: "transaction_key", above: 2, sortable: true, mood:(d, tr) => this.transactionMood(d, tr)},
        {label: "Type", prop: "type", above: 2, sortable: true, mood:(d, tr) => this.transactionMood(d, tr)},
        {label: "Invoice #", prop: "invoice", above: 3, sortable: true, mood:(d, tr) => this.transactionMood(d, tr)},
        {label: "Debit Charge", prop: "debit_charge_date",format: (d,tr) => this.parseDate(d,tr,true), above: 4, sortable: true,},
        {label: "Debit Cancelled", prop: "debit_cancellation_date",format: (d) => this.parseDate(d), above: 4, sortable: true,},
      ],

      chargesFields: [
        {label: "ID", prop: "id", above: 1, sortable: true, mood:(d, tr) => tr.status==='cancelled'?'danger':'success'},
        {label: "Amount", prop:"amount", format: this.getAmount, sortable: true, sum: true, sum_negative: (d, tr) => tr.type==='refund', mood:(d, tr) => tr.status==='cancelled'?'danger':'success'},
        {label: "Date", prop: "date", format: (d) => this.parseDate(d), sortable: true, mood:(d, tr) => tr.status==='cancelled'?'danger':'success'},
        {label: "Status", prop: "status", type: "boolean", always: true, mood: (d) => d==='cancelled'?'danger':'success', sortable: true},
        {label: "Transaction ID", prop: "transaction_id", sortable: true, mood:(d, tr) => tr.status==='cancelled'?'danger':'success'},
        {label: "Invoice #", prop: "invoice", above: 3, sortable: true, mood:(d, tr) => tr.status==='cancelled'?'danger':'success'},
      ],

      metaFields : [
        {label: "Key", prop: "meta_key", sortable: true, width: '20%'},
        {label: "Value", prop: "meta_value", type:"json" },
        {label: "Age", prop: "date", width: '70', above: 3, format: this.parseAge },
        {label: "", prop: "", width: "10%", actions: [
            { mood: "primary", icon: Icons.pencil, action: "edit", tooltip: "Edit" },
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete", confirmation: (meta) => "Are you sre you want to delete '"+meta.meta_key+"'?" }
          ]}
      ],

      logFields : [
        {label: "Date", prop: "date", format: (d) => this.parseDate(d), sortable: true, mood:(d, tr) => tr.status==='cancelled'?'danger':'success'},
        {label: "Type", prop: "type" },
        {label: "Details", prop: "details" },

      ],

      editMeta: null,
      editMetaIndex: null,

      actionResult: null
    }
  },

  async mounted() {

    await this.init()

  },

  computed: {
    ...mapGetters(['currentSubscriber','allPermittedActions','currentUser']),
    transactions() {
      return this.instance && this.externalItems('transactions').length ? this.externalItems('transactions').filter(it => it.subscriber_id === this.instance.id) : []
    },
    charges() {
      return this.instance && this.externalItems('debit-charges').length ? this.externalItems('debit-charges').filter(it => it.subscriber_id === this.instance.id) : []
    },
    actions() {
      if (!this.allPermittedActions) return [];
      return this.allPermittedActions.filter(it => it.scope === 'subscriber');
    },
    getItems() {
      return () => this.$store.dispatch('get_subscribers_list')
    },
    features() {
        if (!this.currentUser || !this.currentUser.app) return []
        return this.currentUser.app.features || []
    },
  },

  methods: {
    ...mapActions(['getSubscribers', 'updateSubscriber', 'createSubscriber','resetPassword', 'getSubscriber','setLoading','setSuccess','getPermittedActions']),
    externalItems(modulePath) {
      return this.$store.getters['all_'+modulePath] || []
    },
    externalGetItems(modulePath) {
      return async () => await this.$store.dispatch('get_'+modulePath+'_list')
    },
    allItems() {
      return this.$store.getters['all_subscribers']
    },

    async init() {
      const conditions = [
        [!this.allItems().length, this.getItems],
        [!this.allPermittedActions.length, this.getPermittedActions],
        [!this.externalItems('plans').length, this.externalGetItems('plans')],
        [!this.externalItems('courses').length, this.externalGetItems('courses')],
        [!this.externalItems('transactions').length, this.externalGetItems('transactions')],
        [!this.externalItems('debit-charges').length, this.externalGetItems('debit-charges')]
      ]

      await this.initInstance('all_subscribers',conditions)
      this.plans = this.externalItems('plans')
      this.courses = this.externalItems('courses')

      if (this.instance && this.instance.id) {
        const sub = await this.getSubscriber(this.instance.id)
        if (this.currentSubscriber && sub) {
          this.subscriberMeta = sub.meta
          this.subscriberLog = sub.log
          if (this.subscriberLog.length) this.subscriberLog = this.subscriberLog.reverse()
        }
      }
    },

    async onResetPassword(newPassword) {
      await this.resetPassword([newPassword, this.instance.id])
    },

    async onSendActivationLink() {
      await this.setLoading(true)
      await ApiRequest(ApiCall(Api.SubscriberSendActivationLink, {email: this.instance.email}))
      await this.setLoading(false)
      await this.setSuccess(t("Activation Link Sent"))
    },

    onEditMeta(meta, index) {
      this.editMeta = meta;
      this.editMetaIndex = index;
    },

    onCancelEditMeta() {
      this.editMeta = this.editMetaIndex = null;
    },

    async onSaveMeta(meta_value) {
      await this.setLoading(true)
      this.$set(this.subscriberMeta, this.editMetaIndex, {...this.subscriberMeta[this.editMetaIndex], meta_value})
      await ApiRequest(ApiCall(Api.SubscriberSetMeta, {key: this.editMeta.meta_key, value: meta_value}, {id: this.instance.id}))
      await this.setLoading(false)
      await this.setSuccess("Updated Subscriber Meta")
      this.onCancelEditMeta()
    },

    async onDeleteMeta(meta, deleteIndex) {
      await this.setLoading(true)
      this.subscriberMeta.splice(deleteIndex, 1);
      await ApiRequest(ApiCall(Api.SubscriberDeleteMeta, {key: meta.meta_key}, {id: this.instance.id}))
      await this.setLoading(false)
      await this.setSuccess("Deleted Subscriber Meta")
    },

    transactionMood(d, tr) {
      if (tr.type === 'refund') return 'danger'
      if (tr.type === 'debit' && tr.debit_cancellation_date) return 'warning'
      return 'success'
    },

    parseDate(d,tr,justDate = false) {
      if (!d) return '-'
      d = new Date(d);
      let date = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear(),
          time = d.getHours()+':'+(d.getMinutes().toString().length < 2 ? '0'+d.getMinutes():d.getMinutes())
      return date + (justDate?'':' '+time)
    },

    parseAge(d) {
      d = (new Date().getTime() - new Date(d).getTime()) / 1000; // secs
      if (d < 60) return t("{0} seconds", Math.round(d))
      else if (d/60 < 60) return t("{0} minutes", Math.round(d/60))
      else if (d/60/60 < 24) return t("{0} hours", Math.round(d/60/60))
      else if (d/60/60/24 < 32) return t("{0} days", Math.round(d/60/60/24))
      else if (d/60/60/24/30.5 < 12) return t("{0} months", Math.round(d/60/60/24/30.5))
      else return t("{0} years", Math.round(d/60/60/24/365))
    },

    getAmount(d, tr) {
      let amount = isNaN(d*1) ? '-' : ''+parseFloat(d).toFixed(2)
      if (tr.debit_amount && tr.debit_amount*1 !==d*1) amount += ' ('+parseFloat(tr.debit_amount).toFixed(2)+')'
      return amount
    },

    title(subscriber) {
      return subscriber.first_name + ' '+subscriber.last_name
    },

    onTransactionClick(item) {
      this.$router.push('/transactions/'+item.id)
    },
    onChargeClick(item) {
      this.$router.push('/transactions/'+item.transaction_id)

    },

    async onRunAction(action, params, name) {
      await this.setLoading(true)
      this.actionResult = null
      params.__subscriber_id = this.instance.id
      const r = await ApiRequest(ApiCall(Api.CallAction, params, {action}))
      if (r.res === 'ok') {
        this.actionResult = r.data;
        await this.getSubscriber(this.instance.id)
        this.subscriberMeta = this.currentSubscriber.meta
        this.subscriberLog = this.currentSubscriber.log
        this.$set(this.instance, 'subscription_renew', this.currentSubscriber.subscription_renew)
        this.$set(this.instance, 'cancellation_date', this.currentSubscriber.cancellation_date)
        this.$set(this.instance, 'expiration_date', this.currentSubscriber.expiration_date)
        // await this.getTransactions()
        await this.externalGetItems('transaction')
        await this.setSuccess(t('Ran subscriber action: {0}',name))
      }
      await this.setLoading(false)
    }
  },
  watch : {
    $route(to) {
      if (!this.instance || (this.instance && to.params.id*1 !== this.instance.id))
      this.init()
    }
  }
}
</script>
