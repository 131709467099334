<template>
  <Container>
    <Container v-if="isLoggedIn">
    <ContentMockup :rows="3" v-if="!loaded" />
    <Panel title="Summary" v-if="loaded">
      <Flexy :even="true" :full="4">
        <Box title="Sales">
          <PeriodStats :periods="dayWeekMonthPeriod" :legend="salesLegend" :moods="salesMoods" :full="1" :half="5" :data="salesData" />
        </Box>
        <Box title="Subscribers">
          <Flexy :even="true" :half="3" :full="1">
            <Box title="New Signups">
              <StatsBox :stats="subscribersStats" />
            </Box>
            <Box title="Plan Breakdown">
              <StatsBox :stats="planStats" />
            </Box>
          </Flexy>
          <Box v-if="activeNow && activeNow.length" :title="activeNow.length+' Subscribers using the app today'">
            <span v-for="(sub, i) in activeNow" :key="i">
              <router-link :to="'/subscribers/'+sub.id">{{sub.name}}</router-link>
              <small> {{visitTime(sub.d)}}</small>
              <span v-if="i < activeNow.length-1">, </span>
            </span>
          </Box>
        </Box>
      </Flexy>
    </Panel>

    <Panel title="Plan Overview" v-if="loaded">
      <Flexy :even="true" :half="3" :full="1" :auto-flex="true">
        <Box title="General Statistics">
          <StatsBox :stats="generalStats" :router-links="generalLinks" />
        </Box>
        <Box title="Content">
          <StatsBox :stats="contentStats" :router-links="contentLinks" />
        </Box>
      </Flexy>
        <Flexy :even="true" :half="3" :full="1" :auto-flex="true">
          <Box title="Reports">
              <Flexy>
              <Button @click="getSubscribersReport" label="Subscribers Report" mood="primary" text="Subscribers Report" />
              <Button @click="getSalesReport" label="Sales Report" mood="primary" text="Sales Report" />
              </Flexy>
          </Box>
        </Flexy>
    </Panel>
    </Container>
  </Container>
</template>

<script>
import Box from "@/components/ui/layout/Box";
import Container from "@/components/ui/layout/Container";
import Panel from "@/components/ui/layout/Panel";
import Flexy from "@/components/ui/layout/Flexy";
import PeriodStats from "@/components/ui/stats/PeriodStats";
import StatsBox from "@/components/ui/stats/StatsBox";
import {mapActions, mapGetters} from "vuex";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import ContentMockup from "@/components/ui/layout/ContentMockup";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import Button from "@/components/ui/form/Button.vue";
import ApiRoutes from "@/config/ApiRoutes";

export default {
  name: "Home",
  props: [],
  components: {Button, ContentMockup, StatsBox, PeriodStats, Flexy, Panel, Container, Box},

  data() {
    return {
      loaded: false,
      activeNow: null,
      dayWeekMonthPeriod: ['Today','This Week','Month','Year'],
      salesLegend : { sales: "Sales", charges: "Charges", refunds: "Refunds" },
      salesMoods : { sales: "success", charges: "success", refunds: "danger" },
      subscribersLegend : { subscribers: "New Subscribers", cancels: "Cancellations" },
      subscribersMoods : { subscribers: "success", cancels: "danger" },
    }
  },
  async mounted() {
    this.loaded = false
    await delayedIfLoading(!this.isLoggedIn, actionIf([
      // [!this.externalItems('subscribers').length, this.externalGetItems('subscribers')],
      [!this.externalItems('transactions').length, this.externalGetItems('transactions')],
      [!this.externalItems('debit-charges').length, this.externalGetItems('debit-charges')],
      [!this.externalItems('plans').length, this.externalGetItems('plans')],
      [!this.externalItems('media-lists').length, this.externalGetItems('media-lists')],
      [!this.externalItems('videos').length, this.externalGetItems('videos')],
      [!this.externalItems('exclusives').length, this.externalGetItems('exclusives')],
      ]), this.setLoading,
      () => { return this.isLoggedIn }, () => this.$router.push('/login'), 1000, 5)

    const home = await ApiRequest(ApiCall(Api.AdminHome))
    if (home.activeSubscribers) this.activeNow = home.activeSubscribers

    this.loaded = true
  },
  computed : {
    ...mapGetters(['isLoggedIn']),

    activeSubscribers() {return this.externalItems('subscribers').filter(it => it.subscription_active && it.subscription_renew)},
    canceledSubscribers() {return this.externalItems('subscribers').filter(it => it.subscription_active && !it.subscription_renew)},
    inactiveSubscribers() {return this.externalItems('subscribers').filter(it => !it.subscription_active)},
    salesData() {
      let transactions = this.externalItems('transactions').filter(it => it.status === 'success'),
          charges = this.externalItems('debit-charges').filter(it => it.status === 'success')

      const res = {}, d = new Date().getTime(),
          today = transactions.filter(it => d - new Date(it.date).getTime() < 1000*60*60*24),
          week = transactions.filter(it => d - new Date(it.date).getTime() < 1000*60*60*24 *7),
          month = transactions.filter(it => d - new Date(it.date).getTime() < 1000*60*60*24 *30),
          year = transactions.filter(it => d - new Date(it.date).getTime() < 1000*60*60*24 *365),
          todayCharges = charges.filter(it => d - new Date(it.date).getTime() < 1000*60*60*24),
          weekCharges = charges.filter(it => d - new Date(it.date).getTime() < 1000*60*60*24 *7),
          monthCharges = charges.filter(it => d - new Date(it.date).getTime() < 1000*60*60*24 *30),
          yearCharges = charges.filter(it => d - new Date(it.date).getTime() < 1000*60*60*24 *365);

      res['Today'] = { sales: today.length?today.reduce((a, b) => a+(b.type==='refund'?0:b.amount*1),0):0, charges: todayCharges.reduce((a, b) => a+(b.amount*1),0), refunds: today.length?today.reduce((a, b) => a+(b.type==='refund'?b.amount*1:0),0):0 }
      res['This Week'] = { sales: week.length?week.reduce((a, b) => a+(b.type==='refund'?0:b.amount*1),0):0, charges: weekCharges.reduce((a, b) => a+(b.amount*1),0), refunds: week.length?week.reduce((a, b) => a+(b.type==='refund'?b.amount*1:0),0):0 }
      res['Month'] = { sales : month.length?month.reduce((a, b) => a+(b.type==='refund'?0:b.amount*1),0):0, charges: monthCharges.reduce((a, b) => a+(b.amount*1),0), refunds: month.length?month.reduce((a, b) => a+(b.type==='refund'?b.amount*1:0),0):0 }
      res['Year'] = { sales : year.length?year.reduce((a, b) => a+(b.type==='refund'?0:b.amount*1),0):0, charges: yearCharges.reduce((a, b) => a+(b.amount*1),0), refunds: year.length?year.reduce((a, b) => a+(b.type==='refund'?b.amount*1:0),0):0 }
      return res
    },
    subscribersStats() {
      let subscribers = this.activeSubscribers
      const res = {}, d = new Date().getTime(),
          today = subscribers.filter(it => d - new Date(it.subscription_date).getTime() < 1000*60*60*24),
          week = subscribers.filter(it => d - new Date(it.subscription_date).getTime() < 1000*60*60*24 *7),
          month = subscribers.filter(it => d - new Date(it.subscription_date).getTime() < 1000*60*60*24 *30),
          year = subscribers.filter(it => d - new Date(it.subscription_date).getTime() < 1000*60*60*24 *365);

      res['Today'] = today.length
      res['This Week'] = week.length
      res['Month'] = month.length
      res['Year'] = year.length
      return res
    },

    planStats() {
      let subscribers = this.activeSubscribers
      let plans = {}, plan
      for (let sub of subscribers) if (sub.subscription_plan_id) {
        plan = this.externalItems('plans').find(it => it.id === sub.subscription_plan_id);
        if (plan && plan.name) plans[plan.name] = plans[plan.name] ? plans[plan.name] + 1 : 1
      }
      return plans
    },


    generalStats() { return {
      "Active Subscribers": this.activeSubscribers.length,
      "Canceled Subscribers": this.canceledSubscribers.length,
      "Pending Leads": this.inactiveSubscribers.length,
      "Transactions" : this.externalItems('transactions').length,
    }},

    generalLinks() {
      return {
        "Active Subscribers": "/subscribers",
        "Canceled Subscribers": "/subscribers",
        "Pending Leads": "/pending",
        "Transactions" : "/transactions",
      }
    },

    contentStats() {
      return {
        "Subscription Plans" : this.externalItems('plans').length,
        "Videos" : this.externalItems('videos').length,
        "Media Lists" : this.externalItems('media-lists').length,
        "Exclusives" : this.externalItems('exclusives').length,
      }
    },
    contentLinks() {
      return {
        "Subscription Plans" : "/plans",
        "Videos" : "/videos",
        "Media Lists" : "media-lists",
        "Exclusives" : "exclusives",
      }
    }

  },
  methods: {
    ...mapActions(['setLoading']),

    visitTime(date) {
      date = new Date(date)
      let hr = date.getHours()+'', min = date.getMinutes()+''
      if (hr.length<2)hr='0'+hr;
      if (min.length<2)min='0'+min;
      return hr+':'+min
    },

    externalItems(modulePath) {
      return this.$store.getters['all_'+modulePath] || []
    },
    externalGetItems(modulePath) {
      return async () => await this.$store.dispatch('get_'+modulePath+'_list')
    },

    async getSalesReport() {
      const data = await ApiRequest(ApiCall(ApiRoutes.SalesReport))
      let csv = "";
      for (let prop in data[0]) {
        csv += prop + ',';
      }
      for (let row of data) {
        for (let prop in row) {

          if (prop === 'phone' && row[prop].substring(0, 1) === '5') row[prop] = '0' + row[prop];

          csv += row[prop].toString().indexOf(',') > -1 ? `"${row[prop]}"` : row[prop];
          csv += ',';
        }
        csv += '\n';
      }
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "sales_report.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },

    async getSubscribersReport() {

      const data = await ApiRequest(ApiCall(ApiRoutes.SubscribersReport))
      // convert to csv and download
      let csv = "";
      for (let prop in data[0]) {
        csv += prop + ',';
      }
      for (let row of data) {
        for (let prop in row) {

          if (prop === 'phone' && row[prop].substring(0, 1) === '5') row[prop] = '0' + row[prop];

          csv += row[prop].toString().indexOf(',') > -1 ? `"${row[prop]}"` : row[prop];
          csv += ',';
        }
        csv += '\n';
      }
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "subscribers_report.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);



    },
  }
}
</script>
