<template>
    <Flexy :col="true" class="checkbox-wrapper">
    <label>
      <Flexy :centered="true">
        <input type="checkbox" :value="val" @keyup="e => onChange(e)" @change="e => onChange(e)" :checked="checked" />
        <Txt class="label-text" v-if="label" :text="label" />
      </Flexy>
    </label>
      <Txt v-if="description" :text="description"></Txt>
    </Flexy>
</template>
<script>
import Flexy from "@/components/ui/layout/Flexy";
import Txt from "@/components/ui/utils/Txt";
export default {
  name: "Checkbox",
  components: {Txt, Flexy},
  props : {
    value: { type: [String, Number, Boolean], default: '' },
    label: { type: String, default: '' },
    checked: { type: Boolean, default: false },
    description: { type: String, default: '' },
  },
  data() {
    return {
      val: ''
    }
  },
  mounted() {
    this.val = this.value;
  },
  computed : {
    inputPlaceholder() {
      return this.placeholder;
    }
  },
  methods : {
    onChange(e) {
      this.$emit('change', e.target.value, e.target)
    }
  },
  watch : {
    value() {
      this.val = this.value
    }
  }
}
</script>
