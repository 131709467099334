import {ApiCall, ApiRequest} from "@/lib/Api";
import List from "@/components/views/module/List";
import Instance from "@/components/views/module/Instance";

export default {

    createStoreModule(module, store) {
        const {path} = module;
        const getterName = 'all_'+path,
            listActionName = 'get_'+path+'_list',
            searchActionName = 'search_'+path,
            updateActionName = 'update_'+path,
            createActionName = 'create_'+path,
            delActionName = 'del_'+path,
            setMutationName = 'set_'+path,
            saveMutationName = 'save_'+path

        const state = {
            [path] : []
        }
        const getters = {
            [getterName] : state => state[path]
        }, actions = {
            async [listActionName]({commit}) {
                console.log(listActionName)
                const res = await ApiRequest(ApiCall(module.endpoints.list))
                return await commit(setMutationName, res);
            },
            async [searchActionName]({commit}, search) {
                            const res = await ApiRequest(ApiCall(module.endpoints.search, {s : search}))
                            return await commit(setMutationName, res);
            },
            async [delActionName]({commit}, id) {
                await ApiRequest(ApiCall(module.endpoints.delete, id))
                return await commit(delActionName, id)
            },
            async [updateActionName]({commit}, [item, id]) {
                await ApiRequest(ApiCall(module.endpoints.update, item, id))
                return await commit(saveMutationName, [item, id])
            },
            async [createActionName]({commit}, item) {
                const res = await ApiRequest(ApiCall(module.endpoints.add, item))
                item.id = res.insertId
                return await commit(saveMutationName, [item, null])
            },
        },
        mutations = {
            [setMutationName]: (state, value) => { state[path] = value },
            [saveMutationName]: (state, [value, id]) => {
                if (id) { state[path] = state[path].map(it => it.id === value.id ? value : it) }
                else { state[path] = [...state[path], value]; }
                return state
            },
            [delActionName]: (state, id) => { state[path] = state[path].filter(it => it.id !== id) }
        }


        store.registerModule(path, {state, getters, actions, mutations})
    },

    createModuleRoutes(module, routes) {
        if (module.fields) {
            if (module.views) {
                for (let view of module.views) {
                    routes.push({path: '/'+view.path, component: List, props: {module,  view} })
                }
            }
            else routes.push({path: '/'+module.path, component: List, props: {module} })
        }
        if (module.form) {
            if (module.views) {
                for (let view of module.views) {
                    routes.push({path: '/'+view.path+'/:id', component: Instance, props: {module, view} })
                }
            }
            routes.push({path: '/'+module.path+'/:id', component: Instance, props: {module} })
        }

        return routes
    }
}
