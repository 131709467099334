import Fe from "@/lib/ui/FormElement";

export default {

    allowDelete: true,
    name: "Exclusive",
    path: "exclusives",
    plural: "Exclusives",
    menu: "Exclusive",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],
    titleProp: "title",

    endpoints : {
        list : { path : '/admin/exclusive/list', method: "get", token: true },
        get : { path : '/admin/exclusive/get/:id', method: "get", token: true },
        add : { path : '/admin/exclusive/add', method: "post", body: true, token: true },
        update : { path : '/admin/exclusive/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/exclusive/delete/:id', method: "get", params: true, token: true },
    },

    fields: [
        {label: "#", prop: "id", sortable: true, width: "60px", above: 1 },
        {label: " ", prop: "thumbnail", type: "image", width: "45px"},
        {label: "Title", prop: "title", primary: true, sortable: true},
        {label: "Headline", prop: "headline", primary: true, sortable: true, above: 1},
        {label: "Code", prop: "code", primary: true, sortable: true},
    ],

    form : [
        Fe.Text("title", "Title"),
        Fe.Text("headline", "Headline"),
        Fe.Text("code", "Code"),
        Fe.Image("thumbnail", "Thumbnail"),
        Fe.Textarea("details", "Description"),
        Fe.Textarea("conditions", "Conditions"),
        Fe.Text("url", "External URL"),
    ],

}
