import Fe from "@/lib/ui/FormElement";
import t from "@/lib/Locale";


const HookKeywords = {
    "reset-password": ['#FIRST_NAME#', '#LAST_NAME#', '#FULL_NAME#', '#EMAIL#', '#RESET_LINK#', '#RESET_HASH#'],
    "activate-account": ['#FIRST_NAME#', '#LAST_NAME#', '#FULL_NAME#', '#EMAIL#', '#ACTIVATE_LINK#', '#ACTIVATE_HASH#'],
    "signup-welcome": ['#FIRST_NAME#', '#LAST_NAME#', '#FULL_NAME#', '#EMAIL#', '#ACTIVATE_LINK#', '#ACTIVATE_HASH#'],
}

function getKeywords(instance) {
    return instance && instance.hook ? HookKeywords[instance.hook] : ['(select hook first)'];
}

export default {

    allowDelete: true,
    name: "Whatsapp Message",
    path: "whatsapp",
    plural: "Whatsapp Messages",
    menu: "Whatsapp Messages",
    ability: 'whatsapp',
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],

    meta : {
        templateNames : [
            {id:'reset-password',name:t('Reset Password')},
            {id:'activate-account',name:t('Activate Account')},
            {id:'signup-welcome',name:t('Signup Welcome')},
        ],

        componentTypes : [
            {id:'body',name:t('Body')},
            {id:'header',name:t('Header')},
            {id:'button',name:t('Button')},
        ],

        parameterTypes: [
            {id:'text',name:t('Text')},
            {id:'image',name:t('Image')},
        ]
    },

    endpoints : {
        list : { path : '/admin/whatsapp-messages/list', method: "get", token: true },
        get : { path : '/admin/whatsapp-messages/get/:id', method: "get", token: true },
        add : { path : '/admin/whatsapp-messages/add', method: "post", body: true, token: true },
        update : { path : '/admin/whatsapp-messages/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/whatsapp-messages/delete/:id', method: "get", params: true, token: true },
        test : { path : '/admin/whatsapp-messages/send-test-message/:id', method: "post", body: true, params: true, token: true },
    },

    fields: [
        {label: "ID", prop: "id", above: 1},
        {label: "Order", prop: "sort"},
        {label: "Name", prop: "name"},
        {label: "Hook", prop: "hook"},
        {label: "Template", prop: "template_name"},
        {label: "Language", prop: "template_language", above: 3},
    ],

    form : () => [
        Fe.Text("name", "Name", "primary"),
        Fe.Select("hook", "Hook", "primary","Select Hook",'name', 'templateNames'),
        Fe.Text("template_name", "Template Name", "primary","Enter Template Name"),
        Fe.Text("template_language", "Template Language", "primary","en_US"),
        Fe.Number("sort", "Sort Order", "primary 2","Sending Order for selected hook","Message Settings"),
        Fe.Checkbox("is_active", "Active", "primary 2"),
    ],

    additionalControls : (instance) => [
        {type: "edit-list", title: "Parameters", position: "main", items: "params", titleProp: "name", fields: [
                { prop: "type", },
                { prop: "value", primary: true },
                { prop: "component", },
            ],
            formFields: () => [
                Fe.Text("value", "Value", "primary"),
                Fe.Select("type", "Type", "primary","Select Type",'id', 'parameterTypes'),
                Fe.Select("component", "Component", "primary","Select Component",'id', 'componentTypes'),
            ]
        },
        { type: "text", label:"Special values: "+ (instance? getKeywords(instance).join(", "):''), position: "main" },
        { type: "params-form", label:"Test Message", position: "sidebar", endpoint: "test", params: [
                { name: "to", label: "To", type: "text", placeholder: "Enter phone number" },
            ]
        },
    ],

}
