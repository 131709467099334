<template>
  <div class="form-group">
    <Txt :bold="true" v-if="title">{{t(title)}}</Txt>
    <div :class="'form-group-inner ' + cls">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Txt from "@/components/ui/utils/Txt";
import t from '@/lib/Locale';
export default {
  name: "FormGroup",
  components: {Txt},
  props: {
    title: String,
    cls: String,
  },
  methods : {
    t,
  }
}
</script>
