import Fe from "@/lib/ui/FormElement";
import t from "@/lib/Locale";

const utils = {
    getStatus(d, tr) {
        return d === 'error'?'Error':tr.debit_cancellation_date?'Debit Canceled':'Success'
    },

    getStatusMood(d,tr) {
        return d === 'Error'?'danger':tr.debit_cancellation_date ?'warning':'success'
    },

    getChargeStatusMood(d) {
        return d === 'cancelled'?'danger':'success'
    },

    parseDate(d) {
        d = new Date(d.replace(' ', 'T'));
        let date = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear(),
            time = d.getHours()+':'+(d.getMinutes().toString().length < 2 ? '0'+d.getMinutes():d.getMinutes())
        return date + ' '+time
    },

    getAmount(d, tr) {
        let amount = isNaN(d*1) ? '-' : ''+parseFloat(d).toFixed(2)
        if (tr.debit_amount && Math.round(tr.debit_amount) !==Math.round(d)) amount += ' ('+parseFloat(tr.debit_amount).toFixed(2)+')'
        return amount
    },

    subscriberActive(tr, meta) {
        console.log('subscriberActive', tr, meta)
        if (!meta.subscriberObj) { meta.subscriberObj = {}; if (meta.subscribers) for (let sub of meta.subscribers) meta.subscriberObj[sub.id] = sub }
        let sub = meta.subscriberObj[tr.subscriber_id]
        return sub ? sub.subscription_active : false
    },

    getSubscriberName(d, tr, meta) {
        if (!meta.subscribers || !meta.subscribers.length) return '-'
        if (tr._sub_name) return tr._sub_name
        if (!meta.subscriberObj) { meta.subscriberObj = {}; for (let sub of meta.subscribers) meta.subscriberObj[sub.id] = sub }
        let sub = meta.subscriberObj[tr.subscriber_id]
        if (sub) {
            tr._sub_name = sub.first_name +' '+sub.last_name
            return sub.first_name +' '+sub.last_name
        }
        return ''
    },
    getSubscriberMood(d, tr, meta) {
        if (!meta.subscribers || !meta.subscribers.length) return ''
        if (!meta.subscriberObj) { meta.subscriberObj = {}; for (let sub of meta.subscribers) meta.subscriberObj[sub.id] = sub }
        let sub = meta.subscriberObj[tr.subscriber_id]
        return sub && sub.subscription_active ? '' : 'warning'
    },
    getSubscriberEmail(d, tr, meta) {
        if (!meta.subscribers || !meta.subscribers.length) return '-'
        if (tr._sub_email) return tr._sub_email
        if (!meta.subscriberObj) { meta.subscriberObj = {}; for (let sub of meta.subscribers) meta.subscriberObj[sub.id] = sub }
        let sub = meta.subscriberObj[tr.subscriber_id]
        if (sub) {
            tr._sub_email = sub.email
            return sub.email
        }
        return ''
    },
    getCharges(d, tr, meta) {
        if (tr._charges) return tr._charges;
        else {
            tr._charges = meta['debit-charges'].filter(it => it.transaction_id === tr.id).length || ''
            return tr._charges;
        }
    },
    chargeMoods(d, tr) {
        return tr._charges ? 'success' :'';
    },
}

export default {

    name: "Transaction",
    path: "transactions",
    plural: "Transactions",
    menu: "Transaction",

    permissions: ['BRAND_MANAGER','BRAND_ACCOUNTING'],
    titleProp: "transaction_key",
    allowDelete: false,

    metaModules : [
      // 'subscribers',
        'debit-charges'
    ],

    endpoints : {
        list : { path : '/admin/transaction/list', method: "get", token: true, cs: true },
        get : { path : '/admin/transaction/get/:id', method: "get", token: true },
        add : { path : '/admin/transaction/add', method: "post", body: true, token: true },
        update : { path : '/admin/transaction/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/transaction/delete/:id', method: "get", params: true, token: true },
    },

    fields:(instance, meta) => [
        {label: "ID", prop: "id", above: 1, sortable: true},
        {label: "Subscriber Name", prop: "subscriber_name", above: 2, format : (d, tr) => utils.getSubscriberName(d, tr, meta), mood: (d,tr) => utils.getSubscriberMood(d, tr, meta), sortable: true},
        {label: "Subscriber Email", prop: "subscriber_email", above: 1, format : (d, tr) => utils.getSubscriberEmail(d, tr, meta),mood: (d,tr) => utils.getSubscriberMood(d, tr, meta), sortable: true},
        {label: "Sum", prop:"amount", target:"sum", format: utils.getAmount, sortable: true, sum: true,sum_negative: (d, tr) => tr.type===t('refund'), mood: (d,tr) => tr.type===t('refund')?'danger':'success'},
        {label: "Date", prop: "date", format: utils.parseDate, sortable: true},
        {label: "Status", prop: "status", type: "boolean", format: utils.getStatus, always: true, mood: utils.getStatusMood},
        {label: "Charges", prop: "charges", format:(d, tr) => utils.getCharges(d, tr, meta), mood:(d, tr) => utils.chargeMoods(d,tr,meta), sortable: true, feature: 'recurring-billing'},
        {label: "Transaction #", prop: "transaction_key", above: 3, sortable: true},
        {label: "Code", prop: "transaction_code", above: 4, sortable: true},
        {label: "Type", prop: "type", format: d => t(d), sortable: true},
        {label: "Invoice #", prop: "invoice", above: 2, sortable: true},
        {label: "Debit ID", prop: "recurring_id", above: 3, sortable: true, feature: 'recurring-billing'},
    ],

    filters: {
        'status': 'Status',
        'type': 'Type',
    },

    form : (instance) => [
        // Fe.Select("subscriber_id", "Subscriber", "primary", "Select Subscriber", 'name','subscriberOptions'),
        Fe.Number("subscriber_id", "Subscriber ID", "primary"),
        Fe.Number("amount", "Amount", "primary"),
        Fe.Select("status", "Status", "primary 2", "Select Status",'name','transactionStatus' ),
        Fe.Date("date", "Date", "primary 2"),
        Fe.Text("transaction_key", "Transaction Key", "primary 3"),
        Fe.Text("transaction_code", "Transaction Code", "primary 3"),
        Fe.Text("invoice", "Invoice #", "primary 3"),
        {...Fe.Select("type", "Type", "primary 3", "Select Status",'name','transactionTypes' ), feature: 'recurring-billing'},
        {...Fe.Text("recurring_id", "Debit ID", "primary 4"), show: () => instance.type === 'debit', feature: 'recurring-billing'},
        {...Fe.Number("debit_amount", "Debit Amount", "primary 4"), show: () => instance.type === 'debit', feature: 'recurring-billing' },
        {...Fe.Date("debit_charge_date", "Debit Charge Date", "primary 4"), show: () => instance.type === 'debit' , feature: 'recurring-billing'},
        {...Fe.Date("debit_cancellation_date", "Debit Cancellation Date", "primary 4"), show: () => instance.type === 'debit', feature: 'recurring-billing' },
        {...Fe.Select("refunded", "Refunded", "primary 5", "Select Refund Transaction", 'name','refundTransactions'), show: () => instance.type !== 'refund'},
        {...Fe.Text("coupon_code", "Coupon Code", "primary 5"), feature: 'coupons'},
        {...Fe.Text("coupon_multi_code", "Coupon Multi-Code", "primary 5"), feature: 'coupons'},
        Fe.Textarea("description", "Description", "primary 6"),
    ],

    additionalControls : (instance, meta) => [
        { type: 'list', position:'main', title: 'Charges', items: 'debit-charges', key: 'transaction_id', feature: 'recurring-billing', fields: [
                {prop: "id", label: "ID",},
                {prop: "date", label: "Date", format: utils.parseDate},
                {prop: "amount", label: "Amount", sum: true, format: d => parseFloat(d).toFixed(2)},
                {prop: "status", label: "Status", type: "boolean", always: true, mood : utils.getChargeStatusMood},
            ]
        },
        {type: 'button', position: 'sidebar', label: (tr) => 'Go to '+(utils.subscriberActive(tr, meta)?'Subscriber':'Pending Lead'), mood: 'primary', target: (tr) => '/'+(utils.subscriberActive(tr, meta)?'subscribers':'pending')+'/'+tr.subscriber_id }
    ],

    computed : {
        refundedTransactions(list) {
            return list.filter(it => it.type === 'refund')
        }
    },
    meta : {
        transactionTypes : [
            {id: 'debit', name: "Debit"},{id: 'credit', name: "Credit"},{id: 'refund', name: "Refund"},
        ],
        transactionStatus : [
            {id:'success',name:'Success'}, {id:'error',name:'Error'}, {id:'canceled',name:'Canceled'},
        ],
    },
    computedMeta : {
        refundTransactions(list, meta) {
            if (typeof list === 'function') list = list()
            return list.filter(t => t.type === 'refund').map(t => {
                const d = new Date(t.date);
                let sub = meta.subscribers ? meta.subscribers.find(it => it.id === t.subscriber_id) : null;

                const date = d.getDate()+'/'+(d.getMonth()+1)+'/'+d.getFullYear()+' '+((d.getHours()+'').length>1?'':'0')+d.getHours()+':'+((d.getMinutes()+'').length>1?'':'0')+d.getMinutes();
                return {id: t.id, name: t.id + ': '+date + ' : '+(sub?sub.first_name+' '+sub.last_name:'-')}
            })
        },
        subscriberOptions() {
            return []
            // return meta.subscribers//.filter(it => it && it.subscription_active).map(it => ({id: it.id, name: it.id+': '+it.first_name+' '+it.last_name}))
        }
    },

}
