<template>
  <form @submit="doSubmit" v-if="data">
    <FormGroup v-for="(group, groupName) in groups" :key="groupName" :cls="groupClass(groupName)" :title="groupTitle(groupName)">
      <FormField v-for="field in group" :key="field.prop" :set-image="(img) => setImage(field, img)" :preview-img="previewImgSrc(field)" :show="field.show ? field.show : true"
                 :field="field" :value="val(field.prop, data)" @change="value => update(field.prop, value)" :meta="meta" :clearable="field.clearable"
      />
    </FormGroup>
  </form>
</template>

<script>
import FormGroup from "@/components/ui/form/FormGroup";
import FormField from "@/components/ui/form/FormField";

export default {
  name: "AppForm",

  mounted() {
    this.values = {...this.data }
  },

  data() {
    return {
      previewImages : {}
    }
  },

  props: {
    fields: {type:Array, default:() => []},
    onSubmit: {type: Function, default: () => {}},
    data: {type: Object, default: () => {}},
    meta: {type: Object, default: () => {}},
    features: {type: Array, default: () => []},
  },
  components: {FormField, FormGroup, },

  methods: {
    async doSubmit(e) {
      e.preventDefault()
      if (this.onSubmit) this.onSubmit(this.data)
    },

    setImage(field, img) {
      this.$set(this.previewImages,field.prop,img);
    },

    previewImgSrc(field) {
      return this.previewImages[field.prop] ? `${URL.createObjectURL(this.previewImages[field.prop])}` : ''
    },

    hasPreview(field) {
      return !!this.previewImages[field.prop]
    },

    update(prop, data) {
      let p = prop.split(/\./g), c
      c = this.data[p[0]];
      if (p.length > 1) {
        if (!c) c = {}
        c[p[1]] = data;
      }
      else c = data;
      this.$set(this.data, p[0], c);

      this.$emit('update', this.data, this.previewImages);
    },

    val(prop, data) {
      let v, p = prop.split(/\./g), res = data;
      while (p.length > 0) {
        v = p.shift();
        if (typeof res[v] !== "undefined" && res[v] !== null)
          res = res[v]
        else return;
      }
      return res;
    },

    groupTitle(groupName) {
      for (let field of this.fields) {
        if (field.group === groupName && field.groupTitle) return field.groupTitle;
      }
    },

    groupClass(group) {
      let cls = '';
      if (group) cls += ' '+group;
      return cls.trim()
    }
  },

  computed : {
    groups() {
      const groups = {}, fields = {}
      let hasGroups = false
      for (let field of this.fields) {
        fields[field.prop] = field

        if (field.condition) {
          if (!field.condition(this.data)) continue;
        }
        if (field.feature) {
          if (this.features.indexOf(field.feature) === -1) continue;
        }

        if (field.group) {
          hasGroups = true
          if (!groups[field.group]) groups[field.group] = []
          groups[field.group].push(field);
        }
      }
      if (hasGroups) {
        return groups;
      }
      else {
        return {'a':fields};
      }
    }
  },

}
</script>
