export default {

    allowDelete: true,
    name: "DebitCharge",
    path: "debit-charges",
    plural: "DebitCharges",
    menu: "DebitCharges",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],

    endpoints : {
        list : { path : '/admin/debit-charge/list', method: "get", token: true, cs: true },
        get : { path : '/admin/debit-charge/get/:id', method: "get", token: true },
        add : { path : '/admin/debit-charge/add', method: "post", body: true, token: true },
        update : { path : '/admin/debit-charge/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/debit-charge/delete/:id', method: "get", params: true, token: true },
    },

}
