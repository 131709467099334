<template>
  <Content :wraps="true" :col="true">
    <DraggableList :title="title" :items="listItems" :fields="listFields" @delete="onDelete" @sort="onSort" @edit="onEdit" />

    <Txt :title="true" :text="editedItem?'Edit Item':'Add Item'" />
    <InlineForm :fields="formFields" :item="editedItem" :meta="meta" :cancel="onCancel" :show-cancel="!!editedItem" :submit-label="editedItem?'Edit Item':'Add Item'" :on-submit="onUpdate" />
  </Content>
</template>

<script>
import Icons from "@/lib/Icons";
import Content from "@/components/ui/layout/Content";
import DraggableList from "@/components/ui/lists/DraggableList";
import Txt from "@/components/ui/utils/Txt";
import InlineForm from "@/components/ui/form/InlineForm";
export default {
  name: "EditableDraggableList",
  components: {InlineForm, Txt, DraggableList, Content, },
  props : {
    title: String,
    titleProp: String,
    fields : Array,
    items: Array,
    formFields: Array,
    meta: Object,
    onChange: Function
  },
  data() {
    return {
      deleteIcon: Icons.x,
      editedItem: null,
      editedItemIndex: null,
      listItems: []
    }
  },
  mounted() {
    this.listItems = this.items ? this.items.sort((a, b) => a.sort < b.sort?-1:1) : []
  },

  computed : {
    listFields() {
      const fields = [...this.fields];
      fields.push({
        actions: [
          { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete", confirmation: (ing) => "Are you sre you want to delete '"+ing[this.titleProp]+"'?" },
          { mood: "primary", icon: Icons.pencil, action: "edit", tooltip: "Edit" }
        ]
      })
      return fields;
    }
  },

  methods : {
    onSort(oldIndex, newIndex) {
      const items = [...this.listItems], item = items.splice(oldIndex, 1);
      items.splice(newIndex, 0, item[0]);

      for (let i = 0; i < items.length; i++) {
        items[i].sort = i+1;
      }
      this.listItems = items
    },
    onEdit(item, index) {
      this.editedItem = {...item};
      this.editedItemIndex = index;
    },
    onUpdate(item) {
      if (this.editedItemIndex === null) this.$set(this.listItems, this.listItems.length, {...item, sort: this.listItems.length+1 })
      else this.$set(this.listItems, this.editedItemIndex, item)
      this.onChange && this.onChange(this.listItems)
      this.onCancel()
    },
    onDelete(item) {
      this.listItems = this.listItems.filter(i => i !== item)
      this.onChange && this.onChange(this.listItems)
      setTimeout(() => console.log(this.listItems), 200)
    },
    onCancel() {
      this.editedItem = this.editedItemIndex = null
    }
  }
}
</script>
