const sleep = (ms) => new Promise(res => setTimeout(() => res(), ms))

export function delayedIf(condition, callback, timeout = 1000) {
    return new Promise((success) => {
        if (condition) {
            setTimeout(async () => {
                await callback()
                success()
            }, timeout)
        }
        else {
            callback()
            success()
        }

    })
}

export function delayedIfLoading(condition, callback, loadingCallback, testRetry = null, retryPenalty = null, timeout = 1000, retry = 5, firstRetry = 150) {
    loadingCallback(true)
    const cb = async function() {
        if (testRetry && retryPenalty) {
            if (!testRetry()) {
                let first = true;
                while (retry > 0) {
                    retry--;
                    await sleep(first?firstRetry:timeout)
                    if (testRetry()) break;
                    first = false;
                }
                if (!testRetry()) {
                    loadingCallback(false)
                    return retryPenalty()
                }
            }
        }

        const res = await (callback instanceof Promise?callback:callback())
        console.log('delayedIfLoading: callback returned', res)
        if (typeof res === 'function') {
        console.log('delayedIfLoading: callback returned a function, calling it')
            res()
        }
        await loadingCallback(false)

    }
    return delayedIf(condition, cb, timeout)
}

export function actionIf(conditionAndPromisePairs) {
    const actions = []
    if (!(conditionAndPromisePairs[0] instanceof Array)) conditionAndPromisePairs = [conditionAndPromisePairs]
    return function() {
        for (let p of conditionAndPromisePairs) {
            if (p[0]) {
                if (!(p[1] instanceof Promise)) p[1] = p[1]()
                actions.push(p[1])
            }
        }
        return Promise.all(actions)
    }
}
