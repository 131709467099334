import Fe from "@/lib/ui/FormElement";

export default {

    allowDelete: true,
    name: "Video",
    path: "videos",
    plural: "Videos",
    menu: "Video",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],
    titleProp: "title",

    endpoints : {
        list : { path : '/admin/video/list', method: "get", token: true },
        get : { path : '/admin/video/get/:id', method: "get", token: true },
        add : { path : '/admin/video/add', method: "post", body: true, token: true },
        update : { path : '/admin/video/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/video/delete/:id', method: "get", params: true, token: true },
    },

    fields: [
        {label: "#", prop: "id", sortable: true, width: "60px" },
        {label: "Title", prop: "title", primary: true, sortable: true},
        {label: "Duration (minutes)", prop: "duration", sortable: true},
    ],

    form : [
        Fe.Text("title", "Title"),
        Fe.Textarea("description", "Description"),
        Fe.Number("duration", "Duration (minutes)"),
        Fe.Text("path", "Path",null, "URL or ID of the video"),
        Fe.Text("alt_path", "Alternative Media Path",null, "URL or ID of the video"),
        Fe.Image("thumbnail", "Thumbnail",null, "Thumbnail image"),
    ],

}
