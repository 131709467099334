<template>
  <Container :full="true">
    <ConfirmModal v-if="serviceForDeletion" item-type="Service" :item-name="serviceForDeletion.name" :confirm="confirmDeleteService" :cancel="cancelDeleteService" />
    <FilteredItemList title="Services" :fields="fields" :items="allServices" :on-click="goto" :search="true" @delete="askToDeleteService" :actions="{'Add Service': addNewService }" />
  </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Container from "@/components/ui/layout/Container";
import Icons from "@/lib/Icons";

export default {
  name: "Services",
  components: {Container, FilteredItemList, ConfirmModal },
  data() {
    return {
      serviceForDeletion: null,
      search : "",
      fields : [
        {label: "Name", prop: "name", primary: true},
        {label: "Active", prop: "is_active", type: "boolean" },
        {label: "URL", prop: "url", above: 3},

        {label: "", prop: "", width: "10%", actions: [
            { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" }
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allServices.length, this.getServices]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/login'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allServices']),
  },

  methods: {


    ...mapActions(['getServices','setLoading','deleteService']),
    goto(service) {
      this.$router.push('/services/'+service.id)
    },
    addNewService() {
      this.$router.push('/services/new')
    },
    askToDeleteService(service) {
      this.serviceForDeletion = service;
    },
    cancelDeleteService() { this.serviceForDeletion = null },
    confirmDeleteService() {
      this.deleteService(this.serviceForDeletion.id)
      this.serviceForDeletion = null
    }
  },


}
</script>
