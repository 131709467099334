<template>
  <Panel :title="t('Service Validation')">
    <Box v-if="!validated && !hasDetails" :title="t('Details Incomplete')" title-mood="danger">
      <Txt>{{t('Please enter the service URL, login and password to test validation')}}</Txt>
    </Box>
    <Box v-if="!validated && hasDetails" :title="t('Validate server')">
      <Flexy :between="true" :centered="true">
        <Txt>{{ t('Validate the server and retrieve its endpoints') }}</Txt>
        <Button @click="validateService" :mood="validating?'primary':''">{{t(validating?'Validating...':'Validate Service')}}</Button>
      </Flexy>
    </Box>
    <Box v-if="validated" :title="t('Service Validated')" title-mood="success">
      <Txt>{{t("The service was successfully validated.")}}</Txt>
    </Box>
    <Box v-if="invalid" :title="t('Service Validation Failed')" title-mood="danger">
      <Flexy :between="true" :full="1">
        <Txt>{{t("The service validation has failed. Check your details!")}}</Txt>
        <Txt mood="danger">{{t("Error: {0}",t(invalid))}}</Txt>
      </Flexy>
    </Box>
  </Panel>
</template>

<script>
import Panel from "@/components/ui/layout/Panel";
import t from "@/lib/Locale";
import Box from "@/components/ui/layout/Box";
import Txt from "@/components/ui/utils/Txt";
import Flexy from "@/components/ui/layout/Flexy";
import Button from "@/components/ui/form/Button";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
export default {
  name: "ServiceValidation",
  props: {
    service : Object,
    onValidate : Function,
  },
  components: {Button, Flexy, Txt, Box, Panel},
  data() {
    return {
      invalid: false,
      validating: false
    }
  },
  computed : {
    hasDetails() {
      return !!(this.service.url && this.service.url.match(/http(s)?:\/\//) && this.service.username && this.service.password)
    },
    validated() {
      return (this.service.token && this.service.is_active)
    }
  },
  methods: {
    t,
    async validateService() {
      if (this.validating) return
      this.validating = true;
      this.invalid = false;
      const {url, username, password} = this.service
      const res = await ApiRequest(ApiCall(Api.ServiceValidate, {url, username, password}))
      if (res.token) {
        if (this.onValidate) this.onValidate(res.token, res.endpoints, res.modules)
      }
      else { this.invalid = res.error }
      this.validating = false;

    }
  }
}
</script>
