<template>
  <InstanceDash :type="type" :instance="instance" :fields="fields" :meta="{integrationTypes}"
                :on-update="onUpdate" :on-submit="onSubmit">
    <template slot="main">
      <Panel title="Credentials" :small="true" v-if="hasCredentials">
        <Panel :title="instance.integration_type">
          <Txt :text="integrationDetails" />
        </Panel>
        <Txt :bold="true" text="Enter the required integration credentials below."></Txt>
        <Flexy :even="true" :full="2">
          <Box v-for="(field, ii) in instance.credentials" :key="ii" :col="true" :title="ii" :title-mood="hasCredential(ii)?'success':'danger'">
            <Input v-model="instance.credentials[ii]" :placeholder="ii" />
          </Box>
        </Flexy>
      </Panel>
      <Panel title="Hooks" v-if="hasHooks">
        <Box v-for="(hook, ii) in hasHooks" :key="ii" :small="true">
          <Flexy :col="true">
            <Checkbox :label="hook.name" :checked="!!hasHook(hook.path)" @change="(a,input) => setHook(input, hook.path)" />
            <Flexy v-if="hasHook(hook.path)" :wraps="true">
              <Box v-for="(param, jj) in hook.params" :key="jj" :col="true" :title="param" :title-mood="hasParam(hook.path, param)?'success':'danger'" :small="true">
                <Input v-model="instance.hooks[getHookIndex(hook.path)].params[param]" :placeholder="param" />
              </Box>
            </Flexy>
          </Flexy>
        </Box>
      </Panel>
    </template>
  </InstanceDash>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import Fe from "@/lib/ui/FormElement";
import Panel from "@/components/ui/layout/Panel";
import Input from "@/components/ui/form/Input";
import Flexy from "@/components/ui/layout/Flexy";
import Txt from "@/components/ui/utils/Txt";
import Box from "@/components/ui/layout/Box";
import Checkbox from "@/components/ui/form/Checkbox";

export default {

  name: "Integration",
  mixins: [InstanceView],
  components: {Checkbox, Box, Txt, Flexy, Input, Panel, InstanceDash,  },
  data() {
    return {

      type: "Integration",
      path: "integrations",
      fields : [
          Fe.Select("integration_type", "Integration Type", "primary", "Select Integration Type",'name','integrationTypes' ),
          Fe.Text("name", "Name", "primary"),
          Fe.Checkbox("is_active", "Active", "primary"),
          Fe.Textarea("comments", "Comments", "primary 4"),
      ],
      Create : this.createIntegration,
      Update : this.updateIntegration,
      defaultData: {
        name: "",
        hooks: []
      },
    }
  },

  async mounted() {
    await this.initInstance('allIntegrations',[
        [!this.allAvailableIntegrations.length, this.getAvailableIntegrations],
        [!this.allIntegrations.length, this.getIntegrations],
    ])
  },

  computed: {
    ...mapGetters(['allIntegrations','allAvailableIntegrations']),
    hasCredentials() {
      try {
        return this.instance.integration_type && this.instance.credentials
      } catch (e) {
        return false
      }
    },
    hasHooks() {
      if (!this.instance || !this.instance.integration_type) return false;
      const integration = this.allAvailableIntegrations.find(it => it.path === this.instance.integration_type)
      return integration && integration.hooks
    },
    integrationTypes() { return this.allAvailableIntegrations.map(it => ({id: it.path, name: it.name }))},
    canResetCredentials() {
      if (!this.instance) return false
      if (!this.instance.credentials) return true
      let ok = true;
      for (let c in this.instance.credentials) {
        if (this.instance.credentials[c]) { ok = false; break}
      }
      return ok
    },
    integrationDetails() {
      const integration = this.allAvailableIntegrations.find(it => it.path === this.instance.integration_type)
      return integration?integration.description:''
    }
  },

  methods: {
    ...mapActions(['getIntegrations', 'getAvailableIntegrations', 'updateIntegration', 'createIntegration']),
    resetCredentials(integrationType) {
      const integration = this.allAvailableIntegrations.find(it => it.path === integrationType)
      if (integration) {
        const credentials = {}
        for (let c of integration.fields) credentials[c] = ""
        this.instance.credentials = credentials
      }
    },
    hasCredential(field) {
      return this.instance && this.instance.credentials && this.instance.credentials[field]
    },

    getHookIndex(path) {
      return this.instance.hooks.findIndex(h => h.path === path)
    },

    hasHook(path) {
      if (!this.instance || !this.instance.integration_type) return false;
      return this.instance.hooks.find(it => it.path === path && it.is_active)
    },

    hasParam(path, param) {
      let hook = this.instance.hooks.find(it => it.path === path)
      return hook && hook.params && hook.params[param]
    },

    setHook(input, path) {
      let hook = this.instance.hooks.findIndex(it => it.path === path)
      if (input.checked) {
        if (hook < 0) {
          hook = { path, is_active : true, params : {} }
          this.instance.hooks.push(hook)
        }
        else {
          this.$set(this.instance.hooks, hook, {...this.instance.hooks[hook], is_active: true})
        }
      }
      else {
        this.$set(this.instance.hooks, hook, {...this.instance.hooks[hook], is_active: false})
      }
    }

  },

  watch : {
    allAvailableIntegrations() {
      if (this.canResetCredentials) this.resetCredentials(this.instance.integration_type)
    },
    'instance.integration_type' : function(){
      console.log(this.instance.integration_type,this.canResetCredentials)
      if (this.canResetCredentials) this.resetCredentials(this.instance.integration_type)
    }
  }
}
</script>
