<template>
  <Panel :title="t('Service Endpoints')">
    <ItemList :fields="fields" :items="endpoints" @edit="onEdit" @test="onTest" :full="false" />
    <EndpointForm v-if="editing && !testing" :endpoint="editing" @update="onSave" />
    <EndpointTester v-if="testing" :endpoint="testing" :service-id="serviceId" />
  </Panel>
</template>

<script>
import t from "@/lib/Locale";
import Panel from "@/components/ui/layout/Panel";
import ItemList from "@/components/ui/lists/ItemList";
import Icons from "@/lib/Icons";
import EndpointForm from "@/components/forms/EndpointForm";
import EndpointTester from "@/components/forms/EndpointTester";
export default {
  name: "ServiceEndpointsForm",
  props: {
    endpoints: Array,
    serviceId: Number,
  },
  components: {EndpointTester, EndpointForm, ItemList, Panel},
  data() {
    return {
      editing: null,
      editingIndex: null,
      testing: null,
      fields: [
        {label:"Name", prop: 'name', primary: true},
        {label:"Path", prop: 'path', above: 2},
        {label:"Description", prop: 'description', above: 3},
        {label: "", prop: "", width: "10%", actions: [
          { mood: "primary", icon: Icons.pencil, action: "edit", tooltip: "Edit" },
          { mood: "success", icon: Icons.play, action: "test", tooltip: "Test" }
        ]}
      ]
    }
  },
  computed : {
  },
  methods: {
    t,
    onEdit(endpoint,index) {
      if (this.testing && !this.editing) {
        this.testing = this.editingIndex = null;
      }
      if (this.editingIndex === index) {
        this.editing = this.editingIndex = null;
      }
      else {
        this.editing = endpoint;
        this.editingIndex = index;
      }
    },

    onTest(endpoint,index) {
      if (this.editing && !this.testing) {
        this.editing = this.editingIndex = null;
      }
      if (this.editingIndex === index) {
        this.testing = this.editingIndex = null;
      }
      else {
        this.testing = endpoint;
        this.editingIndex = index;
      }
    },

    onSave(newData) {
      this.$set(this.endpoints, this.editingIndex, {...this.editing, ...newData, is_active: true})
      this.editing = this.editingIndex = null;
    }
  }
}
</script>
