<template>
  <vSelect :placeholder="t(placeholder)" :label="labelKey" :reduce="reduce" :value="value" :clearable="clearable" :multiple="multiple" :options="options" @input="onInput" />
</template>

<script>
import vSelect from "vue-select";
import t from '@/lib/Locale';

export default {
  name: "Select",
  components: {vSelect},
  props: {
    placeholder : {type: String, default: ''},
    labelKey : {type: String, default: 'label'},
    propKey : {type: String, default: 'id'},
    value : {type: [String, Number, Object, Array, Boolean] },
    flat: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    options : {type: Array,}
  },
  data() {
    return {
      vSelect
    }
  },
  methods : {
    t,
    reduce(it) {
      return !this.flat ? it[this.propKey] : it
    },
    onInput(e) {
      this.$emit('input', e)
    }
  }
}
</script>
