<template>
  <Panel title="Edit Subscriber Meta">
      <Flexy :between="true" :full="1">
        <Textarea v-model="value" placeholder="Description (optional)" :mood="isValidJson?'':'danger'" />
      </Flexy>
      <Flexy :even="true">
        <Button :disabled="!isValid" mood="primary" @click="setMeta" :text="isValidJson?'Set Subscriber Meta':'Fix invalid JSON'" />
        <Button @click="onCancel" text="Cancel" />
      </Flexy>
  </Panel>
</template>

<script>
import Panel from "@/components/ui/layout/Panel";
import Flexy from "@/components/ui/layout/Flexy";
import Textarea from "@/components/ui/form/Textarea";
import Button from "@/components/ui/form/Button";
export default {
  name: "SubscriberMetaForm",
  props: {
    metaValue: {type:[Object,Array,Number,String,]},
  },
  components: {Button, Textarea, Flexy, Panel},
  data() {
    return {
      value: "",
    }
  },
  mounted() {
    if (this.metaValue) this.value = this.isJson ? JSON.stringify(this.metaValue) : this.metaValue.toString()
  },
  computed : {
    isJson() {
      return typeof this.metaValue === 'object'
    },
    isValidJson() {
      if (!this.isJson) return true
      try {
        JSON.parse(this.value)
      } catch (e) {
        return false
      }
      return true
    },
    isValid() {
      return this.isValidJson;
    }
  },
  methods: {
    setMeta() {
      if (this.isValid)
        this.$emit('update', this.isJson ? JSON.parse(this.value) : this.value)
    },
    onCancel() {
      this.$emit('cancel')
    }
  },
  watch : {
    metaValue() {
      this.value = this.isJson ? JSON.stringify(this.metaValue) : this.metaValue.toString()
    }
  }
}
</script>
