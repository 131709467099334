<template>
  <Panel title="Edit Service Endpoint">
      <Flexy :between="true" :full="1">
        <Flexy>
          <Txt text="Endpoint Name:" />
          <Txt :bold="true">{{endpoint.name}}</Txt>
        </Flexy>
        <Flexy>
          <Txt text="Endpoint Path:" />
          <Txt :bold="true">{{endpoint.path}}</Txt>
        </Flexy>
        <Flexy v-if="endpoint.prop">
          <Txt text="Data Prop:" />
          <Txt :bold="true">{{endpoint.prop}}</Txt>
        </Flexy>
      </Flexy>
      <Textarea v-model="description" placeholder="Description (optional)" />
      <Button :disabled="!isValid" @click="setEndpoint" text="Set Endpoint Configuration" />
  </Panel>
</template>

<script>
import Panel from "@/components/ui/layout/Panel";
import Flexy from "@/components/ui/layout/Flexy";
import Txt from "@/components/ui/utils/Txt";
import Textarea from "@/components/ui/form/Textarea";
import Button from "@/components/ui/form/Button";
export default {
  name: "EndpointForm",
  props: {
    endpoint: Object
  },
  components: {Button, Textarea, Txt, Flexy, Panel},
  data() {
    return {
      methodOptions : [{id: 'get', label: 'get'}, {id: 'post', label: 'post'}, ],
      name: "",
      path: "",
      method: null,
      description: "",
    }
  },
  mounted() {
    this.unpack()
  },
  computed : {
    isValid() {
      return true;
    }
  },
  methods: {
    setEndpoint() {
      if (this.isValid)
        this.$emit('update', { description: this.description })
    },
    unpack() {
      this.name = this.endpoint.name
      this.path = this.endpoint.path
      this.method = this.endpoint.method
      this.description = this.endpoint.description
    }
  },
  watch : {
    endpoint() {
      this.unpack()
    }
  }
}
</script>
