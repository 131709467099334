import Fe from "@/lib/ui/FormElement";

export default {

    allowDelete: true,
    name: "UserRole",
    path: "user-roles",
    plural: "UserRoles",
    menu: "UserRoles",
    permissions: ['ADMIN'],

    endpoints : {
        list : { path : '/admin/user-role/list', method: "get", token: true },
        get : { path : '/admin/user-role/get/:id', method: "get", token: true },
        add : { path : '/admin/user-role/add', method: "post", body: true, token: true },
        update : { path : '/admin/user-role/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/user-role/delete/:id', method: "get", params: true, token: true },
    },

    meta : {
        permissions: [
            {id:'BRAND_MANAGER', label: "Brand Manager"},
            {id:'BRAND_EDIT_CONTENT', label: "Brand Content Editor"},
            {id:'BRAND_ACCOUNTING', label: "Brand Accounting"},
            {id:'BRAND_CONSUMER', label: "Brand Consumer"}
        ]
    },

    fields: [
        {label: "Name", prop: "name", primary: true},
    ],

    form : [
        Fe.Text("name", "Name", "main"),
    ],

    additionalControls : () => [
        {type: "edit-list", title: "Role Permissions", position: "main", items: "permissions", titleProp: "title", fields: [
                { prop: "permission", },
            ],
            formFields: () => [
                Fe.Select("permission", "Permission",null, "Select permission",'label','permissions','id'),
            ]
        }
    ],


}
