<template>
  <div class="json-browser">
    <div v-if="renderNode">
      <JsonBrowserNode :value="value" :collapsed="!expanded" @collapse="onCollapse" :base="true" :key="renderIndex" />
    </div>
    <Txt v-if="!renderNode" :bold="true">{{value}}</Txt>
  </div>
</template>

<script>

import JsonBrowserNode from "@/components/ui/lists/JsonBrowserNode";
import Txt from "@/components/ui/utils/Txt";
export default {
  name: "JsonBrowser",
  components: {Txt, JsonBrowserNode},
  props: {
    value: {type: [Object, Array, Number, String]}
  },
  data() {
    return {
      expanded : false,
      renderIndex: 0,
    }
  },
  computed : {
    renderNode() {
      return typeof this.value === 'object'
    }
  },
  methods : {
    onCollapse() {
      this.expanded = !this.expanded
    }
  },
  watch : {
    value() {
      this.renderIndex++
    }
  }

}
</script>
