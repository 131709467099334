<template>
  <Flexy :full="2" :col="true">
    <Flexy :col="true">
      <Flexy :wraps="true">
        <Flexy v-for="(action, ii) in actions" :key="ii">
          <Button @click="() => setAction(ii)" :small="true">{{action.name}}</Button>
        </Flexy>
      </Flexy>
    </Flexy>
    <Box v-if="openAction !== null">
      <Flexy :col="true" :flex="1">
        <Txt :bold="true">{{openAction.name}}</Txt>
        <Flexy :even="true" :wraps="true">
          <Input v-for="(param, p) in openAction.params" :date="param.name.indexOf('date')>-1" :key="p" size="med" :mood="param.is_mandatory?'warning':''" v-model="params[param.name]" :placeholder="param.name" />
        </Flexy>
        <Flexy :even="true">
          <Button mood="primary" text="Run Action" :disabled="!isValid" @click="onRun" />
          <Button text="Cancel" @click="cancelSelection" />
        </Flexy>
      </Flexy>
      <Flexy :col="true" v-if="result">
        <Textarea :disabled="true" :rows="5" :value="resultText" />
      </Flexy>
    </Box>
  </Flexy>
</template>

<script>
import Flexy from "@/components/ui/layout/Flexy";
import {mapActions, mapGetters} from "vuex";
import Input from "@/components/ui/form/Input";
import Button from "@/components/ui/form/Button";
import Box from "@/components/ui/layout/Box";
import Txt from "@/components/ui/utils/Txt";
import Textarea from "@/components/ui/form/Textarea";

export default {
  name: "ActionsPanel",
  components: {Textarea, Txt, Button, Box, Input, Flexy},
  props : {
    actions : { type: Array, default: null },
    result : { type: [Array, Object, null, Boolean], default: null },
  },
  data() {
    return {
      openAction : null,
      params : {},
      showResult: false,
    }
  },

  async mounted() {

  },

  computed : {
    ...mapGetters([]),
    mandatoryParamsEntered() {
      const action = this.openAction;
      if (!action.params.length) return true;
      for (let p of action.params)
        if (p.is_mandatory && !this.params[p.name]) return false
      return true
    },
    isValid() {
      return this.mandatoryParamsEntered;
    },
    resultText() {
      return JSON.stringify(this.result)
    }
  },

  methods: {
    ...mapActions([]),
    setAction(actionIndex) {
      this.openAction = this.actions[actionIndex]
      this.params = {}
      this.showResult = false
    },
    cancelSelection() {
      this.openAction = null
    },
    onRun() {
      this.$emit('run', this.openAction.path, this.params, this.openAction.name)
      this.showResult = true
      this.params = {}
    }
  },
  watch : {
  }
}
</script>
