<template>
  <div id="app" :class="appClass">
    <Navbar :links="navbarLinks" :settings-links="settingsLinks" :key="'nav_'+renderKey" />
    <router-view @force-update="onForceUpdate" :key="'router_'+renderKey" />
  </div>
</template>

<script>

import Navbar from "@/components/ui/navigation/Navbar";
import {mapActions, mapGetters} from "vuex";
import 'vue-select/dist/vue-select.css';
import './scss/style.scss';
import NavbarLinks from "@/config/NavMenuLinks";

export default {
  name: 'App',
  components: {Navbar},
  data() {
    return {
      renderKey: 0,
      navbarLinks : NavbarLinks,
      settingsLinks : [
        {to: '/my-settings', label: 'My Settings'},
        {type: 'toggle', label: 'Night Mode', toggle: this.toggleTheme, checked: () => this.appTheme === 'dark'},
        {type: 'toggle', label: 'Admin Menu', toggle: this.toggleAdminMenu, checked: () => this.appMenuType === 'admin', permission: 'ADMIN' },
        {type: 'action', label: 'Log Out',  action: this.logOut },
      ]
    }
  },
  computed : {
    ...mapGetters(['appDirection','appTheme','appMenuType']),
    appClass() {
      if (this.appDirection === 'rtl') return 'rtl'
      return ''
    }
  },
  methods : {
    ...mapActions(['checkLogin','setLocale','loadTheme','setTheme', 'loadLocale','loadHiddenMenuItems','loadMenuType','logout','toggleMenuType']),
    toggleTheme() {
      if (this.appTheme === 'dark') this.setTheme('')
      else this.setTheme('dark')
    },
    toggleAdminMenu() {
      this.toggleMenuType('admin')
    },
    logOut() {
      this.logout()
      if (this.$router.currentRoute.path !== '/login') this.$router.push('/login')
    },
    onForceUpdate() {
      this.renderKey++
    }
  },
  mounted() {
    // this.setLocale('he')
    this.loadTheme()
    this.loadLocale()
    this.loadMenuType()
    this.loadHiddenMenuItems()
    this.checkLogin()
  }
}
</script>
