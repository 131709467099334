import Fe from "@/lib/ui/FormElement";

export default {

    allowDelete: true,
    name: "Mailer",
    path: "mailers",
    plural: "Mailers",
    menu: "Mailer",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],
    metaModules : ['mailer-templates'],
    titleProp: "name",

    endpoints : {
        list : { path : '/admin/mailer/list', method: "get", token: true },
        get : { path : '/admin/mailer/get/:id', method: "get", token: true },
        add : { path : '/admin/mailer/add', method: "post", body: true, token: true },
        update : { path : '/admin/mailer/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/mailer/delete/:id', method: "get", params: true, token: true },
    },

    fields: [
        {label: "#", prop: "id", sortable: true, width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "Subject", prop: "subject", sortable: true},
    ],

    form : [
        Fe.Text("name", "Name", "small x"),
        Fe.Text("subject", "Subject", "small x"),
        Fe.Select("template_id", "Template", "small 1", "Select Template", "name", "mailer-templates"),
        Fe.Textarea("content", "Content", "main"),
    ],

}
