import t from "@/lib/Locale";
import {addMonths} from "@/lib/TimeUtils";
import Fe from "@/lib/ui/FormElement";

const utils = {
    subscriberPlan(planId,sub,meta) {
        if (sub._plan) return sub._plan
        if (planId) {
            const plan = meta.plans.find(it => it.id === planId*1)
            if (plan) sub._plan = plan.name
            return plan ? plan.name : "-"
        }
        return "-"
    },

    canceled(x, sub) {
        if (sub.cancellation_date) {
            const d = new Date(sub.cancellation_date), n = new Date()
            const days = Math.round((n.getTime() - d.getTime()) / 1000 / 60 / 60 / 24);
            return t('{0} days', days)
        }
        return ''
    },

    daysLeft(x, sub) {
        let res
        if(sub.expiration_date) {
            res = (new Date(sub.expiration_date).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24;
            return res > 0 ? t('{0} days', Math.round(res)) : ''
        }
        else if (sub.inactive_until_date) {
            res = (new Date(sub.inactive_until_date).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24;
            return res > 0 ? t('{0} days', Math.round(res)) : ''
        }

        if (sub.cancellation_date) {
            let d = new Date(sub.subscription_date), cd = new Date(sub.cancellation_date), now = new Date()
            while (d < cd) d = addMonths(d, sub.subscription_charge_interval*1)
            res = (d.getTime() - now.getTime()) / 1000 / 60 / 60 / 24;
            return res > 0 ? t('{0} days', Math.round(res)) : ''
        }
        return ''
    },

    statusBool(x, sub) { return sub.subscription_renew },

    statusMood(x, sub) {
        if (utils.isFrozen(x, sub)) return 'primary'
        if (utils.isSuspended(x, sub)) return 'saving'
        if (sub.subscription_renew) return 'success'
        if (utils.isNotExpired(x, sub)) return 'warning'
        return 'danger'
    },

    isFrozen(x, sub) {
        return sub.inactive_until_date && new Date(sub.inactive_until_date) > new Date()
    },

    isSuspended(x,sub) {
        return !!sub.suspended
    },

    isNotExpired(x, sub) {
        if (sub.subscription_renew) return true
        if (sub.expiration_date) return new Date(sub.expiration_date) > new Date()

        let d = new Date(sub.subscription_date), cd = new Date(sub.cancellation_date)
        while (d < cd) d = addMonths(d, sub.subscription_charge_interval*1)
        if (d < new Date()) return false
        return true
    },

    parseDate(d) {
        if (!d) return '-'
        d = new Date(d);
        return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    },

    subTransactions(d, sub,meta) {
        if (sub._transactions) return sub._transactions
        let t = meta.transactions && meta.transactions.length ? meta.transactions.filter(it => it.subscriber_id === sub.id) : []
        if (t) sub._transactions = t.length
        return t.length||'0'
    },
}

export default {

    name: "Pending Lead",
    path: "pending",
    plural: "Pending Leads",
    menu: "Pending Leads",
    permissions: ['BRAND_MANAGER','BRAND_ACCOUNTING'],
    titleProp: "email",
    allowDelete: false,

    meta : {
        genderOptions : [
            {id:'m',name:'Male'}, {id:'f',name:'Female'}, {id:'o',name:'Other'},
        ],
    },
    metaModules : ['transactions','plans'],

    endpoints : {
        list : { path : '/admin/subscriber/list-inactive', method: "get", token: true, cs: true },
        get : { path : '/admin/subscriber/get/:id', method: "get", token: true },
        add : { path : '/admin/subscriber/add', method: "post", body: true, token: true },
        update : { path : '/admin/subscriber/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/subscriber/delete/:id', method: "get", params: true, token: true },
    },

    filters: {
        'status': 'Status',
        'subscription_plan_id': 'Plan',
        'expired': 'Expired',
    },
    filterData : {
    },

    filterFormat : {
        'status' : function(v) { return v ? 'Active':'Canceled' },
        'expired' : function(v) { return v ? 'Valid':'Expired' },
    },

    fields: () => [
        {label: "ID", prop: "id", above: 1, sortable: true},
        {label: "Name", prop:"full_name", format: (d, o) => o.first_name+' '+o.last_name, below: 3, sortable: true},
        {label: "First Name", prop: "first_name", above: 2, sortable: true},
        {label: "Last Name", prop: "last_name", above: 2, sortable: true},
        {label: "E-mail", prop: "email", sortable: true},
        {label: "Gender", prop: "gender", above: 2, sortable: true},
        {label: "Reg Date", prop: "registration_date", format: (d) => utils.parseDate(d), above: 2, sortable: true},
        {label: "Cancel Date", prop: "cancellation_date", format: (d) => utils.parseDate(d), above: 3, sortable: true},
    ],

    form : [
        Fe.Text("first_name", "First Name", "primary"),
        Fe.Text("last_name", "First Name", "primary"),
        Fe.Text("email", "E-Mail", "small 2"),
        Fe.Text("phone", "Phone", "small 2"),
        Fe.Select("gender", "Gender", "small 2", "Select Gender",'name','genderOptions' ),
        Fe.Text("recovery_code", "Recovery Code", "small 2"),
        Fe.Select("subscription_plan_id", "Plan", "small 3", "Select Plan",'name','plans' ),
        Fe.Checkbox("subscription_active", "Subscription Active", "small 3"),
        Fe.Checkbox("subscription_renew", "Subscription Renewal", "small 3"),
        Fe.Checkbox("vip", "VIP Subscriber", "small 3"),
        Fe.Date("registration_date", "First Registration Date", "small 4"),
        Fe.Date("subscription_date", "Subscription Date", "small 4"),
        Fe.Number("subscription_charge_interval", "Charge Interval (months)", "small 4"),
        Fe.Date("cancellation_date", "Cancellation Date", "small 5"),
        Fe.Date("expiration_date", "Expiration Date", "small 5"),
        Fe.Date("inactive_until_date", "Inactive Until", "small 5"),
    ]

}
