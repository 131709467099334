import Fe from "@/lib/ui/FormElement";

export default {

    allowDelete: true,
    name: "Coupon",
    path: "coupons",
    plural: "Coupons",
    menu: "Coupons",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],

    meta : {
        discountTypes : [
            {id:'fixed',name:'Fixed Discount'}, {id:'percentage',name:'Percentage Discount'}, {id:'custom',name:'Custom Price'},
        ],
    },

    endpoints : {
        list : { path : '/admin/coupon/list', method: "get", token: true },
        get : { path : '/admin/coupon/get/:id', method: "get", token: true },
        add : { path : '/admin/coupon/add', method: "post", body: true, token: true },
        update : { path : '/admin/coupon/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/coupon/delete/:id', method: "get", params: true, token: true },
    },

    fields: [
        {label: "ID", prop: "id", above: 1},
        {label: "Name", prop: "name", above: 2},
        {label: "Coupon Code", prop: "code", primary: true },
        {label: "Type", prop: "discount_type"},
        {label: "Amount", prop: "amount", format: 'discount_amount'},
        {label: "Start Date", prop: "starts", format: 'datetime', above: 3},
        {label: "Expiry Date", prop: "expires", format: 'datetime', above: 2},
    ],

    form : [
        Fe.Text("name", "Name", "main"),
        Fe.Text("code", "Code", "primary"),
        Fe.Number("amount", "Amount", "primary"),
        Fe.Select("discount_type", "Discount Type", "primary", "Select Discount Type",'name','discountTypes' ),
        Fe.Date("starts", "Starts On", "small 2"),
        Fe.Date("expires", "Expires On", "small 2"),
    ]

}
