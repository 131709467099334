import Fe from "@/lib/ui/FormElement";

export default {

    allowDelete: true,
    name: "Course",
    path: "courses",
    plural: "Courses",
    menu: "Courses",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],

    meta : {
    },

    endpoints : {
        list : { path : '/admin/course/list', method: "get", token: true },
        get : { path : '/admin/course/get/:id', method: "get", token: true },
        add : { path : '/admin/course/add', method: "post", body: true, token: true },
        update : { path : '/admin/course/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/course/delete/:id', method: "get", params: true, token: true },
    },

    fields: [
        {label: "ID", prop: "id", above: 1},
        {label: "Name", prop: "name", above: 2},
        {label: "Open", prop: "is_open", type:"boolean"},
        {label: "Start Date", prop: "start_date", format: 'datetime', above: 3},
        {label: "Max. Capacity", prop: "max_capacity", above: 2},
    ],

    form : [
        Fe.Text("name", "Name", "primary"),
        Fe.Checkbox("is_open", "Open", "primary"),
        Fe.Text("description", "Description (non-public)", "primary"),
        Fe.Date("start_date", "Starts On", "primary 2"),
        Fe.Number("max_capacity", "Maximum Capacity", "primary 2"),
    ]

}
