import Home from "@/components/views/Home";
import Subscriber from "@/components/views/subscriber/Subscriber";
import MySettings from "@/components/views/settings/MySettings";
import Login from "@/components/views/Login";
import Services from "@/components/views/service/Services";
import Service from "@/components/views/service/Service";
import BrandSettings from "@/components/views/settings/BrandSettings";
import Actions from "@/components/views/action/Actions";
import Action from "@/components/views/action/Action";
import Integrations from "@/components/views/integration/Integrations";
import Integration from "@/components/views/integration/Integration";
import List from "@/components/views/module/List";
import Instance from "@/components/views/module/Instance";
import Report from "@/components/views/report/Report";

export default [

    ///////////////////////////////////////////////////////////////////////////
    ///  Admin Views
    ///////////////////////////////////////////////////////////////////////////

    // integrations
    { path: "/integrations", component: Integrations },
    { path: "/integrations/:id", component: Integration },

    // services
    { path: "/services", component: Services },
    { path: "/services/:id", component: Service },

    // actions
    { path: "/actions", component: Actions },
    { path: "/actions/:id", component: Action },

    ///////////////////////////////////////////////////////////////////////////
    ///  Manager Views
    ///////////////////////////////////////////////////////////////////////////

    // External module view
    { path: "/module/:external_id", component: List },
    { path: "/module/:external_id/:id", component: Instance },

    // settings
    { path: "/settings", component: BrandSettings },

    ///////////////////////////////////////////////////////////////////////////
    ///  Accounting Views
    ///////////////////////////////////////////////////////////////////////////

    // subscribers
    { path: "/subscribers/:id", component: Subscriber },

    // reports
    { path: "/reports/:id", component: Report },
    { path: "/reports", component: Report },

    ///////////////////////////////////////////////////////////////////////////
    ///  Content Editing Views
    ///////////////////////////////////////////////////////////////////////////

    // media lists

    ///////////////////////////////////////////////////////////////////////////
    ///  General Views
    ///////////////////////////////////////////////////////////////////////////
    // my settings
    { path: '/my-settings', component: MySettings },

    //home
    { path: "/", component: Home },
    { path: "/login", component: Login },

]
