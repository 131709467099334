let missing = [], debugTimeout;

import en from './locales/en'
import he from './locales/he'

const locales = { en, he }
let locale = locales.en;

function debugMissing() {
    clearTimeout(debugTimeout);
    if (locale !== locales.en) debugTimeout = setTimeout(() => {
        let d = {}
        for (let o in missing) d[missing[o]] = ""

        console.log("[Locale]", "Missing translations:',", JSON.stringify(d))
    }, 500);
}

export function setGlobalLocale(localeName) {
    locale = locales[localeName]
}

if (!String.prototype.format) {
    String.prototype.format = function(args = []) {
        return this.replace(/{(\d+)}/g, (match, number) => typeof args[number] != 'undefined' ? args[number] : match);
    };
}

function translate(string, ...args) {
    if (typeof string === "undefined" || string === null) return string
    let res = string.toString(), found = false;
    if (!string) return string

    if (locale) {
        if (locale[string]) {
            res = locale[string];
            found = true;
        }
    }
    if (!found) {
        if (missing.indexOf(string) < 0) {
            missing.push(string);

            debugMissing();
        }
    }
    if (args.length > 0) { res = res.format(args); }
    return res;
}

export default translate;
