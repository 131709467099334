<template>
  <span :class="className" @click="$emit('click')" v-if="icon">
    <InlineSvg :src="icon.icon" class="icon-svg" :style="iconStyle" />
  </span>
</template>

<script>

import InlineSvg from "vue-inline-svg";

export default {
  name: "Icon",
  props: ['icon','width','height','rotate','link','loading','mood','interactive','cursor','small','tiny'],
  components : { InlineSvg },
  computed: {
    className() {
      let clsName = 'icon';
      if (this.link) clsName += ' link'
      if (this.loading) clsName += ' loading'
      if (this.small) clsName += ' small'
      if (this.tiny) clsName += ' tiny'
      if (this.interactive) clsName += ' interactive'
      switch (this.mood) {
        case 'primary': clsName += ' primary'; break;
        case 'success': clsName += ' success'; break;
        case 'warning': clsName += ' warning'; break;
        case 'danger': clsName += ' danger'; break;
        case 'saving': clsName += ' saving'; break;
      }
      return clsName
    },
    iconStyle() {
      const s = {}
      if (this.width) s.width = this.width;
      if (this.height) s.height = this.height;
      if (this.cursor) s.cursor = this.cursor;
      if (this.rotate) s.transform = 'rotate('+this.rotate+'deg)'
      return s
    }
  }
}
</script>
