<template>
    <component :is="tagName" :class="className" :to="routerLink" :href="link" @click="emitClick">
      <Txt v-if="text" :text="text"/><slot></slot>
    </component>
</template>

<script>
import Txt from "@/components/ui/utils/Txt";
export default {
  name: "Button",
  components: {Txt},
  props: {
    mood: {type: String, default: null},
    link: {type: String, default: null},
    routerLink: {type: String, default: null},
    callback: {type: Function, default: () => {}},
    align: {type: String, default: "center"},
    disabled: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
    text: {type: String, default: ""},
  },
  computed: {
    tagName() {
      return 'span';
    },
    className() {
      let cls = 'button';
      if (!this.disabled) {
        switch (this.mood) {
          case 'primary': cls += ' primary'; break;
          case 'success': cls += ' success'; break;
          case 'warning': cls += ' warning'; break;
          case 'danger': cls += ' danger'; break;
          case 'saving': cls += ' saving'; break;
        }
      }
      switch (this.align) {
        case 'right': cls += ' right'; break;
        case 'left': cls += ' left'; break;
        case 'center': cls += ' center'; break;
      }

      if (this.disabled) cls += ' disabled';
      if (this.small) cls += ' small';

      return cls.trim()
    }
  },
  methods : {
    emitClick(e) {
      if (this.disabled) return false;
      if (this.routerLink && this.$router.currentRoute.path !== this.routerLink) this.$router.push(this.routerLink)
      else if (this.link) window.open(this.link)
      else this.$emit('click', e)
    }
  }
}
</script>
