import Fe from "@/lib/ui/FormElement";

export default {

    allowDelete: true,
    name: "Mailer Template",
    path: "mailer-templates",
    plural: "Mailer Templates",
    menu: "Mailer Templates",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],
    titleProp: "name",

    endpoints : {
        list : { path : '/admin/mailer-template/list', method: "get", token: true },
        get : { path : '/admin/mailer-template/get/:id', method: "get", token: true },
        add : { path : '/admin/mailer-template/add', method: "post", body: true, token: true },
        update : { path : '/admin/mailer-template/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/mailer-template/delete/:id', method: "get", params: true, token: true },
    },

    fields: [
        {label: "#", prop: "id", sortable: true, width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true},
    ],

    form : [
        Fe.Text("name", "Name", "small x"),
        Fe.Textarea("header_html", "Header", "main"),
        Fe.Textarea("footer_html", "Footer", "main x"),
    ],

}
