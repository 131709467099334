<template>
    <form @submit="onSubmit">
      <Flexy :col="true">
        <Flexy :even="true">
          <Input placeholder="Title" v-model="name" />
          <Select placeholder="Type" v-model="type" :options="actionCommandTypes" />
        </Flexy>
        <Flexy v-if="type" :even="true" :full="1">
          <Input placeholder="Target" v-model="target" v-if="targetType === 'text' && !target_input" />
          <Select placeholder="Target" v-model="target" v-if="targetType === 'endpoint'" :options="allEndpoints" />
          <Select :flat="true" placeholder="Input to use as Target" :clearable="true" v-model="target" v-if="target_input" :options="inputOptions" />
          <Checkbox label="Input?" :checked="target_input" @change="toggleTargetIsInput" v-if="targetType !== 'endpoint' && typeAllowsTargetInput(type)" />
        </Flexy>

        <Flexy v-if="type" :even="true" :full="1">
          <Select :flat="true" placeholder="Input" :multiple="true" v-model="input" v-if="!typeHides(type,'input')" :options="inputOptions" />
        </Flexy>
        <Flexy v-if="type" :even="true" :full="1">
          <Input placeholder="Store" v-model="store" v-if="!typeHides(type,'store')" />
          <Input placeholder="Value" v-model="value" v-if="!typeHides(type,'value')" />
          <Select :flat="true" placeholder="Search" :multiple="true" v-model="search" v-if="!typeHides(type,'search')" :options="inputOptions" />
        </Flexy>

        <Flexy :even="true">
          <Select placeholder="Condition" :multiple="true" v-model="condition_type" v-if="type" :options="actionConditionTypes" :clearable="true" />
          <Flexy :even="true" v-if="condition_type.length">
            <Input v-for="(con, ii) in condition_type" :placeholder="t('Condition Input {0}', ii+1)" v-model="condition_input[ii]" :key="ii" />
          </Flexy>
          <Checkbox label="Either" :checked="condition_or" @change="toggleOr" v-if="condition_type.length" />
        </Flexy>

        <Flexy :even="true">
          <Button @click="onSubmit" mood="primary" :disabled="!isValid" tabindex="3">{{t(command?'Edit Command':'Add Command')}}</Button>
          <Button v-if="command" @click="cancelEdit">{{t('Cancel')}}</Button>
        </Flexy>
      </Flexy>
    </form>
</template>

<script>
import Flexy from "@/components/ui/layout/Flexy";
import {mapActions, mapGetters} from "vuex";
import Input from "@/components/ui/form/Input";
import Button from "@/components/ui/form/Button";
import t from '@/lib/Locale';
import Select from "@/components/ui/form/Select";
import {actionIf} from "@/lib/Async";
import Checkbox from "@/components/ui/form/Checkbox";

export default {
  name: "CommandForm",
  components: {Checkbox, Select, Button, Input, Flexy},
  props : {
    command : { type: Object, default: null },
    commands : { type: Array, default: null },
    params : { type: Array, default: null },
    onAdd : { type: [Function] },
    onEdit : { type: [Function] },
    onCancelEdit : { type: [Function] },
  },
  data() {
    return {
      name : '',
      type : '',
      target : '',
      target_input : false,
      input : [],
      store : '',
      value : '',
      search : [],
      condition_type : [],
      condition_input : [],
      condition_or : false
    }
  },

  async mounted() {
    await actionIf([
        [!this.actionCommandTypes.length, this.getCommandTypes],
        [!this.allServices.length, this.getServices],
        [!this.actionConditionTypes.length, this.getConditionTypes],
    ])()
  },

  computed : {
    ...mapGetters(['actionCommandTypes','actionConditionTypes','allServices']),
    targetType() {
      if (this.typeHides(this.type,'target')) return ''
      if (this.type && this.type.indexOf('endpoint') >= 0) return 'endpoint'
      return 'text'
    },
    allEndpoints() {
      let a = [];
      for (let service of this.allServices) {
        a = [...a, ...service.endpoints.map(it => ({id: it.path, label: service.name +': '+it.name}))]
      }
      return a
    },
    isValid() {
      return this.name && this.type
    },
    inputOptions() {
      let options = [...this.params.map(p => p.name)]
      if (this.commands) for (let cmd of this.commands) {
        if (cmd.name === this.name) break
        if (cmd.store) options.push(cmd.store)
      }
      // add special values
      options.push('now')
      options.push('today')

      return options
    },
  },

  methods: {
    t,
    ...mapActions(['login', 'logout','getCommandTypes','getConditionTypes','getServices']),

    typeHides(type, field) {
      const cmd = this.actionCommandTypes.find(it => it.id === type)
      if (cmd && cmd.hide && cmd.hide.indexOf(field) > -1) return true
      return false;
    },

    typeAllowsTargetInput(type) {
      const t = this.actionCommandTypes.find(it => it.id === type)
      return t && t.targetInput
    },

    toggleTargetIsInput() {
      this.target_input = !this.target_input
    },
    toggleOr() {
      this.condition_or = !this.condition_or
    },

    async onSubmit(e) {

      e.preventDefault()
      if (this.command) {
        await this.onEdit(this.command.id ? this.command.id : this.command._key, this.name, this.type, this.target, this.typeAllowsTargetInput(this.type)?this.target_input:false, this.input.join(",").trim(), this.store, this.value, this.search.join(",").trim(), this.condition_type.join(",").trim(), this.condition_input.join(",").trim(), this.condition_or)
      }
      else {
        await this.onAdd(this.name, this.type, this.target, this.typeAllowsTargetInput(this.type)?this.target_input:false, this.input.join(",").trim(), this.store, this.value, this.search.join(",").trim(), this.condition_type.join(",").trim(), this.condition_input.join(",").trim(), this.condition_or)
      }
      this.name = this.type = this.target = this.store = this.value = ''
      this.condition_type = this.condition_input = this.input = this.search = []
      this.condition_or = this.target_input = false
    },
    cancelEdit() {
      this.name = this.type = this.target = this.store = this.value = ''
      this.condition_type = this.condition_input = this.input = this.search = []
      this.condition_or = this.target_input = false
      this.onCancelEdit();
    }
  },
  watch : {
    command() {
      if (this.command) {
        this.name = this.command.name;
        this.type = this.command.type;
        this.target = this.command.target;
        this.target_input = this.command.target_input;
        this.input = this.command.input ? this.command.input.split(/,/g) : [];
        this.store = this.command.store;
        this.value = this.command.value;
        this.search = this.command.search ? this.command.search.split(/,/g) : [];
        this.condition_type = this.command.condition_type ? this.command.condition_type.split(/,/g) : [];
        this.condition_input = this.command.condition_input ? this.command.condition_input.split(/,/g) : [];
        this.condition_or = this.command.condition_or;
      }
    }
  }
}
</script>
