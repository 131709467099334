<template>
  <div :class="className">
    <component :is="headerTag" v-if="title">{{t(title)}}</component>
    <slot></slot>
  </div>
</template>

<script>
import Flexer from "@/mixins/Flexer";
import t from '@/lib/Locale';
export default {
  name: "Panel",
  mixins: [Flexer],
  props: {
    title: String,
    col : { type: [Boolean, Object], default: true },
    small : { type: Boolean, default: false },
    tiny : { type: Boolean, default: false }
  },
  data() {
    return {
      clsName: 'panel',
      inheritsFlexyViaSass: true,
    }
  },
  computed : {
    headerTag() {
      if (this.tiny) return 'h3';
      if (this.small) return 'h2';
      return 'h1'
    }
  },
  methods: {
    t,
  }
}
</script>
