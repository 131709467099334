import Fe from "@/lib/ui/FormElement";

export default {

    allowDelete: true,
    name: "Landing Page",
    path: "landing-pages",
    plural: "Landing Pages",
    menu: "Landing Pages",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],

    meta : {
    },

    endpoints : {
        list : { path : '/admin/landing-page/list', method: "get", token: true },
        get : { path : '/admin/landing-page/get/:id', method: "get", params: true, token: true},
        add : { path : '/admin/landing-page/add', method: "post", body: true, token: true },
        update : { path : '/admin/landing-page/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/landing-page/delete/:id', method: "get", params: true, token: true },
    },

    fields: [
        {label: "ID", prop: "id", above: 1},
        {label: "Name", prop: "name", above: 2},
        {label: "Path", prop: "path", above: 2},
    ],

    form : [
        Fe.Text("name", "Name", "main"),
        Fe.Text("path", "Path", "primary"),
        Fe.Textarea("content", "Content", "primary 2"),
    ]

}
