<template>
  <Scroller :full="full">
    <Content :flex="1" :col="true">
      <Header v-if="title" :text="title" :small="true" />
      <Pagination :total="items.length" :page="page" @change="setPage" :limit="maxItems" v-if="maxItems && items.length > maxItems" />
      <table v-if="sortedItems.length" :class="'list' + (onClick?' interactive':'')">
        <thead>
        <tr>
          <th v-for="(field, index) in shownFields" :key="index" :width="field.width?field.width:'auto'" :class="headCellClass(field)" @click="headClick(field.sortable, field)">
            <Txt v-if="field.label" :text="field.label" />
            <span v-if="sortBy === (field.target?field.target:field.prop)" :class="'sort-by-field' + (sortDir<0?' desc':'')"></span>
          </th>
        </tr>
        </thead>
        <thead class="sum" v-if="summaryRow">
        <tr>
          <th v-for="(field, index) in shownFields" :key="index" :class="headCellClass(field)">{{typeof summaryRow[index] === "number" ? parseFloat(summaryRow[index]).toFixed(2) : ''}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in sortedItems" :key="index" @click="doClick(item)">
          <td v-for="(field, fIndex) in shownFields" :key="fIndex" :class="visibilityClass(field, item)">
            <component :is="itemTag(field, item)" :src="itemSrc(field, item)" :class="itemClass(field, item)" :title="itemTitle(field, item)" :style="itemStyle(field, item)" :value="itemValue(field, item)">
              <Icon v-if="field.type !== 'icon' && field.icon && itemContent(field, item)" :icon="field.icon" :mood="field.mood" :tiny="true" />
              {{itemContent(field, item)}}</component>
            <RowActions :actions="field.actions" :item="item" v-on="actionBinding(item, index)" />
          </td>
        </tr>
        </tbody>
        <tfoot v-if="summaryRow">
          <tr>
            <td v-for="(field, index) in shownFields" :key="index" :class="headCellClass(field)">{{typeof summaryRow[index] === "number" ? parseFloat(summaryRow[index]).toFixed(2) : ''}}</td>
          </tr>
        </tfoot>
      </table>
      <Pagination :total="items.length" :page="page" @change="setPage" :limit="maxItems" v-if="maxItems && items.length > maxItems" />

      <Box v-if="sortedItems.length === 0 && showNoItems" line="true" no-border="true">
        <Txt text="No items were found.." />
      </Box>
    </Content>
  </Scroller>
</template>

<script>
import Txt from "@/components/ui/utils/Txt";
import Box from "@/components/ui/layout/Box";
import Panel from "@/components/ui/layout/Panel";
import Badge from "@/components/ui/utils/Badge";
import Scroller from "@/components/ui/layout/Scroller";
import Icon from "@/components/ui/utils/Icon";
import FormattedList from "@/mixins/FormattedList";
import RowActions from "@/components/ui/lists/RowActions";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Content from "@/components/ui/layout/Content";
import Pagination from "@/components/ui/lists/Pagination";
import Header from "@/components/ui/utils/Header";

function sorter(sortBy, type = null) {
  return function(a, b) {
    switch (type) {
      default: return !isNaN(a[sortBy]*1) && isNaN(b[sortBy]*1) ? (a[sortBy]*1 < b[sortBy]*1 ? -1:1) : (a[sortBy] < b[sortBy] ? -1:1)
    }
  }
}

export default {
  name: "ItemList",
  mixins: [FormattedList],
  components: {Header, Pagination, Content, BoxGroup, RowActions, Icon, Scroller, Badge, Panel, Box, Txt},
  props: {
      actions: Array,
      noFormatting: Boolean,
      onClick: Function,
      defaultSortBy: String,
      onPage: {type: Function, default : () => {}},
      maxItems : {type: Number, default: null},
      title : {type: String, default: null},
      page : {type: Number, default: 1},
      full: {type: Boolean, default: true},
      showNoItems: {type: Boolean, default: false},
      features: {type: Array, default: () => []},
  },

  data() {
    return {
      values : {},
      sortBy: null,
      sortDir: 1
    }
  },

  mounted() {
    if (this.defaultSortBy) {
      this.sortBy = this.defaultSortBy
    }
  },

  computed : {

    shownFields() {
      let fields = this.fields;
      if (!fields) return []
      if (this.features instanceof Array) {
        fields = fields.filter(f => !f.feature || this.features.includes(f.feature))
      }
      return fields;
    },

    sortedItems() {
      let items = this.items;
      if (!items) return []
      items = items.sort(sorter(this.sortBy))
      if (this.sortDir === -1) items = items.reverse()
      if (this.maxItems) items = items.slice(this.maxItems * (this.page-1), this.maxItems * (this.page-1) + this.maxItems);
      return items;
    },

    summaryRow() {
      let row = null, neg
      for (let f = 0; f < this.fields.length; f++) {
        if (this.fields[f].sum) {
          if (!row) {
            row = [];
            for (let item of this.items) {
              neg = (this.fields[f].sum_negative) ? ((typeof this.fields[f].sum_negative === 'function') ? this.fields[f].sum_negative(item[this.fields[f].prop], item) : true) : false
              row[f] = row[f] ? row[f]*1 + item[this.fields[f].prop]*(neg?-1:1) : item[this.fields[f].prop]*(neg?-1:1)
            }
          }
        }
      }
      return row
    }
  },

  methods: {
    headCellClass(field) {
      let cls = '';
      if (field.hidden) cls += ' invisible'
      if (field.primary) cls += 'primary'
      if (field.sortable) cls += ' sortable'
      if (field.above) cls += ' above_'+field.above;
      if (field.below) cls += ' below_'+field.below;
      return cls.trim()
    },

    headClick(sortable, field) {
      if (!sortable) return false;
      const prop = field.prop;

      if (this.sortBy === prop) this.sortDir = -this.sortDir
      else {
        this.sortBy = prop;
        this.sortDir = 1
      }
    },

    setPage(page) {
      this.onPage(page);
    },

    async doClick(item) {
      if (this.onClick) await this.onClick(item)
    },

  },

}
</script>

