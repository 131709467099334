<template>
  <Floater :offset-y="32" :container="container" :constrain-x="constrainX" ref="el">
    <BoxGroup class="floating-menu">
      <Box v-for="(item, i) in items" :key="i" :line="true">
        <a @click="(e) => onClick(item, i, e)">
          <Flexy :between="true" :centered="true">
            <Txt>{{t(item.label)}}</Txt>
            <input type="checkbox" v-if="item.type==='toggle'" @click="(e) => onClick(item, i, e)" :checked="checked[i]" />
          </Flexy>
        </a>
      </Box>
    </BoxGroup>
  </Floater>
</template>

<script>
import Floater from "@/components/ui/utils/Floater";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Box from "@/components/ui/layout/Box";
import t from '@/lib/Locale';
import Flexy from "@/components/ui/layout/Flexy";
import Txt from "@/components/ui/utils/Txt";

export default {
  name: "NavFloatingMenu",
  components: {Txt, Flexy, Box, BoxGroup, Floater},
  props: {
    items: {type: Array},
    container: [],
    dismiss: Function,
    constrainX: {type: String, default: "right"}
  },
  data() {
    return {
      checked : []
    }
  },
  mounted() {
    for (let item of this.items) {
      if (item.type === 'toggle' && item.checked) {
        if (typeof item.checked !== 'function' || !!item.checked()) {
          this.checked.push(true)
          continue
        }
      }
      this.checked.push(false)
    }
    setTimeout(() => window.addEventListener('click', this.onBlur), 1)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onBlur);
  },
  methods : {
    t,
    onBlur() {

      if (this.dismiss) this.dismiss()
    },
    onClick(item, i, e) {
      if (item.type === 'action') {
        if (typeof item.action === 'function') item.action()
      }
      else if (item.type === 'toggle') {
        this.$set(this.checked,i, !this.checked[i]);
        if (typeof item.toggle === 'function') item.toggle(this.checked[i])
        e.preventDefault()
        e.stopPropagation()
      }
      else if (item.to && this.$router.currentRoute.path !== item.to) this.$router.push(item.to)
    }
  }
}
</script>
