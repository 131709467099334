<template>
    <form @submit="onSubmit">
      <Flexy :col="true">
        <Flexy :even="true">
          <Input placeholder="Title" v-model="name" />
          <Checkbox label="Mandatory" :checked="is_mandatory" @change="toggleMandatory" />
        </Flexy>
        <Flexy :even="true">
          <Button @click="onSubmit" tabindex="3" mood="primary" :disabled="!isValid">{{t(param?'Edit Param':'Add Param')}}</Button>
          <Button v-if="param" @click="cancelEdit">{{t('Cancel')}}</Button>
        </Flexy>
      </Flexy>
    </form>
</template>

<script>
import Flexy from "@/components/ui/layout/Flexy";
import {mapActions, mapGetters} from "vuex";
import Input from "@/components/ui/form/Input";
import Button from "@/components/ui/form/Button";
import t from '@/lib/Locale';
import {actionIf} from "@/lib/Async";
import Checkbox from "@/components/ui/form/Checkbox";

export default {
  name: "ParamForm",
  components: {Checkbox, Button, Input, Flexy},
  props : {
    param : { type: Object, default: null },
    onAdd : { type: [Function] },
    onEdit : { type: [Function] },
    onCancelEdit : { type: [Function] },
  },
  data() {
    return {
      name : '',
      is_mandatory : false,
    }
  },

  async mounted() {
    await actionIf([
    ])()
  },

  computed : {
    ...mapGetters([]),
    isValid() { return this.name }
  },

  methods: {
    t,
    ...mapActions([]),

    toggleMandatory() {
      this.is_mandatory = !this.is_mandatory
    },

    async onSubmit(e) {

      e.preventDefault()
      if (this.param) {
        await this.onEdit(this.param.id, this.name, this.is_mandatory)
      }
      else {
        await this.onAdd(this.name, this.is_mandatory)
      }
      this.name = ''
      this.is_mandatory = false
    },
    cancelEdit() {
      this.name = ''
      this.is_mandatory = false
      this.onCancelEdit();
    }
  },
  watch : {
    param() {
      if (this.param) {
        this.name = this.param.name;
        this.is_mandatory = this.param.is_mandatory;
      }
    }
  }
}
</script>
