export default {

    // login //////////////////////////////////////////////////////////

    CheckLogin : { path : "/admin/login/check-login", method: "get", token: true },
    Login : { path : '/admin/login/login', method: "post", body: true },
    Logout : { path: '/admin/login/logout', method: "get", token: true },

    // admin //////////////////////////////////////////////////////////
    AdminHome : { path : "/admin/admin/", method: "get", token: true },


    SwitchBrands : {path: '/admin/brand/switch/:id', method: "get", token: true, params: true},

    GetAllPermissions : { path : '/admin/admin/permissions', method: "get", token: true },

    GetBrandSettings : { path: '/admin/brand/settings', method: "get", token: true},
    SetBrandSettings : { path : '/admin/brand/settings', method: "post", params: true, body: true, token: true },

    SubscribersReport : { path : "/admin/report/subscribers-report", method: "get", token: true, cs: true },
    SalesReport : { path : "/admin/report/sales-report", method: "get", token: true, cs: true },

    UserRoleList : { path : '/admin/user-role/list', method: "get", token: true },
    UserRoleGet : { path : '/admin/user-role/get/:id', method: "get",  params: true,token: true },
    UserRoleAdd : { path : '/admin/user-role/add', method: "post", body: true, token: true },
    UserRoleUpdate : { path : '/admin/user-role/edit/:id', method: "post", params: true, body: true, token: true },
    UserRoleDelete : { path : '/admin/user-role/delete/:id', method: "get", params: true, token: true },

    IntegrationList : { path : '/admin/integration/list', method: "get", token: true },
    IntegrationGet : { path : '/admin/integration/get/:id', method: "get", token: true },
    IntegrationAdd : { path : '/admin/integration/add', method: "post", body: true, token: true },
    IntegrationUpdate : { path : '/admin/integration/edit/:id', method: "post", params: true, body: true, token: true },
    IntegrationDelete : { path : '/admin/integration/delete/:id', method: "get", params: true, token: true },
    AvailableIntegrations : { path : '/admin/integration/available', method: "get", token: true },

    ServiceList : { path : '/admin/service/list', method: "get", token: true },
    ServiceGet : { path : '/admin/service/get/:id', method: "get",  params: true,token: true },
    ServiceAdd : { path : '/admin/service/add', method: "post", body: true, token: true },
    ServiceUpdate : { path : '/admin/service/edit/:id', method: "post", params: true, body: true, token: true },
    ServiceDelete : { path : '/admin/service/delete/:id', method: "get", params: true, token: true },

    ServiceValidate : {path : '/admin/service/validate', method: "post", body: true, token: true},
    ServiceTest : {path : '/admin/service/test/:id/:action', method: "post", params: true, body: true, token: true},

    ActionList : { path : '/admin/action/list', method: "get", token: true },
    ActionGet : { path : '/admin/action/get/:id', method: "get",  params: true,token: true },
    ActionAdd : { path : '/admin/action/add', method: "post", body: true, token: true },
    ActionUpdate : { path : '/admin/action/edit/:id', method: "post", params: true, body: true, token: true },
    ActionDelete : { path : '/admin/action/delete/:id', method: "get", params: true, token: true },

    ActionCommandTypes : { path : '/admin/action/command-types', method: "get", token: true },
    ActionConditionTypes : { path : '/admin/action/condition-types', method: "get", token: true },
    ActionTest : { path : '/admin/action/test/:id', method: "post", params: true, body: true, token: true },

    // Manager ////////////////////////////////////////////////////////

    CustomSettings : { path : '/admin/action/settings', method: "get", token: true },
    PermittedActions : { path : '/admin/action/permitted', method: "get", token: true },
    CallAction : { path : '/admin/action/call/:action', method: "post", body: true, params: true, token: true },

    // upload image
    ImageUpload : { path: '/admin/image/upload', method: "post", body: true, token: true, upload: "file" },

    // Subscribers
    SubscriberList : { path : '/admin/subscriber/list', method: "get", token: true, cs: true },
    SubscriberGet : { path : '/admin/subscriber/get/:id', method: "get", params: true, token: true },
    SubscriberAdd : { path : '/admin/subscriber/add', method: "post", body: true, token: true },
    SubscriberUpdate : { path : '/admin/subscriber/edit/:id', method: "post", params: true, body: true, token: true },
    SubscriberDelete : { path : '/admin/subscriber/delete/:id', method: "get", params: true, token: true },
    SubscriberResetPassword : { path : '/admin/subscriber/reset-password/:id', method: "post", body: true, params: true, token: true },
    SubscriberSendActivationLink : { path : '/admin/subscriber/send-activation-link', method: "post", body: true, params: true, token: true },
    SubscriberSetMeta : { path : '/admin/subscriber/set-meta/:id', method: "post", body: true, params: true, token: true },
    SubscriberDeleteMeta : { path : '/admin/subscriber/delete-meta/:id', method: "post", body: true, params: true, token: true },

    // Transactions
    TransactionList : { path : '/admin/transaction/list', method: "get", token: true, cs: true  },
    TransactionGet : { path : '/admin/transaction/get/:id', method: "get", params: true, token: true, },
    TransactionAdd : { path : '/admin/transaction/add', method: "post", body: true, token: true },
    TransactionUpdate : { path : '/admin/transaction/edit/:id', method: "post", body: true, params: true, token: true },
    TransactionDelete : { path : '/admin/transaction/delete/:id', method: "get", params: true, token: true },

    // Debit Charges
    DebitChargeList : { path : '/admin/debit-charge/list', method: "get", token: true, cs: true  },
    DebitChargeGet : { path : '/admin/debit-charge/get/:id', method: "get", params: true, token: true, },
    DebitChargeAdd : { path : '/admin/debit-charge/add', method: "post", body: true, token: true },
    DebitChargeUpdate : { path : '/admin/debit-charge/edit/:id', method: "post", body: true, params: true, token: true },
    DebitChargeDelete : { path : '/admin/debit-charge/delete/:id', method: "get", params: true, token: true },

    // Media lists
    MediaListList : { path : '/admin/media-list/list', method: "get", token: true },
    MediaListGet : { path : '/admin/media-list/get/:id', method: "get", params: true, token: true },
    MediaListAdd : { path : '/admin/media-list/add', method: "post", body: true, token: true },
    MediaListUpdate : { path : '/admin/media-list/edit/:id', method: "post", body: true, params: true, token: true },
    MediaListDelete : { path : '/admin/media-list/delete/:id', method: "get", params: true, token: true },

};
