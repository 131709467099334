import Fe from "@/lib/ui/FormElement";

const utils = {
    formatSrc(src, instance, meta) {
        switch (instance.media_type) {
            case 'video': {
                const vid = meta['videos'].find(it => it.id*1 === src*1);
                if (vid) return vid.title
                break
            }
            default: return src;
        }
    },

}

export default {

    allowDelete: true,
    name: "Media List",
    path: "media-lists",
    plural: "Media Lists",
    menu: "MediaList",
    permissions: ['BRAND_MANAGER','BRAND_CONTENT_EDITOR'],
    titleProp: "name",

    meta : {
        mediaTypes : [{id: 'video', name:"Video"},{id: 'image', name:"Image"},{id: 'content', name:"Content"}],
    },
    computedMeta: {
        videoOptions(list, meta) {
            return meta.videos.map(it => ({id: it.id, name: it.title }))
        }
    },
    metaModules: ['videos'],

    endpoints : {
        list : { path : '/admin/media-list/list', method: "get", token: true },
        get : { path : '/admin/media-list/get/:id', method: "get", token: true },
        add : { path : '/admin/media-list/add', method: "post", body: true, token: true },
        update : { path : '/admin/media-list/edit/:id', method: "post", params: true, body: true, token: true },
        delete : { path : '/admin/media-list/delete/:id', method: "get", params: true, token: true },
    },

    fields: [
        {label: "#", prop: "id", sortable: true, width: "60px", above: 1, },
        {label: " ", prop: "thumbnail", type: "image", width: "45px"},
        {label: "Name", prop: "name", width: "20%",  sortable: true},
        {label: "Display Name", prop: "display_name", primary: true, sortable: true},
        {label: "Media Type", prop: "media_type", sortable: true, above: 1},
        {label: "Folder", prop: "folder", sortable: true, above: 2},
        {label: "Items", prop: "items", sortable: true, format : (items) => items?items.length:0},
        {label: "Sorting", prop: "sort", sortable: true, above: 3},
    ],

    form : [
        Fe.Text("name", "Name", "small x"),
        Fe.Text("display_name", "Display Name", "small x"),
        Fe.Textarea("display_content", "Display Content", "main"),
        Fe.Select('media_type', "Media Type","small 1", "Select Media Type","name", "mediaTypes"),
        Fe.Image("thumbnail", "Thumbnail","small 1", "Thumbnail image"),
        Fe.Text("folder", "Folder", "small 2"),
        Fe.Number("sort", "Sorting", "small 2"),
    ],

    additionalControls : (instance, meta) => [
        {type: "edit-list", title: "Items", position: "main", items: "items", titleProp: "title", fields: [
                { prop: "title", },
                { prop: "content", primary: true, format: (txt) => {
                        return txt instanceof String ? txt.substr(0, 100) + (txt.length > 100 ? '...' : '') : txt
                    } },
                { prop: "src" , above: 1, if: instance && ['video','image'].indexOf(instance.type) > -1, format: (x) => utils.formatSrc(x, instance, meta) , type : () => { return instance.media_type === 'image'?'image':''}},
        ],
            formFields: (instance) => [
                Fe.Text("title", "Title"),
                Fe.Textarea("content", "Content"),
                {...Fe.Select("src","Source Video", '','Select Video','name','videoOptions'), show : () => instance.media_type === 'video' },
                {...Fe.Image("src","Source Image", '','Select Image'), show : () => instance.media_type === 'image' },
            ]
        }
    ],



}
