<template>
  <Container :full="true">
    <ConfirmModal v-if="actionForDeletion" item-type="Action" :item-name="actionForDeletion.name" :confirm="confirmDeleteAction" :cancel="cancelDeleteAction" />
    <FilteredItemList title="Actions" :fields="fields" :filters="filters" :items="allActions" :max-items="20" save-as="actions"
                      :on-click="goto" :search="true" @delete="askToDeleteAction" :actions="{'Add Action': addNewAction }" />
  </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/dialogs/ConfirmModal";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import FilteredItemList from "@/components/ui/lists/FilteredItemList";
import Container from "@/components/ui/layout/Container";
import Icons from "@/lib/Icons";

export default {
  name: "Actions",
  components: {Container, FilteredItemList, ConfirmModal },
  data() {
    return {
      actionForDeletion: null,
      search : "",
      fields : [
        { label: "Path", prop: "path", sortable: true},
        { label: "Group", prop: "group_name", sortable: true, above: 1},
        { label: "Name", prop: "name", sortable: true, primary: true},
        { label: "Params", prop: "params", target: "params_count", sortable: true, format: it => it.length||'0', type:'badge',mood: this.paramsMood, above: 2 },
        { label: "Cmds", prop: "commands", sortable: true, format: it => it.length||'0', type:'badge',mood: it => it*1?'primary':'', above: 1 },
        { label: "Open", prop: "is_open", type:"boolean", sortable: true },
        { label: "Scope", prop: "scope", sortable: true, format: this.actionScope, above: 3 },
        {label: "Call Permissions", prop: "call_permission", target:"perms", format: this.callPermissions, mood: this.callPermissionMood, above: 4},
        { label: "Created", prop: "created", format: (d) => this.parseDate(d), sortable: true, above: 3},
        { label: "", prop: "", width: "10%", actions: [
          { mood: "danger", icon: Icons.x, action: "delete", tooltip: "Delete" },
        ]}
      ],
      filters: {
        'group_name': "Group"
      }
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
          [!this.allActions.length, this.getActions],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/login'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allActions']),
  },

  methods: {

    callPermissions(p, action) {
      return action.call_permission ? (action.call_permission instanceof Array ? action.call_permission.join(",") : action.call_permission) : 'None'
    },

    callPermissionMood(p, action) {
      return action.call_permission || action.is_open ? '':'danger'
    },

    actionScope(d, action) {
      let scopes = [];
      for (let cmd of action.commands) {
        if (action.scope === 'settings' || (cmd.type.indexOf('settings') > -1 && scopes.indexOf('settings') < 0)) scopes.push('settings')
        if (action.scope === 'service' || (cmd.type.indexOf('service') > -1 && scopes.indexOf('service') <0)) scopes.push('service')
        if (action.scope === 'subscriber' || (cmd.type.indexOf('meta') > -1 && scopes.indexOf('subscriber') <0)) scopes.push('subscriber')
      }
      return scopes.join(', ')
    },

    paramsMood(r, action) {
      if (action.params.length > 0) {
        return action.params.filter(it => !it.is_mandatory).length === 0 ? 'warning' : 'success'
      }
      return ''
    },

    parseDate(d) {
      d = new Date(d);
      let date = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear(),
          time = d.getHours()+':'+(d.getMinutes().toString().length < 2 ? '0'+d.getMinutes():d.getMinutes())
      return date + ' '+time
    },

    ...mapActions(['getActions','setLoading','deleteAction']),
    goto(action) {
      this.$router.push('/actions/'+action.id)
    },
    addNewAction() {
      this.$router.push('/actions/new')
    },
    askToDeleteAction(action) {
      this.actionForDeletion = action;
    },
    cancelDeleteAction() { this.actionForDeletion = null },
    confirmDeleteAction() {
      this.deleteAction(this.actionForDeletion.id)
      this.actionForDeletion = null
    }
  },


}
</script>
