<template>
  <component :is="comp" :class="className" :href="this.link" :to="this.routerLink" @click="onClick">
    <div v-if="title" class="box-header">
      <Flexy :between="true" :flex="1">
        <Txt :bold="true" :title="true" :mood="titleMood" :text="title" />
        <component :is="badgeTitle?Badge:Txt" :bold="true" v-if="!isNaN(titleValue*1)" :mood="titleValue && titleMood">{{titleValue}}</component>
      </Flexy>
    </div>
    <div class="box-content"><slot></slot></div>
  </component>
</template>

<script>
import Flexy from "@/components/ui/layout/Flexy";
import Txt from "@/components/ui/utils/Txt";
import Flexer from "@/mixins/Flexer";
import Badge from "@/components/ui/utils/Badge";
export default {
  name: "Box",
  mixins: [Flexer],
  components: {Txt, Badge, Flexy},
  props: ['title', 'titleValue', 'titleMood', 'badgeTitle', 'noBorder','highlighted','line','action','link','routerLink','small'],
  data() {
    return {
      clsName: 'box',
      inheritsFlexyViaSass: true,
      classIfs: [
          [this.line, 'line'],
          [this.action || this.link || this.routerLink, 'interactive'],
          [this.highlighted, 'highlighted'],
          [this.noBorder, 'no-border'],
          [this.small, 'small'],
      ],

      Badge, Txt
    }
  },
  computed: {
    comp() {
      if (this.link) return 'a';
      if (this.routerLink) return 'router-link';
      return 'div';
    }
  },

  methods : {
    onClick() {
      if (this.action) this.action()
    }
  }
}
</script>
