<template>
  <Panel :title="t('Activate Account')" :small="true">
    <Button @click="onSubmit" text="Send Activation Link" />
  </Panel>
</template>

<script>
import Button from "@/components/ui/form/Button";
import Panel from "@/components/ui/layout/Panel";
import t from '@/lib/Locale';

export default {
  name: "SendActivationLink",
  components: {Panel,  Button},
  props: {
    onSubmit : { type: Function, }
  },
  data() {
    return {
      newPassword: '',
      error: ''
    }
  },
  methods : {
    t,
    onClick() {
      if (this.isValid) {
        this.onSubmit(this.newPassword)
        this.newPassword = '';
      }
    }
  },
  computed : {
    isValid() {
      return this.newPassword.length >= 8
    }
  }
}
</script>
