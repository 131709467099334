<template>
  <Panel :title="t('Log In')">
    <form @submit.prevent="onSubmit">
      <Flexy :even="true">
        <Input placeholder="Username" v-model="username" tabindex="1" @keyup.native.enter="onSubmit" />
        <Input placeholder="Password" v-model="password" :password="true" tabindex="2" @keyup.native.enter="onSubmit" />
        <Button tabindex="3" @click="onSubmit" @keyup.native.enter="onSubmit">{{t('Login')}}</Button>
      </Flexy>
      <Txt mood="danger" v-if="loginError">{{t('Error: {0}',t(loginError))}}</Txt>
    </form>
  </Panel>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import Panel from "@/components/ui/layout/Panel";
import Flexy from "@/components/ui/layout/Flexy";
import Txt from "@/components/ui/utils/Txt";
import Input from "@/components/ui/form/Input";
import Button from "@/components/ui/form/Button";
import t from '@/lib/Locale';

export default {
  name: "LoginForm",
  components: {Button, Input, Txt, Flexy, Panel},

  computed: {
    ...mapGetters(['loginError','isLoggedIn']),
  },

  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    t,
    ...mapActions(['login', 'logout']),
    async onSubmit() {
      if (this.username && this.password) await this.login([this.username, this.password])
    }
  },
  watch : {
    isLoggedIn() {
      if (this.isLoggedIn) this.$router.push('/')
    }
  }
}
</script>
