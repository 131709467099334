<template>
  <Container>
    <LoginForm/>
  </Container>
</template>

<script>
import LoginForm from "@/components/forms/LoginForm";
import Container from "@/components/ui/layout/Container";
export default {
  name: "Login",
  components: {Container, LoginForm}
}
</script>
