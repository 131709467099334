import {setGlobalLocale} from "@/lib/Locale";

function applyTheme(theme) {
    if (theme === 'dark') { document.body.classList.add('dark') }
    else { document.body.classList.remove('dark') }
    localStorage.setItem('theme',theme)
}

export default {
    state : {
        direction : 'ltr',
        locale: 'en',
        theme: '',
        abilities : [],
        modules : [],
        settings : {
            title: "",
            url: "",
            icon: null,
            logo: null,
        },
        menuType: '',
        hiddenMenuItems: []
    },
    getters : {
        appDirection : (state) => state.direction,
        appLocale : (state) => state.locale,
        appTheme : (state) => state.theme,
        appMenuType : (state) => state.menuType,
        appSettings : (state) => state.settings,
        appAbilities : (state) => state.abilities,
        appModules : (state) => state.modules,
        appHiddenMenuItems : (state) => state.hiddenMenuItems,
    },
    actions : {
        setDirection({commit}, direction) {
            commit('setDirection', direction)
        },
        setLocale({commit, dispatch}, locale) {
            localStorage.setItem('locale', locale)
            setGlobalLocale(locale)
            commit('setLocale', locale)
            if (['he','ar','fa'].indexOf(locale) > -1) dispatch('setDirection','rtl')
            else dispatch('setDirection','ltr')
        },
        setTheme({commit}, theme) {
            applyTheme(theme)
            commit('setTheme', theme)
        },
        applyAppSettings({commit}, settings) {
            if (settings.icon && document.querySelector('#favicon-link')) {
                document.querySelector('#favicon-link').setAttribute('href', settings.icon)
            }
            if (settings.title) document.title = settings.title
            commit('applyAppSettings', settings)
        },
        applyAbilities({commit}, abilities) {
            commit('applyAbilities', abilities)
        },
        applyModules({commit}, modules) {
            commit('applyModules', modules)
        },

        loadTheme({dispatch}) {
            const theme = localStorage.getItem('theme')
            if (theme) dispatch('setTheme', theme)
        },
        loadLocale({dispatch}) {
            const locale = localStorage.getItem('locale')
            if (locale) dispatch('setLocale', locale)
        },
        toggleMenuType({commit, state}, type) {
            commit('toggleMenuType', type)
            localStorage.setItem('mt', state.menuType)
        },
        loadMenuType({dispatch, state}) {
            const menuType = localStorage.getItem('mt')
            if (menuType && state.menuType !== menuType) dispatch('toggleMenuType', menuType)
        },
        toggleMenuItem({commit, state}, path) {
            commit('toggleMenuItem', path)
            localStorage.setItem('hmi', JSON.stringify(state.hiddenMenuItems))
        },
        loadHiddenMenuItems({dispatch}) {
            let items = localStorage.getItem('hmi')
            items = items ? JSON.parse(items) : []
            for (let path of items) dispatch('toggleMenuItem', path)
        }
    },
    mutations : {
        setDirection: (state, direction) => { state.direction = direction },
        setLocale: (state, locale) => { state.locale = locale },
        setTheme: (state, theme) => { state.theme = theme },
        applyAppSettings: (state, settings) => { state.settings = settings },
        applyAbilities: (state, abilities) => { state.abilities = abilities },
        applyModules: (state, modules) => { state.modules = modules },
        toggleMenuItem : (state, path) => {
            if (state.hiddenMenuItems.indexOf(path) > -1) {
                state.hiddenMenuItems = state.hiddenMenuItems.filter(it => it !== path)
            }
            else state.hiddenMenuItems.push(path)
        },
        toggleMenuType : (state, menuType) => { state.menuType = (state.menuType === menuType ? '' : menuType) }
    }
}
