<template>
  <Scroller :full="true">
    <Container>
      <Panel title="General Settings">
        <Txt text="Please note: these settings will apply to all the users of the app." />
        <AppForm :fields="fields" :data="settings" @update="onUpdate" :meta="{}" />
        <Flexy :full="3" :centered="true" :between="true">
          <Flexy :centered="true" class="preview">
            <Txt text="Preview:" />
            <Flexy :full="2" :centered="true">
              <div class="navbar">
                <Flexy :centered="true">
                  <Icon :icon="Icons.crown" v-if="!settings.app_logo" height="22" width="33" />
                  <img class="app-logo" v-if="settings.app_logo" :src="newImages && newImages.app_logo ? previewImgSrc(newImages.app_logo) : settings.app_logo" />
                  <Flexy>
                    <h1>
                      <span>{{settings.app_title?settings.app_title:'Fito Brands Admin'}}</span>
                      <Button mood="primary" v-if="settings.app_url" :small="true" text="Site" />
                    </h1>
                  </Flexy>
                </Flexy>
              </div>
              <Flexy>
              <img v-if="settings.app_icon" class="app-icon" :src="newImages && newImages.app_icon ? previewImgSrc(newImages.app_icon) : settings.app_icon" />
              </Flexy>
            </Flexy>
          </Flexy>
          <Flexy v-if="settings.app_url" class="box-padding">
            <Txt :bold="true" text="Password Reset Link:" />
            <Txt>{{settings.app_url+(settings.recover_password_path?settings.recover_password_path:'/recover-password/')}}&lt;{{t('code')}}&gt;</Txt>
          </Flexy>
          <Flexy :col="true" align="end">
            <Flexy>
              <Button @click="saveBrandSettings" mood="saving">{{t("Update {0}",t('General Settings'))}}</Button>
            </Flexy>
          </Flexy>
        </Flexy>
      </Panel>

      <Panel title="API Settings">
        <Txt :bold="true" text="Here are your API Keys" />
        <Txt text="Use them with Fito toolkit or your own implementation to create your user experience."></Txt>
        <Flexy :col="true" class="box-padding">
          <Flexy :even="true" :full="1">
            <Flexy :col="true">
              <Txt :bold="true" text="Production API Key" />
              <Input :disabled="true" :value="settings.production_api_key" />
            </Flexy>
            <Flexy :col="true">
              <Txt :bold="true" text="Development API Key"></Txt>
              <Input :disabled="true" :value="settings.development_api_key" />
            </Flexy>
          </Flexy>
        </Flexy>
      </Panel>

      <Panel title="Custom Actions">
        <Flexy :full="3">
          <Box title="Run Actions" :flex="{4:1, 5:1}">
            <ActionsPanel :actions="settingsActions" @run="onRunAction" />
          </Box>
          <Box title="Custom Settings" :flex="{4:1, 5:2}">
            <BoxGroup :col="true">
              <Box v-for="(setting, ii) in allBrandSettings" :key="ii">
                <Flexy>
                <Txt :bold="true" :title="true">{{ii}}</Txt>
                <JsonBrowser :value="setting" />
                </Flexy>
              </Box>
            </BoxGroup>
          </Box>
        </Flexy>
      </Panel>
    </Container>
  </Scroller>
</template>

<script>
import Container from "@/components/ui/layout/Container";
import Panel from "@/components/ui/layout/Panel";
import t from '@/lib/Locale';
import Box from "@/components/ui/layout/Box";
import Txt from "@/components/ui/utils/Txt";
import Flexy from "@/components/ui/layout/Flexy";
import AppForm from "@/components/ui/form/AppForm";
import Fe from "@/lib/ui/FormElement";
import Input from "@/components/ui/form/Input";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import Button from "@/components/ui/form/Button";
import {mapActions, mapGetters} from "vuex";
import Icon from "@/components/ui/utils/Icon";
import Icons from '@/lib/Icons';
import ActionsPanel from "@/components/forms/ActionsPanel";
import JsonBrowser from "@/components/ui/lists/JsonBrowser";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Scroller from "@/components/ui/layout/Scroller";

export default {
  name: "BrandSettings",
  components: {
    Scroller,
    BoxGroup, JsonBrowser, ActionsPanel, Icon, Button, Input, AppForm, Flexy, Txt, Box, Panel, Container},
  data() {
    return {
      Icons,
      runAction: null,
      newImages : null,
      settings : {
        app_url: "",
        recover_password_path: "",
        app_title: "",
        app_logo: null,
        app_icon: null,
        production_api_key: '',
        development_api_key: '',
      },
      fields: [
          Fe.Text("app_title","App Title","main","Management app custom header title"),
          Fe.Text("app_url","App URL","primary","Brand Application URL"),
          {...Fe.Text("recover_password_path","Recover Password Path","primary","Brand Application URL"), show:() => this.settings.app_url },
          Fe.Image("app_logo","App Logo","primary 2","Management app header logo"),
          Fe.Image("app_icon","App Icon","primary 2","Management app browser icon"),
      ]
    }
  },
  async mounted() {
    await this.getPermittedActions()
    await this.getBrandSettings()
    const {settings} = await ApiRequest(ApiCall(Api.GetBrandSettings));
    if (settings) {
      this.settings = settings
    }
  },
  computed : {
    ...mapGetters(['allPermittedActions','allBrandSettings']),
    settingsActions() {
      return this.allPermittedActions.filter(it => it.scope === 'settings')
    }
  },
  methods : {
    ...mapActions(['setLoading','setSuccess','applyAppSettings','getPermittedActions','getBrandSettings']),
    t,
    previewImgSrc(src) {
      return src ? `${URL.createObjectURL(src)}` : ''
    },
    onUpdate(values, newImages = null) {
      this.newImages = newImages && Object.keys(newImages).length > 0 ? newImages : null;
    },
    async saveBrandSettings() {
      await this.setLoading(true);
      let res;
      for (let prop in this.newImages) {
        res = await ApiRequest(ApiCall(Api.ImageUpload, {file: this.newImages[prop]}))
        if (res.location) this.$set(this.settings, prop, res.location)
      }

      const {app_url, app_title, app_logo, app_icon, recover_password_path} = this.settings
      await ApiRequest(ApiCall(Api.SetBrandSettings, {app_url, app_title, app_logo, app_icon, recover_password_path }))
      this.applyAppSettings({url: app_url, title: app_title, logo: app_logo, icon: app_icon, recover_password_path})
      await this.setSuccess(t("Successfully saved settings"))
      await this.setLoading(false);

    },
    async onRunAction(action, params, name) {
      await this.setLoading(true)
      const r = await ApiRequest(ApiCall(Api.CallAction, params, {action}))
      if (r.res === 'ok') {
        await this.getBrandSettings()
        await this.setSuccess(t('Ran action: {0}',name))
      }
      await this.setLoading(false)
    }
  }
}
</script>
