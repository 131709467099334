export default {
    Label: (prop, label, group = null, placeholder = '', groupTitle = '') => ({type: "label", prop, label, group, groupTitle, placeholder}),
    Text: (prop, label, group = null, placeholder = '', groupTitle = '') => ({type: "text", prop, label, group, groupTitle, placeholder}),
    Image: (prop, label, group = null, placeholder = '', groupTitle = '') => ({type: "image", prop, label, group, groupTitle, placeholder}),
    Number: (prop, label, group = null, placeholder = '', groupTitle = '') => ({type: "number", prop, label, group, groupTitle, placeholder}),
    Textarea: (prop, label, group = null, placeholder = '', groupTitle = '') => ({type: "textarea", prop, label, group, groupTitle, placeholder}),
    Date: (prop, label, group = null, placeholder = '', groupTitle = '') => ({type: "date", prop, label, group, groupTitle, placeholder}),
    DateOnly: (prop, label, group = null, placeholder = '', groupTitle = '') => ({type: "date-only", prop, label, group, groupTitle, placeholder}),
    Checkbox: (prop, label, group = null, groupTitle = '') => ({type: "checkbox", prop, label, group, groupTitle}),
    Password: (prop, label, group = null, placeholder = '', groupTitle = '') => ({type: "password", prop, label, group, groupTitle, placeholder}),
    Select: (prop, label, group = null, placeholder = '', labelKey = 'name', metaGroup = null, propKey = 'id', clearable = true, multiple = false, groupTitle = '') => ({type: "select", prop, label, group, groupTitle, multiple, clearable, placeholder, labelKey, propKey, metaGroup}),
    Buttons: (prop, label, group = null, placeholder = '', labelKey = 'name', metaGroup = null, propKey = 'id', clearable = true, multiple = false, groupTitle = '') => ({type: "buttons", prop, label, group, groupTitle, multiple, clearable, placeholder, labelKey, propKey, metaGroup}),
}
