<template>
  <Flexy :even="true" :full="this.full||2" :half="this.half||3" :third="this.third||null" :quarter="this.quarter||null">
    <div v-for="(period, i) in periods" :key="i">
      <BoxGroup>
        <Box highlighted="1" line="1"><h3>{{t(period)}}</h3></Box>
        <Box v-for="(leg, key) in legend" :key="key" line="1">
          <Flexy :between="true" :flex="1">
            <Txt>{{t(leg)}}</Txt>
            <Txt :mood="moods&&moods[key]?moods[key]:null" bold="1">{{data&&data[period]?data[period][key]:''}}</Txt>
          </Flexy>
        </Box>
      </BoxGroup>
    </div>
  </Flexy>
</template>

<script>
import Flexy from "@/components/ui/layout/Flexy";
import Box from "@/components/ui/layout/Box";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Txt from "@/components/ui/utils/Txt";
import t from '@/lib/Locale'

export default {
  name: "PeriodStats",
  props: ['periods', 'legend', 'data', 'moods', 'full','half','third','quarter'],
  components: {Txt, BoxGroup, Box, Flexy},
  methods : {
    t,
  }
}
</script>
