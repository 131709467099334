<template>
  <Content class="actions" v-if="actions && actions.length">
    <Content v-if="confirmAction === null" :flex="1">
      <Txt v-for="(action, index) in actions" :key="action.action">
        <Badge  @click="(e) => doAction(e, index, action, item)" :mood="action.mood" :title="action.tooltip || action.label" :outline="true" :interactive="true">
          <Icon v-if="action.icon" :icon="action.icon" :mood="action.mood" height="10" width="10" :class="action.label?'':'raw'" />
          <Txt v-if="action.label">{{action.label}}</Txt>
        </Badge>
      </Txt>
    </Content>
    <Box v-if="confirmAction !== null">
      <Content :centered="true">
        <Txt mood="danger">{{confirmation}}</Txt>
        <Button mood="danger" @click="doConfirmation">Yes</Button>
        <Button @click="confirmAction = null">No</Button>
      </Content>
    </Box>
  </Content>
</template>

<script>
import Content from "@/components/ui/layout/Content";
import Badge from "@/components/ui/utils/Badge";
import Icon from "@/components/ui/utils/Icon";
import Txt from "@/components/ui/utils/Txt";
import Box from "@/components/ui/layout/Box";
import Button from "@/components/ui/form/Button";
export default {
  name: "RowActions",
  props : {
    actions: Array,
    item: Object,
  },
  components: {Button, Box, Txt, Icon, Badge, Content},
  data() {
    return {
      confirmAction: null
    }
  },
  computed : {
    confirmation() {
      if (this.actions[this.confirmAction].confirmation === true) return "Are you sure?"
      else if (typeof this.actions[this.confirmAction].confirmation === "string") return this.actions[this.confirmAction].confirmation
      else if (typeof this.actions[this.confirmAction].confirmation === "function") return this.actions[this.confirmAction].confirmation(this.item)
      return null
    }
  },
  methods : {
    showAction(action) {
      return typeof action.show === "function" ? action.show() : true
    },
    doAction(e, index, action, item) {
      e.stopPropagation()
      e.preventDefault()
      if (action.confirmation) {
        if (this.confirmAction !== index) {
          this.confirmAction = index;
          return false;
        }
      }
      this.$emit(action.action, item)

      return false;
    },

    doConfirmation(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.confirmAction !== null && this.actions[this.confirmAction]) {
        this.$emit(this.actions[this.confirmAction].action, this.item)
      }
      this.confirmAction = null;
    }
  }
}
</script>
