<template>
    <Panel title="Test Action" :small="true" class="box-padding" v-if="action && action.id">
      <Flexy v-if="hasScope('subscriber')" :even="true">
        <Input v-model="subscriberId" placeholder="Subscriber ID" />
        <Checkbox label="Allow Saving Meta" :checked="subscriberSaveMeta" @change="toggleAllowMeta" />
      </Flexy>
      <Flexy v-if="hasScope('settings')">
        <Checkbox label="Allow Saving Settings" :checked="saveSettings" @change="toggleAllowSettings" />
      </Flexy>
      <Flexy v-if="action.params.length" :wraps="true" class="param-inputs">
        <Input v-for="(param, pi) in action.params" v-model="params[param.name]" :key="pi" :placeholder="param.name" />
      </Flexy>
      <Button @click="doTest" text="Test Action" :disabled="!isValid" />
      <Flexy v-if="tested">
        <Textarea :disabled="true" :value="resultText" :rows="resultRows" />
      </Flexy>
        <BoxGroup :col="true" v-if="log">
          <Box v-for="(cmd, ii) in log" :key="ii">
            <Flexy :col="true">
              <Flexy :centered="true" :between="true">
                <Flexy :centered="true">
                  <Txt :small="true">{{ii+1}}</Txt>
                  <Txt :bold="true" mood="primary">{{cmd.type}}</Txt>
                </Flexy>
                <Txt :bold="true" :text="cmd.executed?'Executed':'Not Executed'" :mood="cmd.executed?'success':'warning'" />
              </Flexy>
              <Flexy :between="true" :wraps="true">
                <Flexy>
                  <Txt text="State Size" :bold="true" />
                  <Txt>{{cmd.state_size}}</Txt>
                </Flexy>
                <Flexy>
                  <Txt text="Age" :bold="true" />
                  <Txt>{{cmd.age}}</Txt>
                </Flexy>
                <Flexy v-if="cmd.condition_type">
                  <Txt text="Condition" :bold="true" />
                  <Txt>{{cmd.condition_type}}</Txt>
                  <Txt>{{cmd.condition_input}}</Txt>
                </Flexy>
              </Flexy>
            </Flexy>
          </Box>
        </BoxGroup>
    </Panel>
</template>

<script>
import Flexy from "@/components/ui/layout/Flexy";
import {mapActions, mapGetters} from "vuex";
import t from '@/lib/Locale';
import {actionIf} from "@/lib/Async";
import Panel from "@/components/ui/layout/Panel";
import Button from "@/components/ui/form/Button";
import Textarea from "@/components/ui/form/Textarea";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Box from "@/components/ui/layout/Box";
import Txt from "@/components/ui/utils/Txt";
import Input from "@/components/ui/form/Input";
import Checkbox from "@/components/ui/form/Checkbox";

export default {
  name: "TestActionForm",
  components: {Checkbox, Input, Txt, Box, BoxGroup, Textarea, Button, Panel, Flexy},
  props : {
    action : { type: Object, default: null },
    results: { type: [Object, Array], default: null },
    log: { type: Array, default: null },
    onTest : { type: [Function] },
  },
  data() {
    return {
      tested: false,
      params : {},
      subscriberId : '',
      subscriberSaveMeta: false,
      saveSettings: false,
    }
  },

  async mounted() {
    await actionIf([
        [!this.actionCommandTypes.length, this.getCommandTypes],
        [!this.allServices.length, this.getServices],
        [!this.actionConditionTypes.length, this.getConditionTypes],
    ])()
  },

  computed : {
    ...mapGetters(['actionCommandTypes','actionConditionTypes','allServices']),
    resultText() {
      return JSON.stringify(this.results)
    },
    resultRows() {
      if (this.resultText.length > 1000) return 15
      if (this.resultText.length > 400) return 10
      return 8;
    },
    isValid() {
      if (this.hasScope('subscriber') && !this.subscriberId) return false
      if (this.action.params) {
        for (let p of this.action.params)
          if (p.is_mandatory && (typeof this.params[p.name] === 'undefined' || this.params[p.name] === null || !this.params[p.name].toString().length)) return false;
      }
      return true
    }
  },

  methods: {
    t,
    ...mapActions(['login', 'logout','getCommandTypes','getConditionTypes','getServices']),
    doTest() {
      this.tested = false
      const settings = {
        __subscriber_id: this.subscriberId,
        __allow_saving_meta: this.subscriberSaveMeta,
        __allow_saving_settings: this.saveSettings,
      }
      this.onTest(settings, this.params)
      this.tested = true
    },
    toggleAllowMeta() {
      this.subscriberSaveMeta = !this.subscriberSaveMeta
    },
    toggleAllowSettings() {
      this.saveSettings = !this.saveSettings
    },
    hasScope(scope) {
      let t;

      for (let cmd of this.action.commands) {
        t = this.actionCommandTypes.find(it => it.id === cmd.type)
        switch (scope) {
          case 'subscriber': if (t && t.scope === 'subscriber' || cmd.type.indexOf('meta') > -1) return true; break
          case 'service': if (t && t.scope === 'service' || cmd.type.indexOf('service') > -1) return true; break
          case 'settings': if (t && t.scope === 'settings' || cmd.type.indexOf('settings') > -1) return true; break
        }
      }
      return false
    }
  },
  watch : {

  }
}
</script>
