<template>
  <InstanceDash :type="name" :title-prop="m.titleProp" :instance="instance" :fields="formFields" :meta="moduleMeta" :features="features"
                :on-update="onUpdate" :on-submit="onSubmit">
  <template v-if="additionalMainControls.length" slot="main">
    <Panel v-for="(control, ii) of additionalMainControls" :key="ii" :title="control.title">
      <component :is="controlEl(control)" v-bind="controlProps(control)" />
    </Panel>
  </template>
  <template v-if="additionalSidebarControls.length" slot="sidebar">
    <BoxGroup :col="true">
      <Box v-for="(control, ii) of additionalSidebarControls" :key="ii" :title="control.title">
        <component :is="controlEl(control)" v-bind="controlProps(control)" />
      </Box>
    </BoxGroup>
  </template>
  </InstanceDash>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import InstanceDash from "@/components/ui/dashboards/InstanceDash";
import InstanceView from "@/mixins/views/InstanceView";
import Panel from "@/components/ui/layout/Panel";
import ItemList from "@/components/ui/lists/ItemList";
import Txt from "@/components/ui/utils/Txt";
import BoxGroup from "@/components/ui/layout/BoxGroup";
import Box from "@/components/ui/layout/Box";
import Button from "@/components/ui/form/Button";
import EditableDraggableList from "@/components/ui/lists/EditableDraggableList";
import MultiParamForm from "@/components/forms/MultiParamForm.vue";
import {ApiCall, ApiRequest} from "@/lib/Api";

export default {

  name: "Instance",
  mixins: [InstanceView],
  components: {Box, BoxGroup, Panel, InstanceDash, },
  props: {
    module: Object,
    view : {type: Object, default: () => {}},
    meta: {type: Object, default: () => ({})},
  },
  data() {
    return {
      externalModule: null,
      type: null,
      Create : this.createItem,
      Update : this.updateItem,
      defaultData: {
        name: "",
      },
    }
  },

  async mounted() {
    await this.initModuleInstance();
  },

  computed: {
    ...mapGetters(['appModules', "currentUser"]),
    m() {
      if (this.externalModule) {
        for (let mod of this.appModules) {
          if (mod.path === this.externalModule) return mod
        }
      }
      return this.module || {}
    },
    v() { return this.view },
    name() {
      return (this.view && this.view.name) ? this.view.name : (this.m ? this.m.name:'');
    },

    features() {
      if (!this.currentUser || !this.currentUser.app) return []
      return this.currentUser.app.features || []
    },

    getItems() {
      if (!this.m) return []

      return () => this.$store.dispatch('get_'+this.m.path+'_list')
    },

    formFields() {
      if (typeof this.m.form === "function") return this.m.form(this.instance)
      return this.m.form
    },

    additionalMainControls() {
      let controls = this.m.additionalControls || [];

      if (typeof controls === 'function') controls = controls(this.instance, this.moduleMeta)
      controls = controls.filter(it => !it.feature || (this.features && this.features.includes(it.feature)))
      return controls.filter(it => it.position ==='main')
    },

    additionalSidebarControls() {
      let controls = this.m.additionalControls || [];
      if (typeof controls === 'function') controls = controls(this.instance, this.moduleMeta)
      controls = controls.filter(it => !it.feature || (this.features && this.features.includes(it.feature)))
      return controls.filter(it => it.position ==='sidebar')
    },

    moduleMeta() {
      const meta = this.m.meta || {}
      if (this.m.metaModules) {
        for (let mm of this.m.metaModules) {
          meta[mm] = this.externalItems(mm)
        }
      }
      if (this.m.computedMeta) {
        for (let cm in this.m.computedMeta) {
          meta[cm] = this.m.computedMeta[cm](this.allItems, meta)
        }
      }

      return meta;
    },
  },

  methods: {
    ...mapActions(['get_brands_list']),

    async initModuleInstance() {

      if (!this.module && this.$route.params && this.$route.params['external_id']) {
        this.externalModule = this.$route.params['external_id']
      }
      else this.externalModule = null

      this.type = (this.view && this.view.name) ? this.view.name : this.m.name
      const conditions = [
        [!this.allItems().length, this.getItems]
      ]
      if (this.m && this.m.metaModules) {
        for (let mm of this.m.metaModules) {
          conditions.push([!this.externalItems(mm).length, this.externalGetItems(mm)])
        }
      }
      await this.initInstance('all_'+this.m.path,conditions)

    },

    externalItems(modulePath) {
      return this.$store.getters['all_'+modulePath] || []
    },
    externalGetItems(modulePath) {
      return async () => await this.$store.dispatch('get_'+modulePath+'_list')
    },
    allItems() {
      if (!this.m) return []
      return this.$store.getters['all_'+this.m.path] || []
    },
    controlEl(control) {
      switch (control.type) {
        case 'list': return ItemList;
        case 'edit-list': return EditableDraggableList;
        case 'params-form': return MultiParamForm;
        case 'button': return Button;
        default: return Txt;
      }
    },
    controlProps(control) {

      if (!this.instance) return {}
      let items
      switch (control.type) {
        case 'list':
          items = this.moduleMeta[control.items]
          if (typeof items === 'undefined') items = this.instance[control.items]

          if (control.key) items = items.filter(it => it[control.key] === this.instance.id)
          return {
            full: false,
            items,
            fields: control.fields,
          }
        case 'edit-list':
          items = this.instance[control.items]
          if (control.reduceProp && items) items = items.map(it => ({[control.reduceProp]: it}))
          return {
            full: false,
            items,
            meta: this.moduleMeta,
            reduceProp: control.reduceProp,
            formFields: typeof control.formFields === 'function' ? control.formFields(this.instance) : control.formFields,
            titleProp: control ? control.titleProp : 'name',
            fields: control.fields,
            onChange: (items) => {
              this.instance[control.items] = control.reduceProp ? items.map(it => it[control.reduceProp]) : items
            }
          }

        case 'params-form':
          return {
            params: control.params || {},
            onSubmit : async (params) => {
              const endpoint = this.m.endpoints[control.endpoint]
              if (endpoint) {
                console.log({endpoint, params})
                const res = await ApiRequest(ApiCall(endpoint, params, this.instance.id))
                console.log({res})
              }
            }
          }

        case 'button':
          return {
            text: typeof control.label === "function"?control.label(this.instance):control.label,
            mood: typeof control.mood === "function"?control.mood(this.instance):control.mood,
            'router-link': typeof control.target === "function"?control.target(this.instance):control.target
          }
        case 'text':
          return {
            text: typeof control.label === "function"?control.label(this.instance):control.label,
          }
        default: return {
        }
      }
    },
    createItem(item) {
      this.$store.dispatch('create_'+this.m.path, item)
    },
    updateItem(item, id) {
      this.$store.dispatch('update_'+this.m.path, item, id)
    }
  },

  watch : {
    async module() {
      await this.initModuleInstance()
    }
  }
}
</script>
