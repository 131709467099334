<template>
  <Container>
    <Panel title="My Settings" class="box-padding">
      <Txt :bold="true" text="Please note: these settings only apply locally. Using a different device will reset them." />
    </Panel>
    <Flexy :even="true" :full="2">
      <Box :col="true" title="Display Settings" :small="true">
        <Flexy :col="true">
          <Checkbox label="Night Mode" :checked="this.appTheme === 'dark'" description="Use a dark theme when your eyes hurt" @change="onThemeToggle" />
          <Checkbox v-if="hasPermission('ADMIN')" label="Admin Menu" :checked="this.appMenuType === 'admin'" description="Toggle between normal and admin menu" @change="onToggleMenuType" />
        </Flexy>
      </Box>
      <Box title="Locale" :small="true">
        <Flexy :col="true">
          <Txt text="Select language and locale settings." />
          <Flexy>
            <ButtonGroup>
              <Button @click="e => onSetLocale('he')" :mood="appLocale==='he'?'primary':''">עברית</Button>
              <Button @click="e => onSetLocale('en')" :mood="appLocale==='en'?'primary':''">English</Button>
            </ButtonGroup>
          </Flexy>
        </Flexy>
      </Box>
      <Box title="Hide menu items" :small="true">
        <Flexy :col="true">
          <Flexy :wraps="true" v-for="(group, i) in groupedMenuItems" :key="i">
            <Flexy v-for="(item, ii) in group" :key="ii" :centered="true">
              <Input :checkbox="true" @change.native="e => onToggleMenuItem(item.to)" :checked="menuItemHidden(item.to)" />
              <Txt :text="item.label" />
              <Txt v-if="item.type" :small="true" mood="primary" :text="item.type" />
            </Flexy>
          </Flexy>
        </Flexy>
      </Box>
    </Flexy>
  </Container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Container from "@/components/ui/layout/Container";
import Panel from "@/components/ui/layout/Panel";
import t from '@/lib/Locale';
import Txt from "@/components/ui/utils/Txt";
import Input from "@/components/ui/form/Input";
import Flexy from "@/components/ui/layout/Flexy";
import ButtonGroup from "@/components/ui/layout/ButtonGroup";
import Button from "@/components/ui/form/Button";
import NavbarLinks from "@/config/NavMenuLinks";
import Checkbox from "@/components/ui/form/Checkbox";
import Box from "@/components/ui/layout/Box";

export default {

  name: "MySettings",
  components: {Box, Checkbox, Button, ButtonGroup, Flexy, Input, Txt, Panel, Container },
  data() {
    return {
      locale: '',
      menuItems: [],
    }
  },

  async mounted() {
    this.locale = this.appLocale
  },

  computed: {
    ...mapGetters(['appLocale','appTheme','currentUser', 'appHiddenMenuItems','appMenuType']),
    allowedMenuItems() {
      return NavbarLinks.filter(it => (!it.feature || this.hasFeature(it.feature)) && (!it.permission || this.hasPermission(it.permission)))
    },
    groupedMenuItems() {
      const groups = {}
      for (let item of this.allowedMenuItems) {
        if (!groups[item.type]) groups[item.type] = []
        groups[item.type].push(item)
      }
      return groups
    }

  },

  methods: {
    t,
    ...mapActions(['setTheme','setLocale','toggleMenuItem','toggleMenuType']),
    onThemeToggle() {
      if (this.appTheme === 'dark') {
        this.setTheme('')
      }
      else {
        this.setTheme('dark')
      }
    },
    onToggleMenuType() {
      this.toggleMenuType('admin')
      this.$emit('force-update')
    },
    onToggleMenuItem(path) {
      this.toggleMenuItem(path)
      this.$emit('force-update')
    },
    menuItemHidden(path) {
      return this.appHiddenMenuItems.indexOf(path) > -1
    },
    hasPermission(permission = null) {
      if (!this.currentUser) return false
      if (this.currentUser.admin) return true
      for (let perm of this.currentUser.permissions) if (perm === permission) return true
    },
    hasFeature(feature) {
      if (!this.currentUser || !this.currentUser.app || !this.currentUser.app.features) return false
      for (let feat of this.currentUser.app.features) if (feat === feature) return true
    },
    onSetLocale(locale) {
      this.setLocale(locale)
      this.$emit('force-update')
    }
  }
}
</script>
